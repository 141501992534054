// we want font-awesome to load as soon as possible to show the fa-spinner
import '../styles/styles.scss';

import '@babel/polyfill';
import * as Bluebird from 'bluebird';

// Config
import {configurationProfiles} from 'config/configurationProfiles'
import {configurationProfiles as configurationProfilesDevOverrides} from 'config/dev-overrides'
import {Configurations} from 'devtag-aurelia-config-plugin'

// Auth
import {OidcBackend, IdentityProvider} from "devtag-aurelia-auth-plugin";
import {ApiClient} from 'api-client';
import Backend from "backend";

// remove out if you don't want a Promise polyfill (remove also from webpack.config.js)
Bluebird.config({ warnings: { wForgottenReturn: false } });

import {I18N} from 'aurelia-i18n';
import I18NXhrBackend from 'i18next-xhr-backend';
import {ValidationMessageProvider} from 'aurelia-validation';
import {Router} from 'aurelia-router';

import moment from 'moment';
import 'moment/locale/nb';
import numeral from 'numeral';
import 'numeral/locales/no';
import {ToastErrorRenderer} from "devtag-aurelia-components-plugin";
import {LoggingErrorRenderer} from "./logging-error-renderer";

import Materialize from 'materialize-css'
import './validation-messages';

export async function configure(aurelia) {
  // Set the momentjs locale
  moment.locale('nb');

  // Set the numeraljs locale
  numeral.locale('no');

  await aurelia.use
    .standardConfiguration()
    .developmentLogging()

    // Scroll up for new pages
    .plugin(aurelia => {
      const router = aurelia.container.get(Router);
      // Scroll: New pages should be scrolled to top. Except if we are restoring scroll. Then we should leave as is.

      router.pipelineProvider.addStep('postRender', {
        run(navigationInstruction, next) {
          if (navigationInstruction.router.isNavigatingNew) {
            if (!navigationInstruction.queryParams.restoreScroll) {
              // console.log('Is new. Scroll to 0,0.')
              window.scroll(0, 0);
            } else {
              // console.log('Is new. BUT, restoreScroll is SET: ' + navigationInstruction.queryParams.restoreScroll);
            }
          }
          return next();
        }
      });

      // Close side nav after navigation.
      // This is done because the UX feels more intuitive.
      // Also: after navigating to order-index the menu can't be removed properly for some reason, this happens to circumvent that bug.
      router.pipelineProvider.addStep('postRender', {
        run(navigationInstruction, next) {
          // Media query lifted from the css that hides sidenav on narrow screens.
          let isSidenavCollapsible = window.matchMedia("only screen and (max-width: 992px)");
          if (isSidenavCollapsible.matches) {
            let sideNavElement = document.getElementsByClassName('sidenav')[0];
            if (sideNavElement) {
              let sidenav = Materialize.Sidenav.getInstance(sideNavElement);
              sidenav.close();
            }
          }
          return next();
        }
      });
    })

    // Add router methods
    .postTask(() => {
      const router = aurelia.container.get(Router);

      // router.getBackUrl() for screens.
      router.getBackUrl = () => {
          let url = router.generate(
            router.currentInstruction.config.name,
            Object.assign(
              {},
              router.currentInstruction.queryParams,
              {restoreScroll: 'true'}));
          return url;
      };

      // router.navigateToRouteAndFixIssue()
      router.navigateToRouteAndFixIssue = (route, params, options) => {
        // This is a wrapper for a method on the router
        router.navigateToRoute(route, params, options)

        // Issue 2
        // Query params on currentInstruction is not set when we change url with activationStrategy.replace
        // Let's do it ourselves.
        router.currentInstruction.queryParams = params;

        // Issue 1
        // Emulate what aurelia-router does to know if navigation is new:
        // Set time stamp on the new history entry.
        // See https://github.com/aurelia/history-browser
        //     https://github.com/aurelia/history-browser/blob/6c04fa2f0f6cedbaa60978703bea5c2164d6a60a/src/browser-history.ts#L214
        //     https://github.com/aurelia/router/blob/63accbce4ea1b249b8a5ddd1524708d70d98c07c/src/app-router.ts#L193
        // todo: why is this necessary? Shouldn't the router do it?
        let state = Object.assign({}, window.history.state);
        state['NavigationTracker'] = Date.now();
        window.history.replaceState(state, null, null);
      }
    })

    // Devtag plugins
    .plugin(PLATFORM.moduleName('devtag-aurelia-config-plugin'), config => config.profiles([configurationProfiles, configurationProfilesDevOverrides]))
    .plugin(PLATFORM.moduleName('devtag-aurelia-error-handling-plugin'), config => {
      let toastErrorRenderer = aurelia.container.get(ToastErrorRenderer);
      config.addRenderer(toastErrorRenderer);
      let loggingErrorRenderer = aurelia.container.get(LoggingErrorRenderer);
      config.addRenderer(loggingErrorRenderer);
    })
    .plugin(PLATFORM.moduleName('devtag-aurelia-permission-gui-plugin'), config => {
      config.backend = aurelia.container.get(Backend);
      config.backendMethodPrefix = 'LocalPermissionService_';
    })
    .plugin(PLATFORM.moduleName('devtag-aurelia-auth-plugin'), config => {
      const router = aurelia.container.get(Router);
      const configurations = aurelia.container.get(Configurations);

      const authorityUrl = configurations.getConfig('authAuthorityUrl');
      const appBaseUrl = configurations.getConfig('authAppBaseUrl');

      config.authBackend = new OidcBackend(authorityUrl, appBaseUrl, appBaseUrl, router);

      // This callback fires when _everything_ is done. It's loaded even if additional claims weren't set. But not if no user is logged in.
      config.userLoadedCallback = user => {
        // Set token on api client.
        const apiClient = aurelia.container.get(ApiClient);
        apiClient.setAccessToken(user.access_token);
      };

      // Signout
      config.userSignedOutCallback = _ => {
        // Clear token from api client
        const apiClient = aurelia.container.get(ApiClient);
        apiClient.clearAccessToken();
      };

      config.urlToRedirectToAfterLogin = '#/';
      config.authorizedHomeRoute = 'home';
      config.noPermissionRoute = 'no-permission';
    })

    // Enable Aurelia Validation pluging
      .plugin(PLATFORM.moduleName('aurelia-validation'))

    // Plugin: scroll history
    .plugin(PLATFORM.moduleName('util/scroll-history-plugin'))

    // Register Devtag UI components for global use
    .feature(PLATFORM.moduleName('devtag-aurelia-components-plugin/index'))
    .globalResources(PLATFORM.moduleName('components/brygg-container'))
    .globalResources(PLATFORM.moduleName('util/additional-fields'))
    .globalResources(PLATFORM.moduleName('util/integer-value-converter'))
    .globalResources(PLATFORM.moduleName('util/article-name.html'))
    .globalResources(PLATFORM.moduleName('util/numeral-value-converter'))
    .globalResources(PLATFORM.moduleName('util/trim-value-converter'))
    .globalResources(PLATFORM.moduleName('util/empty-string-to-null-value-converter'))
    .globalResources(PLATFORM.moduleName('util/numeral-two-decimals-value-converter'))
    .globalResources(PLATFORM.moduleName('util/rate-as-percent-value-converter'))
    .globalResources(PLATFORM.moduleName('util/preserve-breaks'))
  ;

  // Uncomment the line below to enable animation.
  // aurelia.use.plugin(PLATFORM.moduleName('aurelia-animator-css'));
  // if the css animator is enabled, add swap-order="after" to all router-view elements

  // Anyone wanting to use HTMLImports to load views, will need to install the following plugin.
  // aurelia.use.plugin(PLATFORM.moduleName('aurelia-html-import-template-loader'));

  await aurelia.start();
  await aurelia.setRoot(PLATFORM.moduleName('app'));
}
