import Backend from 'backend';
import {inject} from 'aurelia-framework';
import {Router} from 'aurelia-router';

@inject(Backend, Router)
export class BreweryEditScreen {
    backend;

    // To be posted
    editbrewery;

    router;

    constructor(backend, router) {
        this.backend = backend;
        this.router = router;
    }

    activate(params) {
        return Promise.all([
            this.backend.BreweryCrud_getForEdit()
                .then(result => this.editbrewery = result.breweryEditDto),
          ])
    }

    submit() {
        return this.backend.BreweryCrud_edit(this.editbrewery)
            .then(() => this.router.navigateToRoute("brewery-details"))
    }
}
