import Backend from 'backend';
import {inject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {ValidationRules, ValidationController} from 'aurelia-validation';
import {RangeOverrideValueConverter} from 'util/range-override-value-converter';
import numeral from "numeral";

@inject(Backend, Router, ValidationController)
export class ProductCategoryCreateScreen {
  backend;
  validationController;

  // To be posted
  productCategory = {};

  router;

  constructor(backendFacade, router, validationController) {
    this.backend = backendFacade;
    this.router = router;
    this.validationController = validationController;
  }

  bind() {
    ValidationRules
      .ensure('name')
        .displayName('Navn')
        .required()
      .on(this.productCategory);
  }

  createSubmit() {
    return this.validationController.validate()
      .then(result => {
        if (!result.valid) {
          return;
        }

        return this.backend.ProductCategoryCrud_create(this.productCategory)
          .then(() => this.router.navigateToRoute('product-category-index'));
      });
  }
}
