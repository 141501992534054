import Backend from 'backend';
import {bindable, customElement, inject, observable, TaskQueue} from 'aurelia-framework';
import {OrderDocumentTypeValueConverter} from "util/order-document-type-value-converter";

@customElement('prefill-source-chooser-component')
@inject(Backend, Element, TaskQueue)
export class PrefillSourceChooserComponent {
  backend;
  element;
  taskQueue;

  @bindable()
  orderId;
  @bindable()
  preferredPrefillSourceType;

  @observable({changeHandler: 'changeSelectedPrefillSource'})
  selectedPrefillSource;

  constructor(backend, element, taskQueue) {
    this.backend = backend;
    this.element = element;
    this.taskQueue = taskQueue;
  }


  attached() {
    this.backend.OrderDocumentListQueryHandler_handle({orderId: this.orderId})
      .then(result => {
        this.documents = result.items;
        this.prefillSources = this.documents
          // For VMP orders we can't use documents without orderline references for all lines because we won't be able
          // to get additional fields from order line. Additional fields such as price, customerlineref and vmp articlenumber.
          .filter(document => !(document.orderSource === 'VMP' && document.linesWithoutOrderLineReference > 0))
          .map(document => {
          return {
            name: document.date + ": " + OrderDocumentTypeValueConverter.toView(document.type) + " - " + document.number,
            id: {
              id: document.id,
              orderDocumentType: document.type,
            },
          }
        });

        // Try to auto select the latest prefill source of the preferred type.
        if (this.preferredPrefillSourceType) {
          // Task queue because it solves a glitch with the source dropdown when opening component several times with different preferences.
          this.taskQueue.queueMicroTask({
            call: () => {
              // Split to array
              let preferredTypes = this.preferredPrefillSourceType.split(',');

              // Try the types in turn. Notice the return statement if a document is found
              for (const preferredType of preferredTypes) {
                // Find documents
                let prefillSourcesOfPreferredType = this.prefillSources
                  .map(prefillSource => prefillSource.id)
                  .filter(id => id.orderDocumentType === preferredType);

                // If found, use it and stop looking
                if (prefillSourcesOfPreferredType.length > 0) {
                  this.selectedPrefillSource = prefillSourcesOfPreferredType[prefillSourcesOfPreferredType.length - 1];
                  return;
                }
              }
            }
          });
        }
      });
  }
  changeSelectedPrefillSource(newValue, old) {
    if (newValue?.id === old?.id) {
      return
    }
    this.element.dispatchEvent(new CustomEvent('selected-prefill-source-changed', {
      detail: {selectedPrefillSource: this.selectedPrefillSource},
      bubbles: true
    }));
  }
}
