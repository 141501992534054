import Backend from 'backend';
import {inject, observable} from 'aurelia-framework';
import {Router} from "aurelia-router";

@inject(Backend, Router)
export class BatchIndexScreen {
    backend;
    router;
    batches = [];

    @observable({changeHandler: 'changeShowInactiveBatches'})
    showInactiveBatches;

    constructor(backend, router) {
        this.backend = backend;
        this.router = router;
    }

    activate(params) {
        this.showInactiveBatches = params.showInactiveBatches === true || params.showInactiveBatches === 'true';
        return this.refresh();
    }

    refresh() {
        return this.backend.BatchCrud_index(this.showInactiveBatches)
            .then(result => this.batches = result);
    }

    changeShowInactiveBatches(newValue, oldValue) {
        // Don't do anything when value is initially bound to checkbox
        if (oldValue === undefined) {
          return;
        }

        // Navigate to same route, but with value in params, to remember state across page reload
        let newUrl = this.router.generate('batch-index', {showInactiveBatches: newValue});
        window.location.href = newUrl;

        // Force reload because we can't change the data underlying the dt-datatables attribute (this.customer) without tripping up the binding engine.
        window.location.reload();
    }
}
