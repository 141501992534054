import Backend from 'backend';
import {customElement, bindable, bindingMode, observable} from 'aurelia-framework';
import {inject} from 'aurelia-framework';
import {download} from "../util/download-file";
import {FeatureCustomizationProvider} from "../util/feature-customization-provider";

@customElement('invoice-export-table-component')
@inject(Backend, Element, FeatureCustomizationProvider)
export class InvoiceExportTableComponent {
  backend;
  element;
  featureCustomizationProvider;

  @bindable()
  invoices;
  @bindable()
  backUrl;

  constructor(backend, element, featureCustomizationProvider) {
    this.backend = backend;
    this.element = element;
    this.featureCustomizationProvider = featureCustomizationProvider;
  }

  attached() {

  }

  checked = false;
  checkAll() {
    this.checked = !this.checked;
    this.invoices
      .filter(invoice => !invoice.bundled)
      .forEach(invoice => invoice.checked = this.checked);
  }

  submitExportInvoices() {
    let checkedInvoiceIds = this.invoices
      .filter(invoice => invoice.checked)
      .map(invoice => invoice.invoiceId);

    return this.backend.ExportInvoicesCommandHandler_handle({invoiceIds: checkedInvoiceIds})
      .then(result => download(result.fileBase64, result.filename))
  }
}
