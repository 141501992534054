export class PackagingTypeValueConverter {
  static x = {
    BOTTLE: 'Flaske',
    BOX: 'Boks',
    KEG: 'Fat'
  };

  toView(value) {
    return PackagingTypeValueConverter.toView(value);
  }

  static toView(value) {
    return this.x[value];
  }
}
