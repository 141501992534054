import numeral from 'numeral';

export class booleanVsStringValueConverter {
  toView(value) {
    return value === true || value.toLowerCase() === 'true';
  }

  fromView(value) {
    return value.toString();
  }
}
