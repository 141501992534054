import {Router} from 'aurelia-router';
import ScrollBehavior from "scroll-behavior";

export function configure(aurelia) {
  let router = aurelia.container.get(Router);

  let listeners = [];

  function getKey(locationObject, nullableElementKey) {
    return locationObject + '|' + nullableElementKey;
  }

  const scrollBehavior = new ScrollBehavior({
    addNavigationListener: listener => {
      listeners.push(listener);
      return listener => listeners.splice(listeners.findIndex(listener), 1);
    },

    stateStorage: {
      save: (location, element, scrollValues) => {
        let json = JSON.stringify(scrollValues);
        let key = getKey(location, element);
        // console.log('saving ' + value + ' for ' + key);
        window.sessionStorage.setItem(key, json);
      },
      read: (location, element) => {
        let key = getKey(location, element);
        let json = window.sessionStorage.getItem(key);
        // console.log('reading ' + scrollValues + ' for ' + key);
        let scrollValues = JSON.parse(json);
        return scrollValues;
      }
    },

    getCurrentLocation: function () {
      let currentInstruction = router.currentInstruction;
      if (!currentInstruction) {
        return null;
      }
      return router.generate(currentInstruction.config.name, Object.assign(currentInstruction.params, currentInstruction.queryParams));
    },

    shouldUpdateScroll: function (previousContext, context) {
      let navigationInstruction = context;
      if (navigationInstruction.queryParams.restoreScroll) {
        // Unset the flag so it doesn't affect the lookup of saved state.
        delete navigationInstruction.queryParams.restoreScroll;
        // console.log('Is restoreScroll.')
        return true;
      } else {
        // return true;
        if (navigationInstruction.router.isNavigatingNew) {
          // console.log('Is new, NO restore.       ' + (navigationInstruction.router.isNavigatingRefresh ? 'Is also refresh!!' : ''))
          return false;
        } else {
          // console.log('Is old, RESTORE.       ' + (navigationInstruction.router.isNavigatingRefresh ? 'Is also refresh!!' : ''))
          return true;
        }
      }
    }
  });

  aurelia.container.registerInstance(ScrollBehavior, scrollBehavior);
  router.pipelineProvider.addStep('preActivate', {
    run(navigationInstruction, next) {
      for (const listener of listeners) {
        listener({action: null});
      }
      return next();
    }
  });

  router.pipelineProvider.addStep('postRender', {
    run(navigationInstruction, next) {
      scrollBehavior.updateScroll(null, navigationInstruction);
      return next();
    }
  });

  return Promise.resolve();
}
