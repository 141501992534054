import Backend from 'backend';
import {inject} from 'aurelia-framework';
import {download} from "../util/download-file";

@inject(Backend)
export class ExciseDutyReportDetailsScreen {
  backend;

  exciseReportId;
  report

  queryInProgress;
  queryError;

  constructor(backend) {
    this.backend = backend;
  }

  activate(params) {
    this.exciseReportId = params.exciseReportId;
    return this.refresh();
  }

  refresh() {
    return Promise.all([
        this.backend.ExciseDutyReportQueryHandler_handle(this.exciseReportId)
          .then(result => this.report = result),
      ])
      .catch(error => this.queryError = "Error: " + error.toString());
  }

  queryFile() {
    this.queryError = '';
    this.queryInProgress = true;
    return this.backend.DutyCsvReportQueryHandler_handleBase64({
        exciseReportId: this.exciseReportId
      })
      .then(result => download(result.fileBase64, result.filename))
      .catch(error => this.queryError = "Error: " + error.toString())
      .then(() => this.queryInProgress = false);
  }

  dismissQueryError() {
    this.queryError = null;
  }
}