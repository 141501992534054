import Backend from 'backend';
import {inject} from 'aurelia-framework';
import {Router} from 'aurelia-router';

@inject(Backend, Router)
export class ProductCategoryDetailsScreen {
    backend;
    router;

    productCategory;

    constructor(backend, router) {
        this.backend = backend;
        this.router = router;
    }

    activate(params) {
        return this.backend.ProductCategoryCrud_details(params.productCategoryId)
                    .then(productCategory => this.productCategory = productCategory);
    }
}
