import Backend from 'backend';
import {inject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {SaleTypeValueConverter} from 'util/sale-type-value-converter';
import moment from 'moment';
import {ValidationController, ValidationRules} from "aurelia-validation";

@inject(Backend, Router, ValidationController)
export class RegisterSaleScreen {
    backend;

    saleTypes;

    command = {
        date: moment().format('YYYY-MM-DD'), // today()
        lines: [
          {} // Initial empty line in gui
          ]
    };

    saveError;
    saveInProgress;

    queryError;

    router;
    validationController;

    constructor(backendFacade, router, validationController) {
        this.backend = backendFacade;
        this.router = router;
        this.validationController = validationController;
    }

    activate() {
        return this.refresh();
    }

    bind() {
      ValidationRules
        .ensure('date')
          .displayName('Dato')
          .required()
        .ensure('invoiceNumber')
          .displayName('Fakturanr.')
          .required()
        .on(this.command);
    }

    refresh() {
        return Promise.all([
              this.backend.SaleTypesForDropdownQueryHandler_handle()
                .then(result => this.saleTypes = result.saleTypes),
          ])
          .catch(error => this.queryError = "Error: " + error.toString())
    }

    createSubmit() {
      return this.validationController.validate()
        .then(result => {
          if (!result.valid) {
              return;
          }
          this.saveError = '';
          return this.backend.RegisterSaleCommandHandler_sale(this.command)
            .then(() => this.router.navigateToRoute('registration-menu', {ok: true}))
            .catch(error => this.saveError = error.message)
          ;
        });
    }

    translateSaleType(value) {
        return SaleTypeValueConverter.toView(value);
    }

    dismissSaveError() {
        this.saveError = null;
    }
}
