export class UnitValueConverter {
  static x = {
    LITER: 'Liter',
    ABV: 'ABV',
    PIECE: 'Stk'
  };

  toView(value) {
    return UnitValueConverter.toView(value);
  }

  static toView(value) {
    return this.x[value];
  }
}
