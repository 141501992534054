import Backend from 'backend';
import {customElement, bindable, bindingMode, observable} from 'aurelia-framework';
import {inject} from 'aurelia-framework';
import {FeatureCustomizationProvider} from "util/feature-customization-provider";

@customElement('order-lines-component')
@inject(Backend, Element, FeatureCustomizationProvider)
export class OrderLinesComponent {
  backend;
  element;
  featureCustomizationProvider;

  @bindable()
  orderId;

  @bindable()
  order;

  documents;
  activeDocument;

  constructor(backend, element, featureCustomizationProvider) {
    this.backend = backend;
    this.element = element;
    this.featureCustomizationProvider = featureCustomizationProvider;
  }

  attached() {

  }
}
