import Backend from 'backend';
import {inject} from 'aurelia-framework';

@inject(Backend)
export class CustomerPriceGroupIndexScreen {
    backend;
    productCategories = [];

    constructor(backend) {
        this.backend = backend;
    }

    activate() {
        return this.refresh();
    }

    refresh() {
        return this.backend.CustomerPriceGroupCrud_index()
            .then(result => this.productCategories = result);
    }
}
