import Backend from 'backend';
import {customElement, bindable, bindingMode, observable} from 'aurelia-framework';
import {inject} from 'aurelia-framework';
import {OrderStepValueConverter} from "../util/order-step-value-converter";

@customElement('order-step-component')
@inject(Backend, Element)
export class OrderStepComponent {
  backend;
  element;

  @bindable()
  order;
  @bindable()
  orderPolicy;

  // Modal refs
  createOrderConfirmationModal;
  createDeliveryNoteModal;
  createMovementGroupModal;
  createInvoiceModal;
  createCreditNoteModal;

  selectedOverrideOrderStep;

  constructor(backend, element) {
    this.backend = backend;
    this.element = element;
  }

  attached() {
    this.backend.DropdownItemQueryHandler_handle('OrderStep')
              .then(result => this.orderSteps = result.items);
  }

  overrideOrderStep() {
    return this.backend.OverrideOrderStepCommandHandler_handle({orderId: this.order.orderId, newOrderStep: this.selectedOverrideOrderStep})
      .then(() => {
        this.raiseEvent_orderStateChanged();
        this.showOverrideForm = false;
      });
  }

  closeModalsAndEmitEvent_orderStateChanged(documentType, documentId) {
    // Raise event
    let activeDocumentDto = {
      type: documentType,
      id: documentId
    }
    this.raiseEvent_orderStateChanged(activeDocumentDto);

      if (this.createOrderConfirmationModal?.isOpen) { this.createOrderConfirmationModal.close();}
      if (this.createDeliveryNoteModal?.isOpen) { this.createDeliveryNoteModal.close();}
      if (this.createMovementGroupModal?.isOpen) { this.createMovementGroupModal.close();}
      if (this.createInvoiceModal?.isOpen) { this.createInvoiceModal.close();}
      if (this.createCreditNoteModal?.isOpen) { this.createCreditNoteModal.close();}
  }

  raiseEvent_orderStateChanged(activeDocumentDto) {
    this.element.dispatchEvent(new CustomEvent('order-state-changed', {
      detail: {activeDocumentDto: activeDocumentDto},
      bubbles: true
    }));
  }

  translateOrderStep(value) {
    return OrderStepValueConverter.toView(value);
  }
}
