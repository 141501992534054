import Backend from 'backend';
import {inject} from 'aurelia-framework';

@inject(Backend)
export class YearlyCustomerReportScreen {
  backend;

  // For dropdowns
  reportingPeriodYears;

  // Query
  selectedReportingPeriodYear;

  // Query result
  customers;

  constructor(backend) {
    this.backend = backend;
  }

  activate() {
    return this.refresh();
  }

  refresh() {
    Promise.all([
        this.backend.ReportingPeriodYearsForDropdownQueryHandler_handle()
          .then(result => this.reportingPeriodYears = result.reportingPeriodYears),
      ])
      .catch(error => this.queryError = "Error: " + error.toString());
  }

  query() {
    return this.backend.YearlyCustomerReportQueryHandler_handle({
        year: this.selectedReportingPeriodYear
      })
      .then(result => this.customers = result.customers);
  }

  checked = false;
  checkAll() {
    this.checked = !this.checked;
    this.customers
      .filter(customer => customer.customerEmail)
      .filter(customer => !customer.alreadySent)
      .filter(customer => customer.customerShouldHaveYearlyReportOnEmail)
      .forEach(customer => customer.checked = this.checked);
  }

  submitSendEmails() {
    return this.backend.SendYearlySalesReportsCommandHandler_handle({
      year: this.selectedReportingPeriodYear,
      customers: this.customers
        .filter(customer => customer.checked)
        .map(customer => ({
          customerId: customer.customerId
        }))
    })
      .then(() => this.query());
  }

  dismissSaveError() {
    this.queryError = null;
  }
}
