import Backend from 'backend';
import {customElement, bindable, bindingMode, observable} from 'aurelia-framework';
import {inject} from 'aurelia-framework';
import {ValidationController, ValidationRules} from "aurelia-validation";

@customElement('create-order-confirmation-component')
@inject(Backend, Element, ValidationController)
export class CreateOrderConfirmationComponent {
  backend;
  element;
  validationController;

  @bindable()
  orderId;

  createdOrderConfirmationId;

  commandTemplate = {lines: []};

  constructor(backend, element, validationController) {
    this.backend = backend;
    this.element = element;
    this.validationController = validationController;
  }

  bind() {
    // Set up initial validation. Don't wait to run this. Validation should be enabled before the command template
    // is loaded from api.
    this.refreshValidationRules();
  }

  changeSelectedPrefillSource(selectedPrefillSource) {
    this.backend.CreateOrderConfirmationCommandHandler_getTemplate({orderDocumentType: selectedPrefillSource.orderDocumentType, id: selectedPrefillSource.id})
      .then(result => {
        // Store result
        this.commandTemplate = result.command;

        this.refreshValidationRules();
      });
  }

  refreshValidationRules() {
    // Add validation to result
    ValidationRules
      .ensure('deliveryDate')
      .displayName('Leveringsdato')
      .required()
      .on(this.commandTemplate);
    // Add validation to result's lines
    for (const line of this.commandTemplate.lines) {
      ValidationRules
        .ensure('confirmedQuantity')
        .displayName('Bekreftet antall')
        .required()
        .then()
        .satisfies(value => Number.isInteger(Number(value))) // Number() converts string to number. Otherwise "1" is not an integer.
        .withMessage('Bekreftet antall må være et heltall')
        .on(line);
    }
  }

  saveButtonClicked() {
    return this._save()
          .then(orderConfirmationId => {
            // Don't do this on saveAndSend because we don't want to show the send button while we're in progress (risk of double click)
            this.createdOrderConfirmationId = orderConfirmationId;
          });
  }

  sendButtonClicked() {
    return this._send(this.createdOrderConfirmationId);
  }

  saveAndSendButtonClicked() {
    return this._save()
      .then(orderConfirmationId => this._send(orderConfirmationId));
  }

  closeButtonClicked() {
    this.emitDoneEvent(this.createdOrderConfirmationId);
  }

  _save() {
    return this.validationController.validate()
      .then(result => {
        if (!result.valid) {
          return;
        }

        return this.backend.CreateOrderConfirmationCommandHandler_handle({
          orderId: this.commandTemplate.orderId,
          deliveryDate: this.commandTemplate.deliveryDate,
          newAdditionalFields: [],
          lines: this.commandTemplate.lines.map(line => ({
            orderLineId: line.orderLineId,
            confirmedQuantity: line.confirmedQuantity,
            newAdditionalFields: line.newAdditionalFields,
          }))
        })
      });
  }

  _send(orderConfirmationId) {
    return this.backend.SendOrderConfirmationEdiCommandHandler_handle({orderConfirmationId: orderConfirmationId})
      .then(() => this.emitDoneEvent(orderConfirmationId));
  }

  emitDoneEvent(orderConfirmationId) {
    this.element.dispatchEvent(new CustomEvent('done', {
      detail: {orderConfirmationId: orderConfirmationId},
      bubbles: true
    }));

    // Clear form for reuse
    this.createdOrderConfirmationId = null;
  }
}
