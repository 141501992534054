import Backend from 'backend';
import {inject} from 'aurelia-framework';
import {FeatureCustomizationProvider} from "../util/feature-customization-provider";

@inject(Backend, FeatureCustomizationProvider)
export class ProductIndexScreen {
    backend;
    products = [];
    featureCustomizationProvider;

    constructor(backend, featureCustomizationProvider) {
        this.backend = backend;
        this.featureCustomizationProvider = featureCustomizationProvider;
    }

    activate() {
        return this.refresh();
    }

    refresh() {
        return this.backend.ProductCrud_index()
            .then(result => this.products = result);
    }
}
