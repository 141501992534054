import Backend from 'backend';
import {inject, observable} from 'aurelia-framework';
import moment from 'moment';
import {DateTime} from "luxon";
import {ValidationController, ValidationRules} from "aurelia-validation";

@inject(Backend, ValidationController)
export class InventoryValueScreen {
  backend;
  validationController;

  // For dropdowns
  storageAreas;

  inventoryDateInclusive = moment().format('YYYY-MM-DD');
  selectedStorageAreaId;

  storageAreaInventories;

  constructor(backend, validationController) {
    this.validationController = validationController;
    this.threeMonthsFromNow = DateTime.local().plus({months: 3}).toISO();
    this.backend = backend;
  }

  activate() {
    return this.refresh();
  }

  bind() {
    ValidationRules
      .ensure('inventoryDateInclusive')
        .displayName('Dato')
        .required()
      .on(this);
  }

  refresh() {
    return Promise.all([
        this.backend.StorageAreasForDropdownQueryHandler_handle()
          .then(result => {
            this.storageAreas = result.storageAreas;
            this.storageAreas.unshift({id: null, name: 'Vis alle lagre'})
          }),
      ]);
  }

  query() {
    return this.validationController.validate()
      .then(result => {
        if (!result.valid) {
          return;
        }
        return this.backend.InventoryAccountingValueQueryHandler_handle({
            storageAreaId: this.selectedStorageAreaId,
            date: this.inventoryDateInclusive
          })
          .then(result => {
            this.articleMissingCostPriceCount = result.articleMissingCostPriceCount;
            this.articleWithNegativeInventoryCount = result.articleWithNegativeInventoryCount;
            this.storageAreaInventories = result.storageAreas;
          });
      });
  }

  isInPast(date) {
    return new Date(date) < new Date();
  }

  isSoon(date) {
    return new Date(date) < new Date(this.threeMonthsFromNow);
  }
}
