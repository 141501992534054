export class MovementTypeValueConverter {
  static x = {
    SALE: 'Salg',
    TAPPING: 'Tapping',
    RECEIVED_GOODS: 'Mottak av ubeskattede varer',
    RECEIVED_GOODS_PRE_TAXED: 'Mottak av beskattede varer',
    SENT_GOODS: 'Utlevert annen avgiftsregistrert aktør',
    SHORTAGE: 'Manko',
    SHORTAGE_OFFSET: 'Manko (utenfor avgiftsrapport)',
    SURPLUS: 'Overtallighet',
    SURPLUS_OFFSET: 'Overtallighet (utenfor avgiftsrapport)',
    DESTRUCTION: 'Destruksjon',
    PRODUCT_SAMPLE: 'Vareprøve',
    SALE_DUTY_FREE: 'Salg avgiftsfritt - 30',
    RETURNED_GOODS: 'Retur',
    RETURNED_GOODS_DUTY_FREE: 'Retur avgiftsfritt - 51',
    TRANSFER_OUT: 'Overføring(intern) ut',
    TRANSFER_IN: 'Overføring(intern) inn',
    OPENING_BALANCE: 'Overført beholdning fra gammelt lagersystem',
    REVERT: 'Reversering'
  };

  toView(value) {
    return MovementTypeValueConverter.toView(value);
  }

  static toView(value) {
    if (value in this.x){
      return this.x[value];
    }
    return value;
  }
}
