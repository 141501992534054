import Backend from 'backend';
import {inject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {ValidationController, ValidationRules} from 'aurelia-validation';
import {MaterialValueConverter} from 'util/material-value-converter';
import {PackagingTypeValueConverter} from 'util/packaging-type-value-converter';
import {RecyclingSchemeValueConverter} from 'util/recycling-scheme-value-converter';
import numeral from 'numeral';
import {PanteSatsValueConverter} from "../util/pante-sats-value-converter";


@inject(Backend, Router, ValidationController)
export class PackagingEditScreen {
    backend;
    router;
    validationController;

    // For dropdowns
    materials;
    packagingTypes;
    recyclingSchemes;
    editpackaging = {volume: ''};

    constructor(backend, router, validationController) {
        this.backend = backend;
        this.router = router;
        this.validationController = validationController;
    }

    activate(params) {
        return Promise.all([
              this.backend.PackagingCrud_getForEdit(params.packagingId)
                .then(result => {
                  this.editpackaging = result.packagingEditDto;
                  this.canEditMaterial = result.canEditMaterial;
                  this.canEditRecyclingScheme = result.canEditRecyclingScheme;
                  this.canEditVolume = result.canEditVolume;
                }),
              this.backend.MaterialsForDropdownQueryHandler_handle()
                .then(result => this.materials = result.materials),
              this.backend.PackagingTypesForDropdownQueryHandler_handle()
                .then(result => this.packagingTypes = result.packagingTypes),
              this.backend.RecyclingSchemesForDropdownQueryHandler_handle()
                .then(result => this.recyclingSchemes = result.recyclingSchemes),
              this.backend.DropdownItemQueryHandler_handle('PanteSats')
                .then(result => {
                  this.panteSatses = result.items;
                  this.panteSatses.unshift({id: null, name: 'NONE'});
                }),
          ])
          .catch(error => this.queryError = "Error: " + error.toString());
    }

    bind() {
      ValidationRules
        .ensure('name')
          .displayName('Navn')
          .required()
        .ensure('volume')
          .displayName('Volume')
          .required()
          .then()
          .satisfies(value => numeral.validate(value, 'en'))
          .withMessage('Volum må være et tall')
        .ensure('quantityPrCrate')
          .displayName('Antall pr kasse/eske')
          .required()
          .then()
          .satisfies(value => Number.isInteger(Number(value))) // Number() converts string to number. Otherwise "1" is not an integer.
          .withMessage('Antall pr kasse/eske må være et heltall')
        .on(this.editpackaging);
    }

    submit() {
        // this.validationController.addError('sfgssdfg', this.editpackaging, 'volume');
        // this.validationController.addError('sfgssdfg', this.editpackaging, 'volume');
        this.validationController.validate()
          .then(result => {
              if (!result.valid) {
                  return;
              }
              this.saveInProgress = true;
              this.backend.PackagingCrud_edit(this.editpackaging.packagingId, this.editpackaging)
                .then(() => this.router.navigateToRoute("packaging-details", {packagingId: this.editpackaging.packagingId}))
                .catch(error => this.saveError = error)
                .then(() => this.saveInProgress = false);
          });
    }

    dismissSaveError() {
        this.saveError = null;
    }

    dismissQueryError() {
        this.queryError = null;
    }

    translateMaterial(value) {
        return MaterialValueConverter.toView(value);
    }

    translatePackagingType(value) {
        return PackagingTypeValueConverter.toView(value);
    }

    translatePanteSats(value) {
        return PanteSatsValueConverter.toView(value);
    }

    translateRecyclingScheme(value) {
        return RecyclingSchemeValueConverter.toView(value);
    }
}
