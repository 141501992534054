import {inject, singleton} from "aurelia-framework";
import Backend from "../backend";

@inject(Backend)
@singleton() // Since singleton lives across different screen navigations the clear() method needs to be used by the screen class to empty the cache on loading a new route view model.
export class BackendCache {
  backend;

  static statici = 0;
  i;

  map = new Map();

  constructor(backend) {
    this.i = BackendCache.statici++;
    this.backend = backend;
  }

  clear() {
    this.map.clear()
  }

  BatchesForDropdownQueryHandler_handle(query) {
    return this.queryThroughCache(
      () => this.backend.BatchesForDropdownQueryHandler_handle(query),
      'BatchesForDropdownQueryHandler_handle(' + JSON.stringify(query) + ')');
  }


  BatchesWithInventoryForDropdownQueryHandler_handle(query) {
    return this.queryThroughCache(
      () => this.backend.BatchesWithInventoryForDropdownQueryHandler_handle(query),
      'BatchesWithInventoryForDropdownQueryHandler_handle(' + JSON.stringify(query) + ')');
  }

  CustomersForDropdownQueryHandler_handle() {
    if (this.map.has('CustomersForDropdownQueryHandler_handle')) {
      return this.map.get('CustomersForDropdownQueryHandler_handle')
    } else {
      let promise = this.backend.CustomersForDropdownQueryHandler_handle();
      this.map.set('CustomersForDropdownQueryHandler_handle', promise);
      return promise;
    }
  }

  ExciceDutyReportExistsQueryHandler_handle(date) {
    return this.queryThroughCache(
      () => this.backend.ExciceDutyReportExistsQueryHandler_handle(date), 
      'ExciceDutyReportExistsQueryHandler_handle(' + date + ')');
  }

  ProductArticlesForDropdownQueryHandler_handle(query) {
    return this.queryThroughCache(
      () => this.backend.ProductArticlesForDropdownQueryHandler_handle(query),
      'ProductArticlesForDropdownQueryHandler_handle(' + JSON.stringify(query) + ')');
  }

  ProductArticlesWithInventoryForDropdownQueryHandler_handle(query) {
    return this.queryThroughCache(
      () => this.backend.ProductArticlesWithInventoryForDropdownQueryHandler_handle(query),
      'ProductArticlesWithInventoryForDropdownQueryHandler_handle(' + JSON.stringify(query) + ')');
  }

  SaleTypesForDropdownQueryHandler_handle() {
    if (this.map.has('SaleTypesForDropdownQueryHandler_handle')) {
      return this.map.get('SaleTypesForDropdownQueryHandler_handle')
    } else {
      let promise = this.backend.SaleTypesForDropdownQueryHandler_handle();
      this.map.set('SaleTypesForDropdownQueryHandler_handle', promise);
      return promise;
    }
  }

  StorageAreasForDropdownQueryHandler_handle() {
    if (this.map.has('StorageAreasForDropdownQueryHandler_handle')) {
      return this.map.get('StorageAreasForDropdownQueryHandler_handle')
    } else {
      let promise = this.backend.StorageAreasForDropdownQueryHandler_handle();
      this.map.set('StorageAreasForDropdownQueryHandler_handle', promise);
      return promise;
    }
  }

  DropdownItemQueryHandler_handle(query) {
    return this.queryThroughCache(
      () => this.backend.DropdownItemQueryHandler_handle(query),
      'DropdownItemQueryHandler_handle(' + JSON.stringify(query) + ')');
  }

  queryThroughCache(realBackendFunction, cacheKey) {
    if (this.map.has(cacheKey)) {
      return this.map.get(cacheKey)
    } else {
      let promise = realBackendFunction();
      this.map.set(cacheKey, promise);
      return promise;
    }
  }
}
