import Backend from 'backend';
import {inject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {FeatureCustomizationProvider} from "../util/feature-customization-provider";

@inject(Backend, Router, FeatureCustomizationProvider)
export class BreweryDetailsScreen {
    backend;
    router;
    featureCustomizationProvider;

    brewery;

    showDeleteConfirm = false;
    deleteError;

    constructor(backend, router, featureCustomizationProvider) {
        this.backend = backend;
        this.router = router;
        this.featureCustomizationProvider = featureCustomizationProvider;
    }

    activate() {
        return this.backend.BreweryCrud_details()
                    .then(brewery => this.brewery = brewery);
    }
}
