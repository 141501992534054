import Backend from 'backend';
import {inject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import moment from 'moment';
import {ValidationController} from "aurelia-validation";

@inject(Backend, Router, ValidationController)
export class RegisterSentGoodsScreen {
  backend;

  command = {
    date: moment().format('YYYY-MM-DD'), // today()
    lines: [
      {} // Initial empty line in gui
    ]
  };

  router;
  validationController;

  constructor(backendFacade, router, validationController) {
    this.backend = backendFacade;
    this.router = router;
    this.validationController = validationController;
  }

  createSubmit() {
    return this.validationController.validate()
      .then(result => {
        if (!result.valid) {
          return;
        }
        return this.backend.RegisterSentGoodsCommandHandler_handle(this.command)
          .then(() => this.router.navigateToRoute('registration-menu', {ok: true}))
          ;
      });
  }
}
