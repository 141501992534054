import Backend from 'backend';
import {customElement, bindable, bindingMode, observable} from 'aurelia-framework';
import {inject} from 'aurelia-framework';

@customElement('create-movement-group-component')
@inject(Backend, Element)
export class CreateMovementGroupComponent {
  backend;
  element;

  @bindable()
  orderId;

  createdMovementGroupId;

  @bindable()
  order;

  command = {
      lines: []
    };

  constructor(backend, element) {
    this.backend = backend;
    this.element = element;
  }

  changeSelectedPrefillSource(selectedPrefillSource) {
    this.backend.RegisterMovementsFromOrderCommandHandler_getTemplate({orderDocumentType: selectedPrefillSource.orderDocumentType, id: selectedPrefillSource.id})
      .then(result => {
        this.command = result.command;
      });
  }

  saveButtonClicked() {
    return this.backend.RegisterMovementsFromOrderCommandHandler_handle({
          orderId: this.command.orderId,
          storageAreaId: this.command.storageAreaId,
          date: this.command.date,
          saleType: this.command.saleType,
          isDutyFree: this.command.isDutyFree,
          correspondingSalePeriod: this.command.correspondingSalePeriod,
          lines: this.command.lines.map(line => ({
            orderLineId: line.orderLineId,
            articleId: line.articleId,
            batchId: line.batchId,
            quantity: line.quantity,
          }))
      })
      .then(movementGroupId => this.createdMovementGroupId = movementGroupId)
      .then(() => this.emitDoneEvent());
  }

  closeButtonClicked() {
    this.emitDoneEvent();
  }

  emitDoneEvent() {
    this.element.dispatchEvent(new CustomEvent('done', {
      detail: {movementGroupId: this.createdMovementGroupId},
      bubbles: true
    }));

    // Clear form for reuse
    this.createdMovementGroupId = null;
  }
}
