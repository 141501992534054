import Backend from 'backend';
import {customElement, bindable, bindingMode, observable} from 'aurelia-framework';
import {inject} from 'aurelia-framework';
import {download} from "../util/download-file";

@customElement('transfer-details-component')
@inject(Backend, Element)
export class TransferDetailsComponent {
  backend;
  element;

  constructor(backend, element) {
    this.backend = backend;
    this.element = element;
  }

  refresh(transferId) {
    return this.backend.TransferDetailsQueryHandler_handle({transferId: transferId})
      .then(result => this.transfer = result)
  }
}
