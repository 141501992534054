import { bindable, customElement} from 'aurelia-framework';

@customElement('additional-fields')
export class AdditionalFields {
  @bindable additionalField;
  @bindable type; // 'key' or 'value'

  display;

  attached() {
    // Validate input
    if (this.type !== 'key' && this.type !== 'value') {
      throw new Error('Type parameter must be either \'key\' or \'value\', but was: [' + this.type + '].');
    }

    // Translate both key and value
    let translatedAdditionalValue = this.translate(this.additionalField);

    // Should we display the key or the value?
    this.display = translatedAdditionalValue[this.type];
  }

  translate(additionalField) {
    switch (additionalField.key) {
      case 'vmp_orderType':                      return {key: "VMP ordretype",                  value: AdditionalFields.translateVmpOrderType(additionalField.value)};
      case 'vmp_storeNumber':                    return {key: "VMP butikknr.",                  value: additionalField.value};
      case 'vmp_storeName':                      return {key: "VMP butikknavn",                 value: additionalField.value};
      case 'vmp_deliveredWithPONumber':          return {key: "Levert med ordrenr.",            value: additionalField.value};
      case 'vmp_articleNumber':                  return {key: "VMP art.nr.",                    value: additionalField.value};
      case 'vmp_description':                    return {key: "VMP beskrivelse",                value: additionalField.value};
      case 'vmp_levertSomKvartpall':             return {key: "Ønskes levert som kvartpall",    value: AdditionalFields.translateBoolean(additionalField.value)};
      case 'vmp_levertSomKvartpallResponse':     return {key: "Bekreft leveres som kvartpall",  value: AdditionalFields.translateBoolean(additionalField.value)};
      case 'vmp_returnCode':                     return {key: "Returkode",                      value: additionalField.value};
      case 'vmp_returnCodeParsed':               return {key: "Returkode",                      value: additionalField.value};
      case 'vmp_lotNumber':                      return {key: "Lotnr.",                         value: additionalField.value};
      case 'vmp_opprinneligOrdre':               return {key: "Opprinnelig ordrenr.",           value: additionalField.value};
    }
  }

  static translateBoolean(value) {
    switch (value) {
      case true:
      case 'true':
        return 'Ja';
      case false:
      case 'false':
        return 'Nei';
      default:
        return value;
    }
  }

  static translateVmpOrderType(value) {
    switch (value) {
      case 'REGULAR':
        return 'Vanlig';
      case 'LEVERT_IKKE_BESTILLT_VARE':
        return 'Levert ikke-bestillt vare';
      case 'KASSASJONSORDRE':
        return 'Kassasjon';
      case 'RETURORDRE':
        return 'Retur';
      default:
        return value;
    }
  }
}
