import Backend from 'backend';
import {inject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {ValidationRules, ValidationController} from 'aurelia-validation';
import {BeverageTypeValueConverter} from 'util/beverage-type-value-converter';
import numeral from "numeral";
import {FeatureCustomizationProvider} from "../util/feature-customization-provider";

@inject(Backend, Router, ValidationController, FeatureCustomizationProvider)
export class ProductCreateScreen {
    backend;
    router;
    validationRules;

    // For dropdowns
    beverageTypes;

    // To be posted
    product = {};

    saveError;
    router;
    featureCustomizationProvider;

    constructor(backendFacade, router, validationController, featureCustomizationProvider) {
        this.backend = backendFacade;
        this.router = router;
        this.validationController = validationController;
        this.featureCustomizationProvider = featureCustomizationProvider;
    }

    activate() {
        return this.refresh();
    }

    bind() {
        ValidationRules
          .ensure('name')
              .displayName('Navn')
              .required()
          .ensure('abv')
              .displayName('ABV')
              .required()
              .then()
              .satisfies(value => numeral.validate(value, 'en'))
              .withMessage('ABV må være et tall')
          .on(this.product);
    }

    refresh() {
        return Promise.all([
              this.backend.BeverageTypesForDropdownQueryHandler_handle()
                .then(result => this.beverageTypes = result.beverageTypes),
            this.backend.DropdownItemQueryHandler_handle('ProductCategory')
                .then(result => {
                  this.productCategories = result.items;
                  this.productCategories.unshift({id: null, name: 'Ingen'});
                })
          ])
          .catch(error => this.queryError = "Error: " + error.toString())
    }

    createSubmit() {
        this.validationController.validate()
            .then(result => {
                if (!result.valid) {
                    return;
                }
                this.saveInProgress = true;
                this.backend.ProductCrud_create(this.product)
                    .then(() => this.router.navigateToRoute('product-index'))
                    .catch(error => this.saveError = error)
                    .then(() => this.saveInProgress = false)
                ;
            });
    }

    translateBeverageType(value) {
        return BeverageTypeValueConverter.toView(value);
    }

    dismissSaveError() {
        this.saveError = null;
    }
}
