export class OrderTypeValueConverter {
  static x = {
    SALE: 'Salg',
    RETURNED_GOODS: 'Retur',
    Alle: 'Alle'
  };

  toView(value) {
    return OrderTypeValueConverter.toView(value);
  }

  static toView(value) {
    return this.x[value];
  }
}
