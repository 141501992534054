import Backend from 'backend';
import {bindable, computedFrom, bindingMode, customElement, inject, observable} from 'aurelia-framework';
import {BackendCache} from "../util/backend-cache";

@customElement('storage-area-dropdown-component')
@inject(Backend, Element, BackendCache)
export class StorageAreaDropdownComponent {
  backend;
  backendCache;
  element;

  @bindable({defaultBindingMode: bindingMode.twoWay}) value;
  @bindable() forceNative = false;
  @bindable() label = 'Lager';

  constructor(backend, element, backendCache) {
    this.backend = backend;
    this.element = element;
    this.backendCache = backendCache;
  }

  attached() {
    this.refresh();
  }

  refresh() {
    return this.backendCache.StorageAreasForDropdownQueryHandler_handle()
      .then(result => {
        this.storageAreas = result.storageAreas.slice();
        this.storageAreas.unshift({id: null, name: 'Velg lager'});
      });
  }
}
