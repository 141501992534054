export class PanteSatsValueConverter {
  static x = {
    NONE: "Ingen",
    KR_2_00__2018: "Kr 2 - flasker og bokser med opptil ½ liter innhold",
    KR_3_00__2018: "Kr 3 - emballasje med over ½ liter innhold",
    KR_26_70__KEYKEG_10: "Kr 26,70 - KeyKeg 10 liter",
    KR_40_00__KEYKEG_20: "Kr 40,00 - KeyKeg 20 liter",
    KR_53_30__KEYKEG_30: "Kr 53,30 - KeyKeg 30 liter",
  };

  toView(value) {
    return PanteSatsValueConverter.toView(value);
  }

  static toView(value) {
    return this.x[value];
  }
}
