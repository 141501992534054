import Backend from 'backend';
import {inject} from 'aurelia-framework';

@inject(Backend)
export class HealthDirectorateReportScreen {
    backend;

    // For dropdowns
    reportingPeriods;

    // Query
    selectedReportingPeriod;

    // Query result
    lines;
  queryInProgress
    constructor(backend) {
        this.backend = backend;
    }

    activate() {
        return this.refresh();
    }

    refresh() {
        Promise.all([
              this.backend.ReportingPeriodsForDropdownQueryHandler_handle()
                .then(result => this.reportingPeriods = result.reportingPeriods),
          ])
          .catch(error => this.queryError = "Error: " + error.toString());
    }

    query() {
        this.queryInProgress = true;
    this.queryError = null;
        return this.backend.HealthDirectorateReportQueryHandler_handle({
                           yearMonth: this.selectedReportingPeriod
                    })
                    .then(result => this.lines = result.lines)
                    .catch(error => this.queryError = "Error: " + error.toString())
                    .then(result => this.queryInProgress = false)
          ;
    }

  dismissQueryError() {
    this.queryError = null;
  }
}