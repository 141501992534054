import numeral from 'numeral';

export class NumeralOneDecimalsValueConverter {
  toView(value) {
    if (!numeral.validate(value, 'en')) {
        return value;
    }
    let displayValue = numeral(value).format('0.0');
    return displayValue;
  }

  fromView(value) {
    if (!numeral.validate(value)) {
        return value;
    }
    let viewModelValue = numeral(value).value();
    return viewModelValue;
  }
}
