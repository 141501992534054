import Backend from 'backend';
import {inject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {CustomerTypeValueConverter} from 'util/customer-type-value-converter';
import {FeatureCustomizationProvider} from "../util/feature-customization-provider";
import {ValidationController, ValidationRules} from "aurelia-validation";
import numeral from "numeral";

@inject(Backend, Router, FeatureCustomizationProvider, ValidationController)
export class CustomerEditScreen {
    backend;

    // For dropdowns
    customerAdditionalCodes;
    customerTypes;
    countries;
    customerPriceGroups;

    // To be posted
    editcustomer;

    router;
    featureCustomizationProvider;
    validationController;

    constructor(backend, router, featureCustomizationProvider, validationController) {
        this.backend = backend;
        this.router = router;
        this.featureCustomizationProvider = featureCustomizationProvider;
        this.validationController = validationController;
    }

    activate(params) {
        return Promise.all([
            this.backend.DropdownItemQueryHandler_handle('Countries')
                .then(result => {
                  this.countries = result.items;
                  this.countries.unshift({id: null, name: 'Ingen'});
                }),
            this.backend.DropdownItemQueryHandler_handle('CustomerPriceGroup')
                .then(result => {
                  this.customerPriceGroups = result.items;
                  this.customerPriceGroups.unshift({id: null, name: 'Ingen'});
                }),
            this.backend.CustomerTypesForDropdownQueryHandler_handle()
                .then(result => this.customerTypes = result.customerTypes),
            this.backend.CustomerAdditionalCodesForDropdownQueryHandler_handle()
                .then(result => this.customerAdditionalCodes = result.customerAdditionalCodes),
            this.backend.CustomerCrud_getForEdit(params.customerId)
                .then(result => {
                    this.editcustomer = result.customerEditDto;
                    this.canEditCustomerType = result.canEditCustomerType;
                }),
          ])
    }

    bind() {
      ValidationRules
            .ensure('discountRate')
              .displayName('Rabatt')
              .satisfies(value => !value || numeral.validate(value, 'en'))
              .withMessage('Rabatt må være et tall')
            .on(this.editcustomer);
    }

    submit() {
      return this.validationController.validate()
        .then(result => {
          if (!result.valid) {
            return;
          }

          return this.backend.CustomerCrud_edit(this.editcustomer.customerId, this.editcustomer)
            .then(() => this.router.navigateToRoute("customer-details", {customerId: this.editcustomer.customerId}));
        });
    }

    translateCustomerType(value) {
        return CustomerTypeValueConverter.toView(value);
    }
}
