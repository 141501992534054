export class RangeOverrideValueConverter {
  static x = {
    NONE: 'Automatisk - Bruk bryggeriets årlige teller',
    R_0_TO_50: 'småskalaproduksjon — volum t.o.m. 50 000 liter årlig',
    R_50_TO_100: 'småskalaproduksjon — volum over 50 000 t.o.m. 100 000 liter årlig',
    R_100_TO_150: 'småskalaproduksjon — volum over 100 000 t.o.m. 150 000 liter årlig',
    R_150_TO_200: 'småskalaproduksjon — volum over 150 000 t.o.m. 200 000 liter årlig',
    NO_RANGE: 'Ingen avgiftsreduksjon — volum over 200 000 liter årlig, eller produsent omfattes ikke av ordningen ',
  };

  toView(value) {
    return RangeOverrideValueConverter.toView(value);
  }

  static toView(value) {
    return this.x[value];
  }
}
