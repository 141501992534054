import Backend from 'backend';
import {inject} from 'aurelia-framework';
import {download} from "../util/download-file";

@inject(Backend)
export class StorageReportScreen {
    backend;

    // For dropdowns
    reportingPeriods;

    // Query
    selectedReportingPeriod;
    showInactiveArticles;

    // Query result
    report;
    report2;

    constructor(backend) {
        this.backend = backend;
    }

    activate() {
        return this.refresh();
    }

    refresh() {
      Promise.all([
          this.backend.ReportingPeriodsForDropdownQueryHandler_handle()
            .then(result => this.reportingPeriods = result.reportingPeriods),
        ])
        .catch(error => this.queryError = "Error: " + error.toString());
    }

  query() {
    this.queryError = '';
    this.queryInProgress = true;
    Promise.all([
        this.backend.SummaryReportQueryHandler_handle({
            yearMonth: this.selectedReportingPeriod,
            showInactiveArticles: this.showInactiveArticles
          })
          .then(result => this.report = result),
        this.backend.StoragePrArticleQueryHandler_handle({
            yearMonth: this.selectedReportingPeriod
          })
          .then(result => this.report2 = result),
      ])
      .catch(error => this.queryError = "Error: " + error.toString())
      .then(() => this.queryInProgress = false);
  }

  queryExcel() {
      this.queryError = '';
      this.queryInProgress = true;
      this.backend.SummaryReportQueryHandler_handleExcel({
          yearMonth: this.selectedReportingPeriod
        })
        .then(result => download(result.fileBase64, result.filename))
        .catch(error => this.queryError = "Error: " + error.toString())
        .then(() => this.queryInProgress = false)
      ;
  }

  dismissSaveError() {
    this.saveError = null;
  }
}
