import Backend from 'backend';
import {inject} from 'aurelia-framework';
import {ValidationController, ValidationRules} from 'aurelia-validation';
import {activationStrategy, Router} from 'aurelia-router';
import numeral from "numeral";

@inject(Backend, Router, ValidationController)
export class ExciseDutyReportPreviewScreen {
  backend;
  router;
  validationController;

  period;
  comment;
  y2dInOverride;

  initialQueryInProgress;
  queryError;

  constructor(backend, router, validationController) {
    this.backend = backend;
    this.router = router;
    this.validationController = validationController;
  }

  activate(params) {
    this.period = params.period;
    this.comment = params.comment;
    this.y2dInOverride = params.y2dInOverride;
    this.overrideY2dIn = this.y2dInOverride !== undefined;
    this.refresh();
  }

  bind() {
    ValidationRules
      .ensure('y2dInOverride')
        .displayName('Overstyr IB')
        .required()
        .then()
        .satisfies(value => this.toNumeral(value) !== null) // numeral.value() returns null if value is not a number. Allows negative and comma.
        .withMessage('Overstyr IB må være et et tall')
      .on(this);
  }

  refresh() {
    this.initialQueryInProgress = true;
    return Promise.all([
        this.backend.ExciseDutyReportPreviewQueryHandler_handle({
            period: this.period,
            comment: this.comment,
            y2dInOverride: this.toNumeral(this.y2dInOverride)
          })
          .then(result => this.report = result),
      ])
      .catch(error => this.queryError = "Error: " + error.toString())
      .then(result => this.initialQueryInProgress = false);
  }

  dismissQueryError() {
    this.queryError = null;
  }

  updateY2dOverride() {
    return this.validationController.validate()
      .then(result => {
        if (!result.valid) {
          return;
        }
        this.router.navigateToRoute('excise-duty-report-preview', {period: this.period, comment: this.comment, y2dInOverride: this.y2dInOverride})
      });
  }

  generateAndSave() {
    return this.validationController.validate()
      .then(result => {
        if (!result.valid) {
          return;
        }
        this.queryError = ''
        return this.backend.GenerateExciseDutyReportCommandHandler_handle({
            period: this.period,
            comment: this.comment,
            y2dInOverride: this.toNumeral(this.y2dInOverride)
          })
          .then(exciseReportId => this.router.navigateToRoute("excise-duty-report-details", {exciseReportId: exciseReportId}))
          .catch(error => this.queryError = "Error: " + error.toString())
          ;
      });
  }

  toNumeral(value) {
    if (value === undefined || value === null)
      return value;
    return numeral(value.replace('.', ',')).value();
  }

  // Define what to do when links points to this same page, but with different params.
  determineActivationStrategy() {
    return activationStrategy.replace; //replace the viewmodel with a new instance
    // or activationStrategy.invokeLifecycle to invoke router lifecycle methods on the existing VM
    // or activationStrategy.noChange to explicitly use the default behavior
  }
}
