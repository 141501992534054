import {inject} from 'aurelia-framework';

import {IdentityProvider} from 'devtag-aurelia-auth-plugin';
import Backend from "../backend";
import {BindingSignaler} from "aurelia-templating-resources";

@inject(IdentityProvider, Backend, BindingSignaler)
export class FeatureCustomizationProvider {
  identityProvider;
  backend;
  bindingSignaler;

  info;

  constructor(identityProvider, backend, bindingSignaler) {
    this.identityProvider = identityProvider;
  }

  showProblemListRoute() {
    return this.identityProvider.claims.vmpEnableIntegration;
  }

  showVmpArticleNumber() {
    return this.identityProvider.claims.vmpEnableIntegration;
  }

  showVatCode() {
    return this.identityProvider.claims.vmpEnableIntegration;
  }

  showVmpStoreNumber() {
    return this.identityProvider.claims.vmpEnableIntegration;
  }

  useSimpleOrderFlow() {
    return !this.identityProvider.claims.vmpEnableIntegration;
  }

  showBreweryBankAccountNumber() {
    return this.identityProvider.claims.vmpEnableIntegration;
  }

  showPrices() {
    return this.identityProvider.claims.ehfEnableIntegration || this.identityProvider.claims.pdfEnableIntegration;
  }

  showDefaultCustomersRef() {
    return this.identityProvider.claims.ehfEnableIntegration || this.identityProvider.claims.pdfEnableIntegration;
  }

  showProductCategories() {
    return true;
  }

  showPlainInvoiceExportTab() {
    // Don't show for simple order flow
    if (this.useSimpleOrderFlow()) {
      return false;
    }
    return this.identityProvider.claims.enablePlainInvoiceExportGui === true;
  }

  showTransferTab() {
    // Don't show for simple order flow
    if (this.useSimpleOrderFlow()) {
      return false;
    }
    return this.identityProvider.claims.enableTransfers === true;
  }
}
