import Backend from 'backend';
import {inject, observable} from 'aurelia-framework';
import moment from 'moment';
import {DateTime} from "luxon";

@inject(Backend)
export class InventoryScreen {
  backend;

  // For dropdowns
  storageAreas;

  queryInProgress;
  queryError;

  inventoryDateInclusive = moment().format('YYYY-MM-DD');
  selectedStorageAreaId;
  includeArticlesWithEmptyInventory = false;

  storageAreaInventories;

  constructor(backend) {
    this.threeMonthsFromNow = DateTime.local().plus({months: 3}).toISO();
    this.backend = backend;
  }

  activate() {
    return this.refresh();
  }

  refresh() {
    return Promise.all([
        this.backend.StorageAreasForDropdownQueryHandler_handle()
          .then(result => {
            this.storageAreas = result.storageAreas;
            this.storageAreas.unshift({id: null, name: 'Vis alle lagre'})
          }),
      ])
      .catch(error => this.queryError = "Error: " + error.toString())
  }

  query() {
    this.queryInProgress = true;
    this.queryError = '';
    return this.backend.InventoryReportQueryHandler_handle({
        storageAreaId: this.selectedStorageAreaId,
        date: this.inventoryDateInclusive,
        includeArticlesWithEmptyInventory: this.includeArticlesWithEmptyInventory
      })
      .then(result => this.storageAreaInventories = result.storageAreas)
      .catch(error => this.queryError = error.message || 'Ukjent feil')
      .then(result => this.queryInProgress = false);
  }

  isInPast(date) {
    return new Date(date) < new Date();
  }

  isSoon(date) {
    return new Date(date) < new Date(this.threeMonthsFromNow);
  }

  dismissSaveError() {
    this.queryError = null;
  }
}
