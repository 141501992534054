import Backend from 'backend';
import {inject, observable} from 'aurelia-framework';
import {FeatureCustomizationProvider} from "../util/feature-customization-provider";
import {Router} from "aurelia-router";

@inject(Backend, Router, FeatureCustomizationProvider)
export class ArticleIndexScreen {
    backend;
    router;
    featureCustomizationProvider;
    articles = [];

    @observable({changeHandler: 'changeShowInactiveArticles'})
    showInactiveArticles;

    constructor(backend, router, featureCustomizationProvider) {
        this.backend = backend;
        this.router = router;
        this.featureCustomizationProvider = featureCustomizationProvider;
    }

    activate(params) {
        this.showInactiveArticles = params.showInactiveArticles === true || params.showInactiveArticles === 'true';
        return this.refresh();
    }

    refresh() {
        return this.backend.ArticleCrud_index(this.showInactiveArticles)
            .then(result => this.articles = result);
    }

    changeShowInactiveArticles(newValue, oldValue) {
        // Don't do anything when value is initially bound to checkbox
        if (oldValue === undefined) {
          return;
        }

        // Navigate to same route, but with value in params, to remember state across page reload
        let newUrl = this.router.generate('article-index', {showInactiveArticles: newValue});
        window.location.href = newUrl;

        // Force reload because we can't change the data underlying the dt-datatables attribute (this.customer) without tripping up the binding engine.
        window.location.reload();
    }
}
