import Backend from 'backend';
import {customElement, bindable, bindingMode, observable} from 'aurelia-framework';
import {inject} from 'aurelia-framework';

@customElement('proof-of-delivery-component')
@inject(Backend, Element)
export class ProofOfDeliveryComponent {
  backend;
  element;

  @bindable({changeHandler: 'proofOfDeliveryIdChanged'})
  proofOfDeliveryId;

  proofOfDelivery;

  constructor(backend, element) {
    this.backend = backend;
    this.element = element;
  }

  proofOfDeliveryIdChanged() {
      this.proofOfDelivery = null;
      this.backend.ProofOfDeliveryDetailsQueryHandler_handle({proofOfDeliveryId: this.proofOfDeliveryId})
        .then(result => {
          this.proofOfDelivery = result.proofOfDelivery;
        });
  }
}
