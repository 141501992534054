import Backend from 'backend';
import {inject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {ValidationRules, ValidationController} from 'aurelia-validation';
import {RangeOverrideValueConverter} from 'util/range-override-value-converter';
import numeral from "numeral";

@inject(Backend, Router, ValidationController)
export class BatchEditScreen {
  backend;
  router;
  validationController;

  // For dropdowns
  products;

  constructor(backend, router, validationController) {
    this.backend = backend;
    this.router = router;
    this.validationController = validationController;
  }

  activate(params) {
    return Promise.all([
        this.backend.BatchCrud_getForEdit(params.batchId)
          .then(result => {
            this.editbatch = result.batchEditDto;
            this.canEditProductId = result.canEditProductId;
            this.canEditRangeOverride = result.canEditRangeOverride;
          }),
        this.backend.ProductsForDropdownQueryHandler_handle()
          .then(result => this.products = result.products),
        this.backend.RangeOverridesForDropdownQueryHandler_handle()
          .then(result => {
            this.rangeOverrides = result.rangeOverrides;
            this.rangeOverrides.unshift({id: null, name: 'NONE'});
          }),
      ])
      .catch(error => this.queryError = "Error: " + error.toString())
  }

  bind() {
    ValidationRules
      .ensure('batchNumber')
      .displayName('Batchnr.')
      .required()
      .then()
      .satisfies(value => Number.isInteger(Number(value))) // Number() converts string to number. Otherwise "1" is not an integer.
      .withMessage('Batchnr. må være et heltall')
      .on(this.editbatch);
  }

  submit() {
    return this.validationController.validate()
      .then(result => {
        if (!result.valid) {
          return;
        }
        return this.backend.BatchCrud_edit(this.editbatch.batchId, this.editbatch)
          .then(() => this.router.navigateToRoute("batch-details", {batchId: this.editbatch.batchId}))
          .catch(error => this.saveError = error);
      });
  }

  dismissSaveError() {
    this.saveError = null;
  }

  translateRangeOverride(value) {
    return RangeOverrideValueConverter.toView(value);
  }
}
