import Backend from 'backend';
import {inject} from 'aurelia-framework';
import {Router} from 'aurelia-router';

@inject(Backend, Router)
export class StorageAreaEditScreen {
    backend;
    router;

    constructor(backend, router) {
        this.backend = backend;
        this.router = router;
    }

    activate(params) {
        return this.backend.StorageAreaCrud_getForEdit(params.storageAreaId)
            .then(result => {
                this.editstorageArea = result.storageAreaEditDto;
                this.canEditApprovalFlag = result.canEditApprovalFlag;
            });
    }

    submit() {
        this.saveInProgress = true;
        this.backend.StorageAreaCrud_edit(this.editstorageArea.storageAreaId, this.editstorageArea)
            .then(() => this.router.navigateToRoute("storage-area-details", {storageAreaId: this.editstorageArea.storageAreaId}))
            .catch(error => this.saveError = error)
            .then(() => this.saveInProgress = false);
    }

    dismissSaveError() {
        this.saveError = null;
    }
}
