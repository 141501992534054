import Backend from 'backend';
import {inject, observable} from 'aurelia-framework';
import {Router} from "aurelia-router";

@inject(Backend, Router)
export class StorageAreaIndexScreen {
    backend;
    router;
    storageAreas = [];

    @observable({changeHandler: 'changeShowInactiveStorageAreas'})
    showInactiveStorageAreas;

    constructor(backend, router) {
        this.backend = backend;
        this.router = router;
    }

    activate(params) {
        this.showInactiveStorageAreas = params.showInactiveStorageAreas === true || params.showInactiveStorageAreas === 'true';
        return this.refresh();
    }

    refresh() {
        return this.backend.StorageAreaCrud_index(this.showInactiveStorageAreas)
            .then(result => this.storageAreas = result);
    }

    changeShowInactiveStorageAreas(newValue, oldValue) {
        // Don't do anything when value is initially bound to checkbox
        if (oldValue === undefined) {
          return;
        }

        // Navigate to same route, but with value in params, to remember state across page reload
        let newUrl = this.router.generate('storage-area-index', {showInactiveStorageAreas: newValue});
        window.location.href = newUrl;

        // Force reload because we can't change the data underlying the dt-datatables attribute (this.customer) without tripping up the binding engine.
        window.location.reload();
    }
}
