import Backend from 'backend';
import {inject} from 'aurelia-framework';
import {BackendCache} from "../util/backend-cache";
import {OrderTypeValueConverter} from "../util/order-type-value-converter";

@inject(Backend, BackendCache)
export class CsvRegisterSaleScreen {
  backend;
  backendCache;

  showSuccess;
  items;
  selectedFiles;
  selectedFilesValueForClearing;

  selectedDateFormatPattern;
  dateFormatPatterns = [
    { id: "d.M.yyyy", name: 'Norsk: dd.mm.åååå' },
    { id: "d/M/yyyy", name: 'Norsk: dd/mm/åååå' },
    { id: "M/d/yyyy", name: 'Amerikansk: mm/dd/åååå' },
    { id: 'yyyy-MM-dd', name: 'ISO 8601: åååå-MM-dd' },
  ]

  constructor(backend, backendCache) {
    this.backend = backend;
    this.backendCache = backendCache;
  }

  activate() {
    return this.refresh();
  }

  refresh() {
    return Promise.all([
      this.backend.ProductsForDropdownQueryHandler_handle()
        .then(result => {
          this.products = result.products;
          this.products.unshift({id: null, name: 'Velg'})
        }),
      this.backend.OrderTypesForDropdownQueryHandler_handle()
        .then(result => {
          result.orderTypes.unshift({id: null, name: ''})
          return this.orderTypes = result.orderTypes;
        }),
    ])
      .catch(error => this.queryError = "Error: " + error.toString());
  }

  doUpload() {
    this.items = [];
    this.showSuccess = false;
    return this.backend.CsvParserQueryHandler_handle({
        csv: this.selectedFiles[0],
        dateFormatPattern: this.selectedDateFormatPattern
      })
      .then(result => {
        this.items = result.items;
        this.items.forEach(item => item.hasErrors = !item.registerSaleTemplate.date ||
                                              !item.registerSaleTemplate.storageAreaId ||
                                              !item.registerSaleTemplate.customerId ||
                                              !item.registerSaleTemplate.invoiceNumber ||
                                              !item.registerSaleTemplate.articleId ||
                                              !item.registerSaleTemplate.batchId ||
                                              !item.registerSaleTemplate.quantity);
        this.anyItemsHasErrors = this.items.filter(item => item.hasErrors).length > 0;
      })
      .then(() => this.selectedFilesValueForClearing = null);
  }

  submitMovementRegistrations() {
    // Validate ordertype set
    let itemsWithMissingOrderType = this.items
      .filter(item => !item.ignore)
      .filter(item => !item.registerSaleTemplate.orderType)
      .map(item => new Error(`Row nr: ${item.parsedCsvLine.lineNumber}. OrderType must be set.`));
    if (itemsWithMissingOrderType.length > 0) {
      return Promise.reject(itemsWithMissingOrderType[0]);
    }

    let command = {
      saleItems: this.items.filter(item => !item.ignore).filter(item => item.registerSaleTemplate.orderType === 'SALE').map(item => {return {
          date: item.registerSaleTemplate.date,
          customerId: item.registerSaleTemplate.customerId,
          storageAreaId: item.registerSaleTemplate.storageAreaId,
          lines: [{
            rowNumber: item.parsedCsvLine.lineNumber,
            articleId: item.registerSaleTemplate.articleId,
            batchId: item.registerSaleTemplate.batchId,
            quantity: item.registerSaleTemplate.quantity,
            comment: item.registerSaleTemplate.comment,
          }],
          saleType: item.registerSaleTemplate.saleType,
          invoiceNumber: item.registerSaleTemplate.invoiceNumber
        }
      }),
      returnItems: this.items.filter(item => !item.ignore).filter(item => item.registerSaleTemplate.orderType === 'RETURNED_GOODS').map(item => {return {
          date: item.registerSaleTemplate.date,
          customerId: item.registerSaleTemplate.customerId,
          storageAreaId: item.registerSaleTemplate.storageAreaId,
          isDutyFree: item.registerSaleTemplate.isDutyFree,
          correspondingSalePeriod: item.registerSaleTemplate.correspondingSalePeriod,
          lines: [{
            rowNumber: item.parsedCsvLine.lineNumber,
            articleId: item.registerSaleTemplate.articleId,
            batchId: item.registerSaleTemplate.batchId,
            quantity: item.registerSaleTemplate.quantity,
            comment: item.registerSaleTemplate.comment,
          }],
          invoiceNumber: item.registerSaleTemplate.invoiceNumber
        }
      })
    };

    return this.backend.RegisterSaleCommandHandler_saleMultiple(command)
      .then(() => {
        this.items = null;
        this.selectedFiles = null;
        this.showSuccess = true;
      });
  }

  translateOrderType(value) {
    return OrderTypeValueConverter.toView(value);
  }
}
