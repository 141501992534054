import Backend from 'backend';
import {inject} from 'aurelia-framework';

@inject(Backend)
export class InventoryOffsetIndexScreen {
    backend;
    years;

    constructor(backend) {
        this.backend = backend;
    }

    activate() {
        return this.refresh();
    }

    refresh() {
        return this.backend.InventoryOffsetYearsQueryHandler_handle()
            .then(result => this.years = result.years);
    }
}