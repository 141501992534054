import Backend from 'backend';
import {inject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import moment from "moment";
import {FeatureCustomizationProvider} from "../util/feature-customization-provider";

@inject(Backend, Router, FeatureCustomizationProvider)
export class OrderDetailsScreen {
    backend;
    router;

    order;

    moveGoodsError;
    moveGoodsInProgress;

    cancelOrderError;
    cancelOrderInProgress;

    // view model refs
    moveGoodsModal;
    cancelOrderModal;
    orderDocumentsComponent;

    movementDateInput = moment().format('YYYY-MM-DD');
    orderId;

    initialActiveDocumentId;

    forceResendOrderConfirmation;
    featureCustomizationProvider;

    autoSendOrderConfirmation;

    constructor(backend, router, featureCustomizationProvider) {
        this.backend = backend;
        this.router = router;
        this.featureCustomizationProvider = featureCustomizationProvider;
    }

    activate(params) {
        this.orderId = params.orderId;
        this.backUrl = params.backUrl;

        // Alternative id for query?
        this.orderDocumentId =
          params.orderConfirmationId ??
          params.deliveryNoteId ??
          params.movementGroupId ??
          params.proofOfDeliveryId ??
          params.invoiceId ??
          null;
        this.orderDocumentIdType =
              this.orderDocumentId === null                       ? null
            : this.orderDocumentId === params.orderConfirmationId ? 'ORDER_CONFIRMATION_ID'
            : this.orderDocumentId === params.deliveryNoteId      ? 'DELIVERY_NOTE_ID'
            : this.orderDocumentId === params.movementGroupId     ? 'MOVEMENT_GROUP_ID'
            : this.orderDocumentId === params.proofOfDeliveryId   ? 'PROOF_OF_DELIVERY_ID'
            : this.orderDocumentId === params.invoiceId           ? 'INVOICE_ID'
            : null;
        this.initialActiveDocumentId = this.orderDocumentId;
        return this.refresh();
    }

    refresh() {
        return this.backend.OrderDetailsQueryHandler_handle({
          orderId: this.orderId,
          orderDocumentIdType: this.orderDocumentIdType,
          orderDocumentId: this.orderDocumentId,
        })
                           .then(result => {
                               // Store result
                               this.order = result.order;
                               // In case we were querying based on one of the other document ids we need the orderId for other queries.
                               this.orderId = result.order.orderId;

                               this.orderPolicy = result.orderPolicy;
                               this.sentConfirmationOrWarning = this.order.orderConfirmationEmailSent ? 'Ordrebekreftelse har allerede blitt sendt' : null;

                               // Default checkbox to true if we're allowed to create an order confirmation
                               this.autoSendOrderConfirmation = this.orderPolicy.allowNewOrderConfirmation;
                           });
    }

    orderStepComponent_orderStateChanged(activeDocumentDto) {
        // Refresh order
        this.refresh()
            // Refresh documents
            .then(() => this.orderDocumentsComponent.refresh())
            // Select new document if present
            .then(() => {
              this.orderDocumentsComponent.showDocument(activeDocumentDto)
            });
    }

    orderDocumentsComponentRefreshed() {
        this.refresh();
    }

    moveGoods() {
        this.moveGoodsError = '';
        this.moveGoodsInProgress = true;
        this.backend.MoveGoodsFromStorageForOrderCommandHandler_handle({orderId: this.orderId, movementDate: this.movementDateInput})
              .then(() => this.refresh())
              .then(() => this.closeMoveGoodsModal())
              .catch(error => this.moveGoodsError = error)
              .then(() => this.moveGoodsInProgress = false);
    }

    dismissMoveGoodsError() {
        this.moveGoodsError = null;
    }

    openMoveGoodsModal() {
        this.moveGoodsModal.open();
    }

    closeMoveGoodsModal() {
        this.moveGoodsModal.close();
    }

    openCancelOrderModal() {
        this.cancelOrderModal.open();
    }

    closeCancelOrderModal() {
        this.cancelOrderModal.close();
    }

    cancelOrder(autoSendOrderConfirmation) {
      return this.backend.CancelOrderCommandHandler_handle({
            orderId: this.orderId,
            autoSendOrderConfirmation: autoSendOrderConfirmation
          })
          .then(() => this.refresh())
          .then(() => this.orderDocumentsComponent.refresh())
          .then(() => this.closeCancelOrderModal());
    }


    sendOrderConfirmationEmail() {
        this.sendOrderConfirmationError = '';
        this.sendOrderConfirmationInProgress = true;
        this.backend.SendOrderConfirmationCommandHandler_handle({
            orderId: this.orderId,
            force: this.forceResendOrderConfirmation
            })
          .then(() => this.refresh())
          .then(() => this.sentConfirmationOrWarning = 'Ordrebekreftelse er nå sendt')
          .catch(error => this.sendOrderConfirmationError = error)
          .then(() => this.forceResendOrderConfirmation = false)
          .then(() => this.sendOrderConfirmationInProgress = false);
    }

    dismissCancelOrderError() {
        this.cancelOrderError = null;
    }

    dismissSendOrderConfirmationError() {
        this.sendOrderConfirmationError = null;
    }

    getCurrentUrl() {
        return this.router.getBackUrl();
    }
}
