import Backend from 'backend';
import {inject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {CustomerTypeValueConverter} from 'util/customer-type-value-converter';
import {FeatureCustomizationProvider} from "../util/feature-customization-provider";
import {ValidationController, ValidationRules} from "aurelia-validation";
import numeral from "numeral";
import {BrregLookup} from "../brreg-lookup";

@inject(Backend, Router, FeatureCustomizationProvider, ValidationController, BrregLookup)
export class CustomerCreateScreen {
    backend;
    router;
    featureCustomizationProvider;
    // For dropdowns
    customerTypes;
    customerAdditionalCodes;
    countries;

    // To be posted
    customer = {
      countryCode: 'NO'
    };

    postError;
    validationController;
    brregLookup;

    constructor(backendFacade, router, featureCustomizationProvider, validationController, brregLookup) {
        this.backend = backendFacade;
        this.router = router;
        this.featureCustomizationProvider = featureCustomizationProvider;
        this.validationController = validationController;
        this.brregLookup = brregLookup;
        this.refresh();
    }

    bind() {
      ValidationRules
            .ensure('name')
              .required()
            .ensure('discountRate')
              .displayName('Rabatt')
              .satisfies(value => !value || numeral.validate(value, 'en'))
              .withMessage('Rabatt må være et tall')
            .on(this.customer);
    }

    refresh() {
        return Promise.all([
              this.backend.DropdownItemQueryHandler_handle('Countries')
                .then(result => this.countries = result.items),
              this.backend.CustomerTypesForDropdownQueryHandler_handle()
                .then(result => this.customerTypes = result.customerTypes),
              this.backend.CustomerAdditionalCodesForDropdownQueryHandler_handle()
                .then(result => this.customerAdditionalCodes = result.customerAdditionalCodes),
          ])
          .catch(error => this.queryError = "Error: " + error.toString())
    }

    lookUp(organizationNumber) {
      return this.brregLookup.brreg(organizationNumber)
                            .then(result => {
                              this.customer.name = result.name;
                              this.customer.organizationNumber = result.organizationNumber;
                              this.customer.streetAddress = result.streetAddress;
                              this.customer.postalCode = result.postalCode;
                              this.customer.city = result.city;
                              this.customer.countryCode = result.countryCode;
                            });
    }
    createSubmit() {
      return this.validationController.validate()
        .then(result => {
          if (!result.valid) {
            return;
          }

          this.backend.CustomerCrud_create(this.customer)
            .then(() => this.router.navigateToRoute('customer-index'));
        });
    }

    translateCustomerType(value) {
        return CustomerTypeValueConverter.toView(value);
    }
}
