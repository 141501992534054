import Backend from 'backend';
import {customElement, bindable, bindingMode, observable} from 'aurelia-framework';
import {inject} from 'aurelia-framework';
import {OrderStepValueConverter} from "../util/order-step-value-converter";

@customElement('mark-as-invoiced-component')
@inject(Backend, Element)
export class MarkAsInvoicedComponent {
  backend;
  element;

  @bindable() order;
  @bindable() buttonText;
  @bindable() tiny;

  invoiceNumberInput;

  constructor(backend, element) {
    this.backend = backend;
    this.element = element;
  }

  submitInvoicingForm() {
    return this.backend.InvoiceOrderCommandHandler_handle({
      orderId: this.order.orderId,
      invoiceNumber: this.invoiceNumberInput
    })
      .then(() => this.raiseEvent_orderStateChanged())
      .then(() => this.invoicingModal.close());
  }

  raiseEvent_orderStateChanged() {
    this.element.dispatchEvent(new CustomEvent('order-state-changed', {
      detail: {},
      bubbles: true
    }));

    // Clear form for reuse
    this.invoiceNumberInput = null;
  }
}
