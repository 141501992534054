import Backend from 'backend';
import {inject} from 'aurelia-framework';
import {Router} from 'aurelia-router';

@inject(Backend, Router)
export class OrderPackagingSlipScreen {
    backend;
    router;

    order;
    heading;
    showSignatureFields;

    showDeleteConfirm = false;
    deleteError;

    constructor(backend, router) {
        this.backend = backend;
        this.router = router;
    }

    activate(params) {
        this.heading = params.heading;
        this.backUrl = params.backUrl;
        this.showSignatureFields = params.showSignatureFields;
        return this.backend.PackagingSlipQueryHandler_handle({orderId: params.orderId, deliveryNoteId: params.deliveryNoteId})
                            .then(result => this.order = result);
    }

    get anyLineHasCrateCountRemainder() {
        return this.order.lines.some(line => line.crateCountRemainder > 0);
    }

    get anyLineHasCrateCount() {
        return this.order.lines.some(line => line.crateCount > 0);
    }

    showPrintDialog() {
        window.print();
    }
}
