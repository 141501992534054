import Backend from 'backend';
import {customElement, bindable, bindingMode, observable} from 'aurelia-framework';
import {inject} from 'aurelia-framework';
import {FeatureCustomizationProvider} from "../util/feature-customization-provider";
import {Router} from "aurelia-router";
import {EventAggregator} from "aurelia-event-aggregator";

@customElement('problem-list-component')
@inject(Backend, Element, FeatureCustomizationProvider, Router, EventAggregator)
export class ProblemListComponent {
  backend;
  element;
  featureCustomizationProvider;
  router;
  eventAggregator;

  @bindable()
  referenceIdType;
  @bindable({referenceIdChanged: 'refresh'})
  referenceId;

  @bindable()
  useAlertColors;

  @bindable()
  hideWhenEmpty = false;

  @bindable()
  inhibitQueryWithoutReferenceId = true;

  newProblems;
  doneProblems;

  constructor(backend, element, featureCustomizationProvider, router, eventAggregator) {
    this.router = router;
    this.backend = backend;
    this.element = element;
    this.featureCustomizationProvider = featureCustomizationProvider;
    this.eventAggregator = eventAggregator;
  }

  bind() {
    this.refresh();
  }

  referenceIdChanged(newValue, oldValue) {
    if (newValue !== oldValue) {
      this.refresh();
    }
  }

  refresh() {
    // Some usages of this component might want to make sure we don't first query with empty referenceId before binding is ready.
    // If we query with null params we get all problems in result.
    if (this.inhibitQueryWithoutReferenceId && !this.referenceId) {
      return;
    }

    return this.backend.ProblemListQueryHandler_handle({
      referenceIdType: this.referenceIdType,
      referenceId: this.referenceId,
    })
      .then(result => {
        this.newProblems = result.newExplicitActionableItems;
        this.doneProblems = result.doneExplicitActionableItems;
      });
  }

  retry(problem) {
    return this.backend.RetryExplicitActionableItemCommandHandler_handle({explicitActionableItemId: problem.explicitActionableItemId})
      .then(result => this.refresh())
      .then(() => this.eventAggregator.publish('ProblemCountChanged', {}));
  }

  close(problem) {
    return this.backend.CloseExplicitlyActionableItemCommandHandler_handle({explicitActionableItemId: problem.explicitActionableItemId})
      .then(result => this.refresh())
      .then(() => this.eventAggregator.publish('ProblemCountChanged', {}))
      .then(() => {
        this.element.dispatchEvent(new CustomEvent('problem-count-changed', {
          detail: {},
          bubbles: true
        }));
      });
  }
}
