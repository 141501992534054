export function download(base64File, filename) {
  const arrayBuffer = base64ToArrayBuffer(base64File);
  createAndDownloadBlobFile(arrayBuffer, filename);
}

function createAndDownloadBlobFile(body, filename) {
  const blob = new Blob([body]);
  const fileName = filename;
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, fileName);
  } else {
    const link = document.createElement('a');
    // Browsers that support HTML5 download attribute
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', fileName);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

function base64ToArrayBuffer(base64) {
  const binaryString = window.atob(base64); // Comment this if not using base64
  const bytes = new Uint8Array(binaryString.length);
  return bytes.map((byte, i) => binaryString.charCodeAt(i));
}