import Backend from 'backend';
import {inject} from 'aurelia-framework';

@inject(Backend)
export class YearlyLiquorLicenseReportScreen {
    backend;

    // For dropdowns
    reportingPeriodYears;

    // Query
    selectedReportingPeriodYear;

    // Query result
    result;

    constructor(backend) {
        this.backend = backend;
    }

    activate() {
        return this.refresh();
    }

    refresh() {
        Promise.all([
              this.backend.ReportingPeriodYearsForDropdownQueryHandler_handle()
                .then(result => this.reportingPeriodYears = result.reportingPeriodYears),
          ])
          .catch(error => this.queryError = "Error: " + error.toString());
    }

    query() {
        return this.backend.YearlyLiquorLicenseReportQueryHandler_handle({
                           year: this.selectedReportingPeriodYear
                    })
                    .then(result => this.result = result);
    }

  dismissSaveError() {
    this.saveError = null;
  }
}