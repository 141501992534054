//import {computedFrom} from 'aurelia-framework';

import {inject} from "aurelia-framework";
import {FeatureCustomizationProvider} from "../util/feature-customization-provider";

@inject(FeatureCustomizationProvider)
export class ReportingMenuScreen {
  featureCustomizationProvider;

  constructor(featureCustomizationProvider) {
    this.featureCustomizationProvider = featureCustomizationProvider;
  }
}
