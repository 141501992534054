import Backend from 'backend';
import {bindable, computedFrom, bindingMode, customElement, inject, observable} from 'aurelia-framework';
import {ValidationRules, ValidationController} from "aurelia-validation";
import numeral from "numeral";
import {BackendCache} from "../util/backend-cache";

@customElement('line-component')
@inject(Backend, Element, ValidationController, BackendCache)
export class LineComponent {
  backend;
  element;

  @bindable({ defaultBindingMode: bindingMode.twoWay })
  line;

  @bindable({changeHandler: 'storageAreaIdSelected'})
  storageAreaId;

  @bindable()
  showInventory;

  @bindable()
  showComment;

  @bindable()
  forceNativeSelects = false;

  @bindable()
  leaveRoomForButton = true;

  // For dropdowns
  @bindable()
  products;
  articles;
  batches;

  @observable({changeHandler: 'productIdSelected'})
  selectedProductId;

  @observable({changeHandler: 'articleIdSelected'})
  selectedArticleId;

  @observable({changeHandler: 'batchIdSelected'})
  selectedBatchId;

  @bindable({changeHandler: 'quantityCrateChanged'}) quantityCrate;
  @bindable({changeHandler: 'quantityLooseChanged'}) quantityLoose;

  validationController;

  showBatch = true;

  constructor(backend, element, validationController, backendCache) {
    this.backend = backend;
    this.element = element;
    this.validationController = validationController;
    this.backendCache = backendCache;
  }

  attached() {
    this.selectedProductId = this.line.productId;
    this.selectedBatchId = this.line.batchId;
    this.selectedArticleId = this.line.articleId;
  }

  bind() {
    ValidationRules
      .ensure('quantityCrate')
        .displayName('Antall kasser')
        .satisfies(value => value == null || (Number.isInteger(Number(value)) && value >= 0)) // Number() converts string to number. Otherwise "1" is not an integer.
        .withMessage('Antall kasser må være et positivt heltall')
      .ensure('quantityLoose')
        .displayName('Antall løse')
        .satisfies(value => value == null || (Number.isInteger(Number(value)) && value >= 0)) // Number() converts string to number. Otherwise "1" is not an integer.
        .withMessage('Antall løse må være et positivt heltall')
      .on(this);
  }

  storageAreaIdSelected() {
    // Reload inventory: different storage area means different inventory count.
    this.loadArticlesWithInventory()
      .then(() => this.loadBatchesWithInventory());
  }

  productIdSelected(selectedProductId) {
    this.line.productId = selectedProductId;

    return this.loadArticlesWithInventory()
      .then(() => this.loadBatchesWithInventory());
  }

  articleIdSelected(selectedArticleId) {
    this.line.articleId = selectedArticleId;

    // Show/hide batch
    this.updateBatchTrackingMode();

    // The new article may have different quantityPrCrates which will affect the calculation
    this.calculateQuantities();

    // Reload inventory: different article means different batches means different inventory count.
    this.loadBatchesWithInventory();
  }

  updateBatchTrackingMode() {
    this.showBatch = this.isSelectedArticleBatchTracking();
    if (this.showBatch === false) {
      // Make sure we don't post the batchId that was chosen before the user switched to an article without batch tracking.
      this.line.batchId = null;
    }
  }

  isSelectedArticleBatchTracking() {
    // Articles not loaded yet?
    if (!this.articles) {
      return null;
    }

    // No article selected yet?
    if (!this.selectedArticleId) {
      return null;
    }

    // Find article
    let article = this.articles.filter(a => a.id === this.selectedArticleId)[0];

    // No article found?
    // Could be that product just changed and the selected article id has not updated yet.
    if (!article) {
      return null;
    }

    return article.batchTracking === true;
  }

  articleSaved(event) {
    this.addArticleModal.close();
    this.loadArticlesWithInventory()
      .then(() => {
        this.selectedArticleId = event.detail.articleId
      });
  }

  batchIdSelected(selectedBatchId) {
    this.line.batchId = selectedBatchId;
  }

  loadArticlesWithInventory() {
    // With or without inventory numbers?
    // Also: can only show inventory if productId and storageArea is selected
    if ((this.showInventory === true || this.showInventory === 'true') && this.selectedProductId && this.storageAreaId) {
      // WITH inventory numbers

      // if (!this.selectedProductId || !this.storageAreaId) {
      //   this.articles = [{id: null, name: 'Velg først lager og produkt'}];
      //   return Promise.resolve();
      // }

      // Load articles WITH inventory
      return this.backendCache.ProductArticlesWithInventoryForDropdownQueryHandler_handle({
          productId: this.selectedProductId,
          storageAreaId: this.storageAreaId,
        })
        .then(result => {
          this.articles = result.articles.slice();
          this.articles.unshift({id: null, name: 'Velg'})

          // Show/hide batch
          this.updateBatchTrackingMode();
        })
        .then(result => this.reverseCalculateQuantitiesAfterLoadingExisting());

    } else {
      // WITHOUT inventory numbers

      // If no product selection: empty article dropdown
      if (!this.selectedProductId) {
        this.articles = [{id: null, name: 'Velg først produkt'}];
        return Promise.resolve();
      }

      // Load articles WITHOUT inventory
      return this.backendCache.ProductArticlesForDropdownQueryHandler_handle({productId: this.selectedProductId})
        .then(result => {
          this.articles = result.articles.slice()
          this.articles.unshift({id: null, name: 'Velg'})

          // Show/hide batch
          this.updateBatchTrackingMode();
        })
        .then(result => this.reverseCalculateQuantitiesAfterLoadingExisting());
    }
  }

  loadBatchesWithInventory() {
    this.batches = [];
    if (!this.selectedProductId) {
      console.info("loadBatchesWithInventory() - selectedProductId not ready");
      this.batchesNotReadyDropdownErrorMessage = [{id: null, name: 'Velg produkt først'}];
      return
    }

    if (this.showInventory === true || this.showInventory === 'true') {
      if (!this.storageAreaId) {
        console.info("loadBatchesWithInventory() - storageAreaId not ready");
        this.batchesNotReadyDropdownErrorMessage = [{id: null, name: 'Velg lager først'}];
        return;
      }
      if (!this.selectedArticleId) {
        console.info("loadBatchesWithInventory() - selectedArticleId not ready");
        this.batchesNotReadyDropdownErrorMessage = [{id: null, name: 'Velg artikkel først'}];
        return
      }
      this.backendCache.BatchesWithInventoryForDropdownQueryHandler_handle({
          productId: this.selectedProductId,
          articleId: this.selectedArticleId,
          storageAreaId: this.storageAreaId,
        })
        .then(result => {
          this.batches = result.batches.slice();
          this.batches.unshift({id: null, name: 'Velg'})
          this.batchesNotReadyDropdownErrorMessage = null;
        });
    } else {
      this.backendCache.BatchesForDropdownQueryHandler_handle({productId: this.selectedProductId})
        .then(result => {
          this.batches = result.batches.slice();
          this.batches.unshift({id: null, name: 'Velg'})
          this.batchesNotReadyDropdownErrorMessage = null;
        });
    }
  }

  quantityCrateChanged() {
    this.calculateQuantities();
  }

  quantityLooseChanged() {
    this.calculateQuantities();
  }

  calculateQuantities() {
    if (this.articles && this.selectedArticleId) {
      // Find selected article
      let article = this.articles.filter(a => a.id === this.selectedArticleId)[0];

      // Convert to numbers
      let crates = Number(this.quantityCrate || 0);
      let loose = Number(this.quantityLoose || 0);
      let quantityPrCrate = Number(article.quantityPrCrate || 0);
      // Make sure we never calculate with non-integers. We wouldn't want to show a total quantity
      // with decimals while the validation is saying 'not an integer!'
      crates = Number.isInteger(crates) ? crates : Number.NaN;
      loose = Number.isInteger(loose) ? loose : Number.NaN;

      // Calculate total units
      let total = crates * quantityPrCrate + loose;

      // Set quantity directly on line together with articleId, etc.
      this.line.quantity = total;

      return total;
    }
  }

  reverseCalculateQuantitiesAfterLoadingExisting() {
    // If any of these are set we have already done the initial loading.
    // Trying to do it again only creates problems because the actions of this code triggers the change handlers for the other calculation,
    // which isn't ready because selectedArticleId hasn't been set yet.
    if (this.quantityCrate || this.quantityLoose) {
      return;
    }
    // Only load if everything needed is in place.
    // line.quantity is probably not set on regular registration screens, which is ok.
    if (this.articles && this.selectedArticleId && this.line.quantity) {
      let selectedArticle = this.articles.filter(a => a.id === this.selectedArticleId)[0];

      // If quantityPrCrate is not set or zero, put the entire quantity in loose
      if (!selectedArticle || !selectedArticle.quantityPrCrate || selectedArticle.quantityPrCrate === 0) {
        this.quantityCrate = null;
        this.quantityLoose = this.line.quantity;
        return;
      }

      // If everything is set
      // Calculate properly
      this.quantityCrate = Number.parseInt(this.line.quantity / selectedArticle.quantityPrCrate); // parseInt to round down
      this.quantityLoose = this.line.quantity % selectedArticle.quantityPrCrate;
    }
  }
}
