//import {computedFrom} from 'aurelia-framework';

export class RegistrationMenuScreen {
  saveOkMessage;

  activate(params) {
    this.saveOkMessage = params.ok
  }
  dismissSaveOkMessage() {
    this.saveOkMessage = null;
  }

  getCurrentUrl() {
    return window.location.href;
  }
}