import Backend from 'backend';
import {inject} from 'aurelia-framework';

@inject(Backend)
export class FakeSftpContentScreen {
  backend;
  files;

  constructor(backend) {
    this.backend = backend;
  }

  activate() {
    return this.refresh();
  }

  refresh() {
    return this.backend.FakeSftpContentQueryHandler_handle({})
      .then(result => this.files = result.files);
  }

  toggle(file) {
    file.showContent = !file.showContent;
  }
  dismissQueryError() {
    debugger
  }
}
