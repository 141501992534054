import Backend from 'backend';
import {customElement, bindable, inject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {ValidationController, ValidationRules} from "aurelia-validation";
import numeral from "numeral";
import {NewInstance} from "aurelia-dependency-injection";
import {DtFormValidationRenderer} from "devtag-aurelia-components-plugin/dist/validation/validation-renderer";
import {FeatureCustomizationProvider} from "../util/feature-customization-provider";

@customElement('article-create-form')
@inject(Element, Backend, Router, NewInstance.of(ValidationController), FeatureCustomizationProvider)
export class ArticleCreateForm {
  element;
  backend;
  router;
  validationController;

  // For dropdowns
  products;
  packaging;

  maxArticleNumbers;

  // To be posted
  article = {
    vatCode: 0.25,
    batchTracking: true
  };

  fractionalVolumeSameAsPackaging = true;

  @bindable()
  presetProductId;
  featureCustomizationProvider;

  constructor(element, backendFacade, router, validationController, featureCustomizationProvider) {
    this.element = element;
    this.backend = backendFacade;
    this.router = router;
    this.validationController = validationController;
    this.featureCustomizationProvider = featureCustomizationProvider;
  }

  attached() {
    this.article.productId = this.presetProductId;
    this.refresh();

    this.article.productId = this.presetProductId;
    this.backend.MaxArticleNumberQueryHandler_handle({productId: this.presetProductId})
      .then(result => this.maxArticleNumbers = result);
  }

  refresh() {
    return Promise.all([
        this.backend.ProductsForDropdownQueryHandler_handle()
          .then(result => this.products = result.products),
        this.backend.PackagingForDropdownQueryHandler_handle()
          .then(result => this.packaging = result.packaging),
        this.backend.DropdownItemQueryHandler_handle('VatCode')
          .then(result => {
            this.vatCodes = result.items;
            this.vatCodes.unshift({id: null, name: 'Velg'})
          }),
      ]);
  }

  dtFormValidationRenderer = new DtFormValidationRenderer();

  bind() {
    // Don't use custom attribute on form to add renderer because this is a custom element that will be used in modals on pages with other forms,
    // which means we will have two renderers if both forms use the custom attribute on form.
    this.validationController.addRenderer(this.dtFormValidationRenderer)
    ValidationRules
      .ensure('articleNumber')
        .required()
      .ensure('fractionalVolume')
        .displayName('Volume')
        .required()
        .then()
        .satisfies(value => numeral.validate(value, 'en') && value >= 0)
        .withMessage('Volum må være et positivt tall')
      .on(this.article);
  }

  unbind() {
    this.validationController.removeRenderer(this.dtFormValidationRenderer);
  }

  createSubmit() {
    return this.validationController.validate()
      .then(result => {
        if (!result.valid) {
          return;
        }

        //  If the checkbox hasn't been checked, or if the volume field is empty.
        if (this.fractionalVolumeSameAsPackaging || this.article.fractionalVolume === '') {
          this.article.fractionalVolume = null;
        }

        // Backend
        return this.backend.ArticleCrud_create(this.article)

          // Event
          .then(articleId => {
            this.element.dispatchEvent(new CustomEvent('article-saved', {
              detail: {articleId: articleId},
              bubbles: true
            }));
          })

          // Reset all values, form might be used again (in modal f.ex.)
          .then(() => this.article = { productId: this.presetProductId })

          // Validation errors
          .catch(error => {
            if (error.message === 'Article number must be set') {
              this.validationController.addError('Artikkelnummer kan ikke være tomt', this.article, 'articleNumber');
            } else {
              throw error;
            }
          });
      });
  }
}
