import Backend from 'backend';
import {customElement, bindable, bindingMode, observable} from 'aurelia-framework';
import {inject} from 'aurelia-framework';
import {BackendCache} from "../util/backend-cache";

@customElement('existing-report-warning-component')
@inject(Backend, Element, BackendCache)
export class ExistingReportWarningComponent {
  backend;
  backendCache;
  element;

  @bindable({changeHandler: 'dateChanged'})
  date;


  constructor(backend, element, backendCache) {
    this.backend = backend;
    this.element = element;
    this.backendCache = backendCache;
  }

  dateChanged() {
    if (this.date) {
      return this.backendCache.ExciceDutyReportExistsQueryHandler_handle(this.date)
        .then(result => this.showWarning = result.reportExists)
    }
  }
}
