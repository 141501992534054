import Backend from 'backend';
import {inject, observable} from 'aurelia-framework';
import {Router} from 'aurelia-router';

@inject(Backend, Router)
export class MovementEditScreen {
    backend;
    router;

    revertInProgress;

    editmovement;

    customers;
    storageAreas;
    products;
    articles;
    batches;

    @observable({changeHandler: 'productIdSelected'})
    selectedProductId;
    @observable({changeHandler: 'articleIdSelected'})
    selectedArticleId;

    constructor(backend, router) {
        this.backend = backend;
        this.router = router;
    }

    activate(params) {
        this.backUrl = params.backUrl;
        return this.refresh(params.movementId);
    }

    refresh(movementId) {
        return Promise.all([
              this.backend.EditMovementCommandHandler_getForEdit(movementId)
                  .then(result => {
                      this.editmovement = result;
                      this.selectedProductId = result.productId;
                      this.showBatch = this.editmovement.batchId !== null;
                  }),
              this.backend.CustomersForDropdownQueryHandler_handle()
                .then(result => this.customers = result.customers),
              this.backend.StorageAreasForDropdownQueryHandler_handle()
                .then(result => this.storageAreas = result.storageAreas),
              this.backend.ProductsForDropdownQueryHandler_handle()
                .then(result => this.products = result.products),
          ])
          .catch(error => this.queryError = "Error: " + error.toString())
    }

    productIdSelected(selectedProductId) {
        return Promise.all([
              this.backend.ProductArticlesForDropdownQueryHandler_handle({productId: selectedProductId})
                .then(result => this.articles = result.articles)
                .then(() => this.selectedArticleId = this.editmovement.articleId),

          ])
          .catch(error => this.queryError = "Error: " + error.toString());
    }

    articleIdSelected(selectedArticleId) {
      this.editmovement.articleId = selectedArticleId;
      let article = this.articles.filter(a => a.id === this.selectedArticleId)[0];

      if (article?.batchTracking) {
        this.showBatch = true;
        this.backend.BatchesForDropdownQueryHandler_handle({productId: this.selectedProductId})
          .then(result => this.batches = result.batches);
      } else {
        this.showBatch = false;
      }

    }

    submit() {
        this.saveInProgress = true;
        this.backend.EditMovementCommandHandler_handle({movement: this.editmovement})
            // .then(result => this.router.navigateToRoute("movement-details", {movementId: result, backUrl: this.backUrl}))
          .then(result => window.location = this.backUrl)
            .catch(error => this.saveError = error)
            .then(() => this.saveInProgress = false);
    }

    dismissSaveError() {
        this.saveError = null;
    }

    dismissQueryError() {
        this.queryError = null;
    }

    revert() {
        this.revertInProgress = true;
        this.backend.EditMovementCommandHandler_revert({movementId: this.editmovement.movementId, comment: this.editmovement.comment})
          // .then(result => this.router.navigateToRoute("movement-details", {movementId: result}))
          .then(result => window.location = this.backUrl)
          .catch(error => this.saveError = error)
          .then(() => this.revertInProgress = false);
    }
}
