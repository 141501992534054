export class MaterialValueConverter {
  static x = {
    GLASS: 'Glass',
    METAL: 'Metall',
    PLASTIC: 'Plast',
    CARTON_CARDBOARD: 'Kartong/papp',
  };

  toView(value) {
    return MaterialValueConverter.toView(value);
  }

  static toView(value) {
    return this.x[value];
  }
}
