export class TransferTypeValueConverter {
  static x = {
    INVOICE_ID: 'Faktura',
    BUNDLE_ID: 'Fakturaliste'
  };

  toView(value) {
    return TransferTypeValueConverter.toView(value);
  }

  static toView(value) {
    return this.x[value];
  }
}
