import Backend from 'backend';
import {customElement, bindable, bindingMode, observable} from 'aurelia-framework';
import {inject} from 'aurelia-framework';

@customElement('order-documents-component')
@inject(Backend, Element)
export class OrderDocumentsComponent {
  backend;
  element;

  @bindable()
  orderId;

  @bindable()
  order;

  documents;

  @bindable()
  initialActiveDocumentId;

  activeDocument = {type: 'ORDER'};

  constructor(backend, element) {
    this.backend = backend;
    this.element = element;
  }

  attached() {
      this.refresh();
  }

  refresh() {
    return this.backend.OrderDocumentListQueryHandler_handle({orderId: this.orderId ?? this.order.orderId})
      .then(result => {
        this.documents = result.items;
        if (this.initialActiveDocumentId) {
          this.documents
            .filter(document => document.id === this.initialActiveDocumentId)
            .forEach(document => this.showDocument(document))
        } else {
          this.showDocument(this.documents[0]);
        }
      });
  }

  detailsComponentHasRefreshed() {
    return this.refresh()
      .then(() => {
        this.element.dispatchEvent(new CustomEvent('refreshed', {
              detail: {},
              bubbles: true
            }));
      });
  }

  showDocument(document) {
    if (document && document.id) {
      this.activeDocument = document;
    }
  }

  problemCountChanged() {
    this.detailsComponentHasRefreshed();
  }
}
