import Backend from 'backend';
import {inject, observable} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {OrderTypeValueConverter} from 'util/order-type-value-converter';
import moment from 'moment';
import {ValidationController} from "aurelia-validation";

@inject(Backend, Router, ValidationController)
export class RegisterOrderScreen {
    backend;

    orderTypes;

    command = {
        date: moment().format('YYYY-MM-DD'), // today()
        isDutyFree: false, // Default value because backend asserts it's not null for returned goods because we don't want to accidentally post null as falsy.
        lines: [
            {} // Initial empty line in gui
        ],
        freetextLines: [
            {} // Initial empty line in gui
        ]
    };

    saveError;
    saveInProgress;

    queryError;

    router;
    validationController;

    constructor(backendFacade, router, validationController) {
        this.backend = backendFacade;
        this.router = router;
        this.validationController = validationController;
    }

    activate(params) {
        this.backUrl = params.backUrl;
        return this.refresh();
    }

    refresh() {
        return Promise.all([
              this.backend.OrderTypesForDropdownQueryHandler_handle()
                .then(result => this.orderTypes = result.orderTypes),
          ])
          .catch(error => this.queryError = "Error: " + error.toString())
    }

    createSubmit() {
        return this.validationController.validate()
          .then(result => {
              if (!result.valid) {
                  return;
              }
              this.saveError = '';
              return this.backend.RegisterOrderCommandHandler_handle(this.command)
                .then(() => this.router.navigateToRoute('order-index', {ok: true}))
                .catch(error => this.saveError = error.message);
          });
    }

    translateOrderType(value) {
        return OrderTypeValueConverter.toView(value);
    }

    dismissSaveError() {
        this.saveError = null;
    }
}