import Backend from 'backend';
import {inject, TaskQueue} from 'aurelia-framework';
import {activationStrategy, Router} from 'aurelia-router';

import Materialize from 'materialize-css'
import {OrderTypeValueConverter} from 'util/order-type-value-converter';
import moment from "moment";
import {download} from "../util/download-file";
import {FeatureCustomizationProvider} from "../util/feature-customization-provider";

@inject(Backend, TaskQueue, Router, FeatureCustomizationProvider)
export class OrderIndexScreen {
    backend;
    taskQueue;
    router;

    orders = [];
    ordersOrderConfirmation = [];
    ordersDelivery = [];
    ordersProofOfDelivery = [];
    ordersInvoice = [];
    ordersDone = [];
    ordersCancelled = [];

    customers;
    orderTypes;

    fromDateInclusive;
    toDateInclusive;
    selectedCustomerId;
    selectedOrderType;
    invoiceNumber;

    saveOkMessage;

    el;

    tabId;
    featureCustomizationProvider;

    constructor(backend, taskQueue, router, featureCustomizationProvider) {
        this.backend = backend;
        this.taskQueue = taskQueue;
        this.router = router
        this.featureCustomizationProvider = featureCustomizationProvider;
    }

    activate(params) {
        this.saveOkMessage = params.ok;

        this.fromDateInclusive = params.fromDateInclusive || moment().subtract(90, 'days').startOf('month').format('YYYY-MM-DD');
        this.toDateInclusive = params.toDateInclusive || moment().endOf('month').format('YYYY-MM-DD');
        this.tabId = params.tabId || 'test1';
        this.selectedCustomerId = params.customerId;
        this.selectedOrderType = params.orderType;
        this.invoiceNumber = params.invoiceNumber;

        return this.refresh()
          .then(() => this._submitQuery());
    }

    attached() {
        let element = document.getElementById('tabs-row-ul');
        this.tabsInstance = Materialize.Tabs.init(element, {
            onShow: element => {
                this.tabId = element.getAttribute('id');
                this.updateUrl();
            }
        });
        this.tabsInstance.select(this.tabId)
        this.taskQueue.queueMicroTask({
            call: () => this.tabsInstance.updateTabIndicator()
        });
    }

    refresh() {
        return Promise.all([
            this.backend.CustomersForDropdownQueryHandler_handle()
              .then(result => {
                  this.customers = result.customers;
                  this.customers.unshift({id: null, name: 'Alle'});
              }),
              this.backend.OrderTypesForDropdownQueryHandler_handle()
                .then(result => {
                    this.orderTypes = result.orderTypes;
                    this.orderTypes.unshift({id: null, name: 'Alle'});
                }),
          ])
    }

    submitQueryForm() {
      return this._submitQuery()
        .then(() => this.updateUrl());
    }

    _submitQuery() {
        if (this.invoiceNumber === '') {
            this.invoiceNumber = null;
        }
        return Promise.all([
              this.backend.OrderIndexQueryHandler_handle({fromDateInclusive: this.fromDateInclusive, toDateInclusive: this.toDateInclusive, customerId: this.selectedCustomerId, orderType: this.selectedOrderType, invoiceNumber: this.invoiceNumber})
                .then(result => this.orders = result.orders),
              this.backend.OrderIndexQueryHandler_handle({fromDateInclusive: this.fromDateInclusive, toDateInclusive: this.toDateInclusive, customerId: this.selectedCustomerId, orderType: this.selectedOrderType, invoiceNumber: this.invoiceNumber, orderStep: 'ORDER_CONFIRMATION' })
                .then(result => this.ordersOrderConfirmation = result.orders),
              this.backend.OrderIndexQueryHandler_handle({fromDateInclusive: this.fromDateInclusive, toDateInclusive: this.toDateInclusive, customerId: this.selectedCustomerId, orderType: this.selectedOrderType, invoiceNumber: this.invoiceNumber, orderStep: 'DELIVERY' })
                .then(result => this.ordersDelivery = result.orders),
              this.backend.OrderIndexQueryHandler_handle({fromDateInclusive: this.fromDateInclusive, toDateInclusive: this.toDateInclusive, customerId: this.selectedCustomerId, orderType: this.selectedOrderType, invoiceNumber: this.invoiceNumber, orderStep: 'PROOF_OF_DELIVERY' })
                .then(result => this.ordersProofOfDelivery = result.orders),
              this.backend.OrderIndexQueryHandler_handle({fromDateInclusive: this.fromDateInclusive, toDateInclusive: this.toDateInclusive, customerId: this.selectedCustomerId, orderType: this.selectedOrderType, invoiceNumber: this.invoiceNumber, orderStep: 'INVOICE' })
                .then(result => this.ordersInvoice = result.orders),
              this.backend.OrderIndexQueryHandler_handle({fromDateInclusive: this.fromDateInclusive, toDateInclusive: this.toDateInclusive, customerId: this.selectedCustomerId, orderType: this.selectedOrderType, invoiceNumber: this.invoiceNumber, orderStep: 'DONE' })
                .then(result => this.ordersDone = result.orders),
              this.backend.OrderIndexQueryHandler_handle({fromDateInclusive: this.fromDateInclusive, toDateInclusive: this.toDateInclusive, customerId: this.selectedCustomerId, orderType: this.selectedOrderType, invoiceNumber: this.invoiceNumber, orderStep: null, onlyCancelled: true})
                .then(result => this.ordersCancelled = result.orders),
              this.backend.InvoicesForAccountingIndexQueryHandler_handle({fromDateInclusive: this.fromDateInclusive, toDateInclusive: this.toDateInclusive, customerId: this.selectedCustomerId, invoiceNumber: this.invoiceNumber})
                .then(result => this.invoices = result.items),
              this.backend.TransferIndexQueryHandler_handle({})
                .then(result => this.transfers = result.items)
          ]);
    }

    updateUrl() {
        this.router.navigateToRouteAndFixIssue("order-index", {
            fromDateInclusive: this.fromDateInclusive,
            toDateInclusive: this.toDateInclusive,
            customerId: this.selectedCustomerId,
            orderType: this.selectedOrderType,
            invoiceNumber: this.invoiceNumber,
            tabId: this.tabId
        },
        {
            replace: true,
            trigger: false
        });
        this.taskQueue.queueMicroTask(() => {
          this.backUrl = this.router.getBackUrl();
        });
    }

    backUrl;

    // Define what to do when links points to this same page, but with different params.
    determineActivationStrategy() {
        return activationStrategy.replace; //replace the viewmodel with a new instance
        // or activationStrategy.invokeLifecycle to invoke router lifecycle methods on the existing VM
        // or activationStrategy.noChange to explicitly use the default behavior
    }

    translateOrderType(value) {
        return OrderTypeValueConverter.toView(value);
    }

    dismissSaveOkMessage() {
      this.saveOkMessage = null;
    }
}
