import Backend from 'backend';
import {inject, TaskQueue} from 'aurelia-framework';
import Materialize from 'materialize-css'
import moment from 'moment';
import {Router} from "aurelia-router";
import {FeatureCustomizationProvider} from "../util/feature-customization-provider";

@inject(Backend, TaskQueue, Router, FeatureCustomizationProvider)
export class SalesStatisticsReportScreen {
  backend;
  taskQueue;
  router;

  byArticle;
  byProductCategory;
  byCustomer;
  byYearMonth;
  baseData;
  fromDateInclusive = '2021-01-01';
  toDateInclusive = '2022-01-01';

  constructor(backend, taskQueue, router, featureCustomizationProvider) {
    this.backend = backend;
    this.taskQueue = taskQueue;
    this.router = router;
    this.featureCustomizationProvider = featureCustomizationProvider;
  }

  activate(params) {
    this.fromDateInclusive = params.fromDateInclusive || moment().startOf('month').format('YYYY-MM-DD');
    this.toDateInclusive = params.toDateInclusive || moment().endOf('month').format('YYYY-MM-DD');
    if (this.fromDateInclusive && this.toDateInclusive) {
      this.refresh();
    }
  }

  submitQuery() {
    this.router.navigateToRoute("sales-statistics-report", {
      fromDateInclusive: this.fromDateInclusive,
      toDateInclusive: this.toDateInclusive,
    });
    return this.refresh();
  }

  refresh() {
    this.hasRefreshed = true;
    let query = {
        fromDateInclusive: this.fromDateInclusive,
        toDateInclusive: this.toDateInclusive
    }
    this.baseData = null;
    this.byArticle = null;
    this.byCustomer = null;
    this.byYearMonth = null;
    return Promise.all([
      this.backend.SalesStatisticsByArticleQueryHandler_handle(query)
        .then(result => this.byArticle = result.items),
      this.backend.SalesStatisticsByCustomerQueryHandler_handle(query)
        .then(result => this.byCustomer = result.items),
      this.backend.SalesStatisticsByProductCategoryQueryHandler_handle(query)
        .then(result => this.byProductCategory = result.items),
      this.backend.SalesStatisticsByYearMonthQueryHandler_handle({
          fromDateInclusive: '2000-01-01',
          toDateInclusive: '2100-01-01'
        })
        .then(result => this.byYearMonth = result.items),
      this.backend.SalesStatisticsBaseDataQueryHandler_handle(query)
        .then(result => this.baseData = result.items),
    ]);
  }

  attached() {
    let element = document.getElementById('tabs-row-ul');
    this.tabsInstance = Materialize.Tabs.init(element, {});
    this.taskQueue.queueMicroTask({
      call: () => {
        this.tabsInstance.updateTabIndicator();
      }
    });
  }
}
