import {inject} from 'aurelia-framework';
import {AuthenticationService} from "devtag-aurelia-auth-plugin";

@inject(AuthenticationService)
export class Login {
    constructor(authenticationService) {
        this.authenticationService = authenticationService;
    }

    // This, and the dependency injection for authenticationService, is only necessary in this view model to have a login button.
    // It's not tied to any of the other stuff here
    authenticationService;
    redirectToLogin() {
        this.authenticationService.redirectToLogin();
    }
}
