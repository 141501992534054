import Backend from 'backend';
import {customElement, bindable, bindingMode, observable} from 'aurelia-framework';
import {inject} from 'aurelia-framework';

@customElement('movement-group-component')
@inject(Backend, Element)
export class MovementGroupComponent {
  backend;
  element;

  @bindable()
  movementGroupId;

  movementGroup;
  allMovementsReverted;

  constructor(backend, element) {
    this.backend = backend;
    this.element = element;
  }

  attached() {
    this.refresh();
  }

  refresh() {
    this.movementGroup = null;
    this.backend.MovementGroupDetailsQueryHandler_handle({movementGroupId: this.movementGroupId})
      .then(result => {
        this.movementGroup = result.movementGroup;
        this.allMovementsReverted = result.allMovementsReverted;
      });
  }

  revertAll(comment) {
    return this.backend.RevertMovementGroupCommandHandler_handle({movementGroupId: this.movementGroupId, comment: comment})
      .then(() => this.refresh())
      .then(() => this.revertAllModal.close())
      .then(() => {
        this.element.dispatchEvent(new CustomEvent('refreshed', {
              detail: {movementGroupId: this.movementGroupId},
              bubbles: true
            }));
      });
  }

  getCurrentUrl() {
    return window.location.href;
  }
}
