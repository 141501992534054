import Backend from 'backend';
import {customElement, bindable, bindingMode, observable} from 'aurelia-framework';
import {inject} from 'aurelia-framework';
import {download} from "../util/download-file";

@customElement('transfer-table-component')
@inject(Backend, Element)
export class TransferTableComponent {
  backend;
  element;

  @bindable()
  transfers;

  @bindable()
  backUrl;

  constructor(backend, element) {
    this.backend = backend;
    this.element = element;
  }

  closeAndDownload(transfer) {
    this.assertIsBundle(transfer);
    let bundleId = transfer.reference;
    return this.backend.CloseBundleCommandHandler_handle({bundleId: bundleId})
      .then(() => this.refresh())
      .then(() => this.download(transfer));
  }

  refresh() {
    return this.backend.TransferIndexQueryHandler_handle({})
      .then(result => this.transfers = result.items)
  }
  download(transfer) {
    this.assertIsBundle(transfer);
    return this.backend.DownloadTransferFileQueryHandler_handle({transferId: transfer.transferId})
                       .then(result => download(result.fileBase64, result.filename));
  }

  assertIsBundle(transfer) {
    if (transfer.referenceType !== 'BUNDLE_ID') {
      throw new Error('Cannot close and download transfer that is not type bundle.');
    }
  }
  showDetails(transfer) {
    this.transferDetailsModal.open();
    this.transferDetailsComponent.refresh(transfer.transferId);
  }
}
