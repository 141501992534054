export class OrderDocumentTypeValueConverter {
  static x = {
    ORDER: 'Ordre',
    ORDER_CONFIRMATION: 'Ordrebekreftelse',
    DELIVERY_NOTE: 'Pakkseddel',
    PROOF_OF_DELIVERY: 'Varemottaksbekreftelse',
    INVOICE: 'Faktura',
    CREDIT_NOTE: 'Kreditnota',
    MOVEMENT_GROUP: 'Lagerbevegelser'
  };

  toView(value) {
    return OrderDocumentTypeValueConverter.toView(value);
  }

  static toView(value) {
    return this.x[value];
  }
}
