export class CustomerTypeValueConverter {
  static x = {
    RETAIL_LICENCE: 'Salgsbevilling',
    DIRECT_SALE: 'Direktesalg',
    LIQUOR_LICENSE: 'Skjenkebevilling',
    VINMONOPOLET: 'Vinmonopolet',
    EMPLOYEES_AND_OWNERS: 'Ansatte og eiere',
    SPECIFIED_BY_ADDITIONAL_CODE: 'Spesifisert av tilleggskode',
    KIOSKS_AND_GAS_STATIONS: 'Kiosk og bensinstasjon',
    OTHER_PRODUCERS_AND_GROCERS: 'Andre tilvirkere og grossister - 30 (overført til andre registrerte avgiftspliktige)',
  };

  toView(value) {
    return CustomerTypeValueConverter.toView(value);
  }

  static toView(value) {
    return this.x[value];
  }
}
