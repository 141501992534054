import Backend from 'backend';
import {customElement, bindable, bindingMode, observable} from 'aurelia-framework';
import {inject} from 'aurelia-framework';
import {SaleTypeValueConverter} from 'util/sale-type-value-converter';
import {BackendCache} from "../util/backend-cache";
import {ValidationController, ValidationRules} from "aurelia-validation";
import {NewInstance} from "aurelia-dependency-injection";

@customElement('registration-sub-form-for-customer-component')
@inject(Backend, Element, BackendCache, ValidationController /*Without controller we get error when using this component in forms that don't have validation on their own.*/)
export class RegistrationSubFormForCustomerComponent {
  backend;
  backendCache;
  element;

  queryError;

  // Bindable input
  @bindable
  orderType;
  @bindable()
  forceNativeSelects = false;

  // Bindable outputs
  @bindable({defaultBindingMode: bindingMode.twoWay, changeHandler: 'customerIdSelected'})
  customerId;
  @bindable({defaultBindingMode: bindingMode.twoWay})
  saleType;
  @bindable({defaultBindingMode: bindingMode.twoWay})
  isDutyFree;
  @bindable({defaultBindingMode: bindingMode.twoWay})
  correspondingSalePeriod;

  @bindable
  disableCustomerDropdown = false;

  // Dropdown data
  customers;
  saleTypes;

  // Internal
  disableIsDutyFreeCheckbox;
  disableSaleTypeDropdown;

  constructor(backend, element, backendCache) {
    this.backend = backend;
    this.element = element;
    this.backendCache = backendCache;
  }

  attached() {
    return Promise.all([
      this.backendCache.CustomersForDropdownQueryHandler_handle()
        .then(result => {
          this.customers = result.customers.slice();
          this.customers.unshift({id: null, name: 'Velg kunde'})

          // Without this duty free checkbox etc is not disabled when the existing selected customeris OTHER_PRODUCERS_AND_GROCERS
          this.customerIdSelected(this.customerId);
        }),
        this.backendCache.SaleTypesForDropdownQueryHandler_handle()
          .then(result => this.saleTypes = result.saleTypes),
      ])
      .catch(error => this.queryError = "Error: " + error.toString());
  }

  bind() {
    ValidationRules
      .ensure('correspondingSalePeriod')
      .displayName('Solgt i periode')
      .satisfies(value => !value || /^\d{4}-\d{2}$/.test(value))
      .withMessage('Solgt i periode må være i format åååå-mm (År-Måned)')
      .on(this);
  }
  customerIdSelected(newValue, oldValue) {
    // Was it actively changed or is it just the initialization that triggers the change handler
    let wasActivelyChanged = oldValue !== null && oldValue !== undefined;

    if (this.customers) {
      // If selected customer is OTHER_PRODUCERS_AND_GROCERS, sale type should be DUTY_FREE
      let selectedCustomers = this.customers.filter(customer => customer.id === newValue);
      let selectedCustomer = selectedCustomers[0];
      if (selectedCustomer.customerType === 'OTHER_PRODUCERS_AND_GROCERS') {
        // Always set default values for this customer type. They should be locked.
        this.saleType = 'DUTY_FREE';
        this.isDutyFree = true;
        this.disableIsDutyFreeCheckbox = true;
        this.disableSaleTypeDropdown = true;
      } else {
        // Only set default values if the select value was actively changed to a different customer.
        // If this change handler is just reacting to initialization then leave the fields as is.
        if (wasActivelyChanged) {
          this.saleType = 'REGULAR';
          this.isDutyFree = false;
        }
        this.disableIsDutyFreeCheckbox = false;
        this.disableSaleTypeDropdown = false;
      }
    }
  }

  translateSaleType(value) {
    return SaleTypeValueConverter.toView(value);
  }
}
