import Backend from 'backend';
import {inject, observable} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {OrderTypeValueConverter} from 'util/order-type-value-converter';

import Materialize from 'materialize-css'
import {ValidationController} from "aurelia-validation";

@inject(Backend, Router, ValidationController)
export class OrderEditScreen {
  // Dependencies
  backend;
  router;

  // Params
  orderId;

  // Edit object
  editOrder = {};

  saveInProgress;
  saveError;
  validationController;

  constructor(backend, router, validationController) {
    this.backend = backend;
    this.router = router;
    this.validationController = validationController;
  }

  activate(params) {
    this.orderId = params.orderId;
    this.backUrl = params.backUrl;

    return Promise.all([
        this.backend.OrderEditCommandHandler_getForEdit(params.orderId)
          .then(result => {
            this.editOrder = result.orderEditDto;
            this.selectedCustomerId = this.editOrder.customerId;
          }),
        this.backend.StorageAreasForDropdownQueryHandler_handle()
          .then(result => this.storageAreas = result.storageAreas),
        this.backend.ProductsForDropdownQueryHandler_handle()
          .then(result => this.products = result.products),
        this.backend.OrderTypesForDropdownQueryHandler_handle()
          .then(result => this.orderTypes = result.orderTypes),
      ])
      .catch(error => this.queryError = "Error: " + error.toString())
  }

  submit() {
    return this.validationController.validate()
      .then(result => {
        if (!result.valid) {
          return;
        }
        this.saveError = null;
        return this.backend.OrderEditCommandHandler_edit(this.orderId, this.editOrder)
          .then(result => this.router.navigate(this.backUrl))
          .catch(error => this.saveError = error.message.toString());
      });
  }

  dismissSaveError() {
    this.saveError = null;
  }

  dismissQueryError() {
    this.queryError = null;
  }

  translateOrderType(value) {
    return OrderTypeValueConverter.toView(value);
  }
}
