import Backend from 'backend';
import {inject} from 'aurelia-framework';
import {download} from "../util/download-file";

@inject(Backend)
export class ExciseDutyReportIndexScreen {
  backend;

  // For dropdowns
  reportingPeriods;

  // Query
  selectedReportingPeriod;

  // Query result
  csv = '';
  reports = [];

  queryInProgress;
  queryError;

  constructor(backend) {
    this.backend = backend;
  }

  activate() {
    return this.refresh();
  }

  refresh() {
    return Promise.all([
        this.backend.ReportingPeriodsForDropdownQueryHandler_handle()
          .then(result => this.reportingPeriods = result.reportingPeriods),
        this.backend.ExciseDutyReportListQueryHandler_handle()
          .then(result => this.reports = result.reports),
      ])
      .catch(error => this.queryError = "Error: " + error.toString());
  }

  dismissQueryError() {
    this.queryError = null;
  }
}