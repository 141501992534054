import Backend from 'backend';
import {inject} from 'aurelia-framework';
import {Router} from 'aurelia-router';

@inject(Backend, Router)
export class StorageAreaDetailsScreen {
    backend;
    router;

    storageArea;

    showDeleteConfirm = false;
    deleteError;

    constructor(backend, router) {
        this.backend = backend;
        this.router = router;
    }

    activate(params) {
        return this.backend.StorageAreaCrud_details(params.storageAreaId)
                    .then(storageArea => this.storageArea = storageArea);
    }
}
