import Backend from 'backend';
import {inject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import Materialize from 'materialize-css'

@inject(Backend, Router)
export class StorageAreaCreateScreen {
    backend;
    storageAreas;

    postError;
    router;

    constructor(backendFacade, router) {
        this.backend = backendFacade;
        this.router = router;
        this.refresh();
    }

    refresh() {

    }

    createSubmit() {
        this.saveInProgress = true;
        this.backend.StorageAreaCrud_create(this.storageArea)
            .then(() => this.router.navigateToRoute('storage-area-index'))
            .catch(error => this.postError = error.message)
            .then(() => this.saveInProgress = false)
        ;
    }
}