export class NumeralValueConverter {
  toView(raw) {
    if (typeof raw === "number") {
      raw = raw.toString();
    }
    if (typeof raw === 'string' || raw instanceof String) {
      return raw.replaceAll('.', ',');
    } else {
      return raw;
    }
  }

  fromView(view) {
    if (typeof view === 'string' || view instanceof String) {
      // Often empty strings cant be deserialized as int or bigdecimal in the backend, so the empty value for a numeral should be represented by null.
      if (view.trim() === '') {
        return null
      }
      return view.replaceAll(' ', '').replaceAll(',', '.');
    } else {
      return view;
    }
  }
}
