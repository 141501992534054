export class OrderStepValueConverter {
  static x = {
    ORDER_CONFIRMATION: 'Klar til ordrebekreftelse',
    DELIVERY: 'Klar til levering',
    PROOF_OF_DELIVERY: 'Venter på varemottaksbekreftelse',
    INVOICE: 'Klar til fakturering/kredittering',
    DONE: 'Fullført',
  };

  toView(value) {
    return OrderStepValueConverter.toView(value);
  }

  static toView(value) {
    return this.x[value];
  }
}
