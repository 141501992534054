import Backend from 'backend';
import {customElement, bindable, bindingMode, observable} from 'aurelia-framework';
import {inject} from 'aurelia-framework';

@customElement('lines-component')
@inject(Backend, Element)
export class LinesComponent {
  backend;
  element;

  @bindable({ defaultBindingMode: bindingMode.twoWay })
  lines = [];

  @bindable()
  storageAreaId;

  @bindable()
  showInventory;

  @bindable()
  disableRemoveLineButton = false;
  @bindable()
  disableAddLineButton = false;

  // For dropdowns
  products;

  constructor(backend, element) {
    this.backend = backend;
    this.element = element;
  }

  attached() {
    return Promise.all([
        this.backend.ProductsForDropdownQueryHandler_handle()
          .then(result => {
            this.products = result.products;
            this.products.unshift({id: null, name: 'Velg'})
          }),
      ])
      .catch(error => this.queryError = "Error: " + error.toString());
  }

  addLine() {
    this.lines.push({});
  }

  removeLine(line) {
    this.lines = this.lines.filter(l => l !== line);
  }
}
