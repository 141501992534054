import Backend from 'backend';
import {inject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import moment from 'moment';
import {ValidationController} from "aurelia-validation";

@inject(Backend, Router, ValidationController)
export class RegisterDestructionScreen {
    backend;

    command = {
        date: moment().format('YYYY-MM-DD'), // today()
        lines: [
          {} // Initial empty line in gui
          ]
    };

    saveError;
    saveInProgress;

    queryError;

    router;
    validationController;

    constructor(backendFacade, router, validationController) {
        this.backend = backendFacade;
        this.router = router;
        this.validationController = validationController;
    }

    createSubmit() {
        return this.validationController.validate()
          .then(result => {
              if (!result.valid) {
                  return;
              }
              this.saveError = '';
              return this.backend.RegisterDestructionCommandHandler_destruction(this.command)
                .then(() => this.router.navigateToRoute('registration-menu', {ok: true}))
                .catch(error => this.saveError = error.message)
                ;
          });
    }

    dismissSaveError() {
        this.saveError = null;
    }

}