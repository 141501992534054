import {inject} from 'aurelia-framework';
import {Router} from "aurelia-router";
import Backend from "./backend";
import {EventAggregator} from "aurelia-event-aggregator";

@inject(Router, Backend, EventAggregator)
export class NavBar {
  router;
  backend;
  eventAggregator;

  constructor(router, backend, eventAggregator) {
    this.router = router;
    this.backend = backend;
    this.eventAggregator = eventAggregator;

    this.eventAggregator.subscribe('ProblemCountChanged', (event) => {
      this.bind();
    });
  }

  bind() {
    this.backend.ProblemListQueryHandler_unResolvedProblemCount()
      .then(count => {
        this.unResolvedProblemCount = count;
        let problemListRoute = this.router.navigation.filter(nav => nav.config.name === 'problem-list')[0];
        if (problemListRoute) {
          problemListRoute.unResolvedProblemCount = this.unResolvedProblemCount;
        }
      })
  }
}
