import Backend from 'backend';
import {customElement, bindable, bindingMode, observable} from 'aurelia-framework';
import {inject} from 'aurelia-framework';

@customElement('delivery-note-component')
@inject(Backend, Element)
export class DeliveryNoteComponent {
  backend;
  element;

  @bindable({changeHandler: 'deliveryNoteIdChanged'})
  deliveryNoteId;

  @bindable()
  hideSendButton;

  deliveryNote;

  constructor(backend, element) {
    this.backend = backend;
    this.element = element;
  }

  deliveryNoteIdChanged() {
    this.refresh();
  }

  refresh() {
    this.deliveryNote = null;
    return this.backend.DeliveryNoteDetailsQueryHandler_handle({deliveryNoteId: this.deliveryNoteId})
      .then(result => {
        this.deliveryNote = result.deliveryNote;
      });
  }

  send() {
    return this.backend.SendDeliveryNoteCommandHandler_handle({deliveryNoteId: this.deliveryNoteId})
      .then(() => this.refresh())
      .then(() => {
        this.element.dispatchEvent(new CustomEvent('refreshed', {
              detail: {deliveryNoteId: this.deliveryNoteId},
              bubbles: true
            }));
      });
  }

  getCurrentUrl() {
    return window.location.href;
  }
}
