import {PLATFORM} from 'aurelia-pal';
import {EventAggregator} from 'aurelia-event-aggregator';
import {inject} from "aurelia-framework";
import {Router, RouterEvent} from 'aurelia-router';
import {Configurations} from "devtag-aurelia-config-plugin";
import {BackendCache} from "./util/backend-cache";
import {FeatureCustomizationProvider} from "./util/feature-customization-provider";

@inject(Configurations, Router, EventAggregator, BackendCache, FeatureCustomizationProvider)
export class App {
  isTestEnvironment;
  configurations;
  featureCustomizationProvider;

  constructor(configurations, router, eventAggregator, backendCache, featureCustomizationProvider) {
    this.configurations = configurations;
    this.featureCustomizationProvider = featureCustomizationProvider;

    // Every route change: clear the backend cache. Otherwise new e.g. customers will not show up in dropdowns until page-load.
    eventAggregator.subscribe(RouterEvent.Processing, (event) => {
      backendCache.clear();
    });

    // This works for both page loads and on page routing failures
    eventAggregator.subscribe(RouterEvent.Error, (event) => {
      let routeName = event.instruction?.config?.name;

      // Assert that we know which route failed. If we don't know our protection for infinite recursion won't work.
      if (!routeName) {
        console.log(event);
        throw new Error('Router error handler was called with an event that didn\'t have the expected routing info [event.instruction.config.name].');
      }

      // Assert that we are not in a recursive failure
      if (routeName === 'error-screen') {
        // If we have the error, throw it
        if (event.result.output) {
          throw event.result.output;
        } else {
          throw new Error('Error screen failed, but router error handler was not given the error details.');
        }
      }
      let newVar = {
        navigationResult: event.result,
        navigationInstructionData: {
          routeName: event.instruction.config.name,
          params: event.instruction.params,
          queryParams: event.instruction.queryParams
        }
      };
      if (event.result.output instanceof Error) {
        newVar.error = {
          name: event.result.output.name,
          message: event.result.output.message,
          stack: event.result.output.stack
        }
      }

      // Remove old data so we don't clog up the client
      sessionStorage.removeItem('error-data_')
      for (let key in sessionStorage) {
        if (key.startsWith('error-data_')) {
          sessionStorage.removeItem(key)
        }
      }

      // Store new data
      // Make sure to use a unique key so that router is forced to update the page in retry failure cases.
      let sessionStorageKey = 'error-data_' + new Date().toISOString(); //Use different key every time
      sessionStorage.setItem(sessionStorageKey, JSON.stringify(newVar));

      // Navigate to error screen
      router.navigateToRoute('error-screen', {sessionStorageKey: sessionStorageKey});
    });
  }

  configureRouter(config, router) {
    this.isTestEnvironment = this.configurations.getConfig('isTestEnvironment');

    config.title = 'Brygg';
    config.map([
      { route: ['', 'home'],          name: 'home',                 moduleId: PLATFORM.moduleName('./home'), nav: true, title: 'Hjem',         authOnly: true },
      { route: 'error-screen',                                name: 'error-screen',     moduleId: PLATFORM.moduleName('./error-screen'), nav: false, title: 'Error', authOnly: true },

      { route: 'brewery-edit',        name: 'brewery-edit',         moduleId: PLATFORM.moduleName('brewery/brewery-edit-screen'),    nav: false, title: 'Bryggeri - rediger',    permission: 'BreweryPermission' },
      { route: 'brewery-details',     name: 'brewery-details',      moduleId: PLATFORM.moduleName('brewery/brewery-details-screen'), nav: true,  title: 'Bryggeri', permission: 'BreweryPermission' },

      { route: 'storage-area',        name: 'storage-area-index',   moduleId: PLATFORM.moduleName('storage-area/storage-area-index-screen'), nav: true, title: 'Lager', permission: 'BreweryPermission' },
      { route: 'storage-area-create', name: 'storage-area-create',  moduleId: PLATFORM.moduleName('storage-area/storage-area-create-screen'), nav: false, title: 'Lager - legg til', permission: 'BreweryPermission' },
      { route: 'storage-area-edit',   name: 'storage-area-edit',    moduleId: PLATFORM.moduleName('storage-area/storage-area-edit-screen'),     title: 'Lager - rediger',    permission: 'BreweryPermission' },
      { route: 'storage-area-details',name: 'storage-area-details', moduleId: PLATFORM.moduleName('storage-area/storage-area-details-screen'),  title: 'Lager - detaljer', permission: 'BreweryPermission' },

      { route: 'customer',            name: 'customer-index',       moduleId: PLATFORM.moduleName('customer/customer-index-screen'), nav: true, title: 'Kunder', permission: 'BreweryPermission' },
      { route: 'customer-create',     name: 'customer-create',      moduleId: PLATFORM.moduleName('customer/customer-create-screen'), nav: false, title: 'Kunde - legg til', permission: 'BreweryPermission' },
      { route: 'customer-edit',       name: 'customer-edit',        moduleId: PLATFORM.moduleName('customer/customer-edit-screen'),     title: 'Kunde - rediger',    permission: 'BreweryPermission' },
      { route: 'customer-details',    name: 'customer-details',     moduleId: PLATFORM.moduleName('customer/customer-details-screen'),  title: 'Kunde - detaljer', permission: 'BreweryPermission' },
      { route: 'customer-permit-create',name: 'customer-permit-create',moduleId: PLATFORM.moduleName('customer/customer-permit-create-screen'), nav: false, title: 'Kundebevilling - legg til', permission: 'BreweryPermission' },
      { route: 'customer-permit-edit',name: 'customer-permit-edit', moduleId: PLATFORM.moduleName('customer/customer-permit-edit-screen'), nav: false, title: 'Kundebevilling - rediger', permission: 'BreweryPermission' },

      { route: 'product',             name: 'product-index',      moduleId: PLATFORM.moduleName('product/product-index-screen'), nav: true, title: 'Produkter', permission: 'BreweryPermission' },
      { route: 'product-create',      name: 'product-create',     moduleId: PLATFORM.moduleName('product/product-create-screen'), nav: false, title: 'Produkt - legg til', permission: 'BreweryPermission' },
      { route: 'product-edit',        name: 'product-edit',       moduleId: PLATFORM.moduleName('product/product-edit-screen'),     title: 'Produkt - rediger',    permission: 'BreweryPermission' },
      { route: 'product-details',     name: 'product-details',    moduleId: PLATFORM.moduleName('product/product-details-screen'),  title: 'Produkt - detaljer', permission: 'BreweryPermission' },

      { route: 'product-category',         name: 'product-category-index',   moduleId: PLATFORM.moduleName('product-category/product-category-index-screen'), nav: false, title: 'Produktkategorier', permission: 'BreweryPermission' },
      { route: 'product-category-create',  name: 'product-category-create',  moduleId: PLATFORM.moduleName('product-category/product-category-create-screen'), nav: false, title: 'Produktkategori - legg til', permission: 'BreweryPermission' },
      { route: 'product-category-edit',    name: 'product-category-edit',    moduleId: PLATFORM.moduleName('product-category/product-category-edit-screen'),     title: 'Produktkategori - rediger',    permission: 'BreweryPermission' },
      { route: 'product-category-details', name: 'product-category-details', moduleId: PLATFORM.moduleName('product-category/product-category-details-screen'),  title: 'Produktkategori - detaljer', permission: 'BreweryPermission' },

      { route: 'customer-price-group',         name: 'customer-price-group-index',   moduleId: PLATFORM.moduleName('customer-price-group/customer-price-group-index-screen'), nav: false, title: 'Kundeprisgrupper', permission: 'BreweryPermission' },
      { route: 'customer-price-group-create',  name: 'customer-price-group-create',  moduleId: PLATFORM.moduleName('customer-price-group/customer-price-group-create-screen'), nav: false, title: 'Kundeprisgruppe - legg til', permission: 'BreweryPermission' },
      { route: 'customer-price-group-edit',    name: 'customer-price-group-edit',    moduleId: PLATFORM.moduleName('customer-price-group/customer-price-group-edit-screen'),     title: 'Kundeprisgruppe - rediger',    permission: 'BreweryPermission' },
      { route: 'customer-price-group-details', name: 'customer-price-group-details', moduleId: PLATFORM.moduleName('customer-price-group/customer-price-group-details-screen'),  title: 'Kundeprisgruppe - detaljer', permission: 'BreweryPermission' },

      { route: 'packaging',           name: 'packaging-index',      moduleId: PLATFORM.moduleName('packaging/packaging-index-screen'), nav: true, title: 'Forpakning', permission: 'BreweryPermission' },
      { route: 'packaging-create',    name: 'packaging-create',     moduleId: PLATFORM.moduleName('packaging/packaging-create-screen'), nav: false, title: 'Forpakning - legg til', permission: 'BreweryPermission' },
      { route: 'packaging-edit',      name: 'packaging-edit',       moduleId: PLATFORM.moduleName('packaging/packaging-edit-screen'),     title: 'Forpakning - rediger',    permission: 'BreweryPermission' },
      { route: 'packaging-details',   name: 'packaging-details',    moduleId: PLATFORM.moduleName('packaging/packaging-details-screen'),  title: 'Forpakning - detaljer', permission: 'BreweryPermission' },

      { route: 'article-price-list',  name: 'article-price-list',  moduleId: PLATFORM.moduleName('article-price-list/article-price-list-screen'), nav: false, title: 'Prislister', permission: 'BreweryPermission' },

      { route: 'article',             name: 'article-index',       moduleId: PLATFORM.moduleName('article/article-index-screen'), nav: true, title: 'Artikler', permission: 'BreweryPermission' },
      { route: 'article-create',      name: 'article-create',      moduleId: PLATFORM.moduleName('article/article-create-screen'), nav: false, title: 'Artikkel - legg til', permission: 'BreweryPermission' },
      { route: 'article-edit',        name: 'article-edit',        moduleId: PLATFORM.moduleName('article/article-edit-screen'),     title: 'Artikkel - rediger',    permission: 'BreweryPermission' },
      { route: 'article-details',     name: 'article-details',     moduleId: PLATFORM.moduleName('article/article-details-screen'),  title: 'Artikkel - detaljer', permission: 'BreweryPermission' },

      { route: 'batch',               name: 'batch-index',       moduleId: PLATFORM.moduleName('batch/batch-index-screen'), nav: true, title: 'Batcher', permission: 'BreweryPermission' },
      { route: 'batch-create',        name: 'batch-create',      moduleId: PLATFORM.moduleName('batch/batch-create-screen'), nav: false, title: 'Batch - legg til', permission: 'BreweryPermission' },
      { route: 'batch-edit',          name: 'batch-edit',        moduleId: PLATFORM.moduleName('batch/batch-edit-screen'),     title: 'Batch - rediger',    permission: 'BreweryPermission' },
      { route: 'batch-details',       name: 'batch-details',     moduleId: PLATFORM.moduleName('batch/batch-details-screen'),  title: 'Batch - detaljer', permission: 'BreweryPermission' },

      { route: 'registration-menu',   name: 'registration-menu',    moduleId: PLATFORM.moduleName('registration/registration-menu-screen'), nav: true, title: 'Registrer inn/ut', permission: 'BreweryPermission' },
      { route: 'csv-register-sale',   name: 'csv-register-sale',    moduleId: PLATFORM.moduleName('registration/csv-register-sale-screen'), nav: false, title: 'Importer faktura', permission: 'BreweryPermission' },
      { route: 'register-tapping',    name: 'register-tapping',     moduleId: PLATFORM.moduleName('registration/register-tapping-screen'), nav: false, title: 'Registrer tapping', permission: 'BreweryPermission' },
      { route: 'register-destruction',name: 'register-destruction', moduleId: PLATFORM.moduleName('registration/register-destruction-screen'), nav: false, title: 'Registrer destruksjon', permission: 'BreweryPermission' },
      { route: 'register-shortage',   name: 'register-shortage',    moduleId: PLATFORM.moduleName('registration/register-shortage-screen'), nav: false, title: 'Registrer manko', permission: 'BreweryPermission' },
      { route: 'register-opening-balance',name: 'register-opening-balance', moduleId: PLATFORM.moduleName('registration/register-opening-balance-screen'), nav: false, title: 'Registrer åpningsbeholdning', permission: 'BreweryPermission' },
      { route: 'register-received-goods', name: 'register-received-goods',       moduleId: PLATFORM.moduleName('registration/register-received-goods-screen'), nav: false, title: 'Registrer motatte varer', permission: 'BreweryPermission' },
      { route: 'register-sent-goods', name: 'register-sent-goods',  moduleId: PLATFORM.moduleName('registration/register-sent-goods-screen'), nav: false, title: 'Registrer overleverte varer til annen avgiftsregistrert aktør', permission: 'BreweryPermission' },
      { route: 'register-returned-goods', name: 'register-returned-goods',       moduleId: PLATFORM.moduleName('registration/register-returned-goods-screen'), nav: false, title: 'Registrer retur', permission: 'BreweryPermission' },
      { route: 'register-sale',       name: 'register-sale',        moduleId: PLATFORM.moduleName('registration/register-sale-screen'), nav: false, title: 'Registrer salg', permission: 'BreweryPermission' },
      { route: 'register-order',      name: 'register-order', moduleId: PLATFORM.moduleName('registration/register-order-screen'), nav: false, title: 'Registrer ordre', permission: 'BreweryPermission' },
      { route: 'register-surplus',    name: 'register-surplus',     moduleId: PLATFORM.moduleName('registration/register-surplus-screen'), nav: false, title: 'Registrer overtallighet', permission: 'BreweryPermission' },
      { route: 'register-transfer',   name: 'register-transfer',    moduleId: PLATFORM.moduleName('registration/register-transfer-screen'), nav: false, title: 'Registrer overføring', permission: 'BreweryPermission' },

      { route: 'order',               name: 'order-index',    moduleId: PLATFORM.moduleName('order/order-index-screen'), nav: true, title: 'Ordre', permission: 'BreweryPermission' },
      { route: 'order-edit',       name: 'order-edit',  moduleId: PLATFORM.moduleName('order/order-edit-screen'), nav: false, title: 'Ordre - rediger', permission: 'BreweryPermission' },
      { route: 'order-details',       name: 'order-details',  moduleId: PLATFORM.moduleName('order/order-details-screen'), nav: false, title: 'Ordre - detaljer', permission: 'BreweryPermission' },
      { route: 'order-packaging-slip',name: 'order-packaging-slip',  moduleId: PLATFORM.moduleName('order/order-packaging-slip-screen'), nav: false, title: 'Ordre - pakkseddel', permission: 'BreweryPermission' },

      { route: 'movement',            name: 'movement',             moduleId: PLATFORM.moduleName('movement/movement-screen'), nav: true, title: 'Lagerbevegelser', permission: 'BreweryPermission' },
      { route: 'movement-details',    name: 'movement-details',     moduleId: PLATFORM.moduleName('movement/movement-details-screen'), nav: false, title: 'Lagerbevegelsesdetaljer', permission: 'BreweryPermission' },
      { route: 'movement-edit',       name: 'movement-edit',        moduleId: PLATFORM.moduleName('movement/movement-edit-screen'), nav: false, title: 'Rediger lagerbevegelse', permission: 'BreweryPermission' },
      { route: 'inventory',           name: 'inventory',            moduleId: PLATFORM.moduleName('inventory-screen'), nav: true, title: 'Lagerbeholdning', permission: 'BreweryPermission' },
      { route: 'inventory-value',     name: 'inventory-value',      moduleId: PLATFORM.moduleName('reports/inventory-value-screen'), nav: false, title: 'Lagerverdi', permission: 'BreweryPermission' },
      { route: 'inventory-offset-index',  name: 'inventory-offset-index',   moduleId: PLATFORM.moduleName('inventory-offset/inventory-offset-index-screen'), nav: true, title: 'Motregning manko/overtallighet', permission: 'BreweryPermission' },
      { route: 'inventory-offset-details',  name: 'inventory-offset-details',   moduleId: PLATFORM.moduleName('inventory-offset/inventory-offset-details-screen'), nav: false, title: 'Motregning - detaljer', permission: 'BreweryPermission' },
      { route: 'inventory-offset-edit',  name: 'inventory-offset-edit',   moduleId: PLATFORM.moduleName('inventory-offset/inventory-offset-edit-screen'), nav: false, title: 'Motregning - rediger', permission: 'BreweryPermission' },

      { route: 'reporting-menu',      name: 'reporting-menu',       moduleId: PLATFORM.moduleName('reports/reporting-menu-screen'), nav: true, title: 'Rapportering', permission: 'BreweryPermission' },
      { route: 'excise-duty-report-index',  name: 'excise-duty-report-index',  moduleId: PLATFORM.moduleName('reports/excise-duty-report-index-screen'), nav: false, title: 'Rapport - Særavgift ', permission: 'BreweryPermission' },
      { route: 'excise-duty-report-preview',name: 'excise-duty-report-preview',moduleId: PLATFORM.moduleName('reports/excise-duty-report-preview-screen'), nav: false, title: 'Rapport - Særavgift - Forhåndsvisning ', permission: 'BreweryPermission' },
      { route: 'excise-duty-report-details',name: 'excise-duty-report-details',moduleId: PLATFORM.moduleName('reports/excise-duty-report-details-screen'), nav: false, title: 'Rapport - Særavgift - Detaljer ', permission: 'BreweryPermission' },
      { route: 'health-directorate-report', name: 'health-directorate-report', moduleId: PLATFORM.moduleName('reports/health-directorate-report-screen'), nav: false, title: 'Rapport - Helsedirektoratet', permission: 'BreweryPermission' },
      { route: 'infinitum-report', name: 'infinitum-report', moduleId: PLATFORM.moduleName('reports/infinitum-report-screen'), nav: false, title: 'Rapport - Infinitum', permission: 'BreweryPermission' },
      { route: 'storage-report',      name: 'storage-report',       moduleId: PLATFORM.moduleName('reports/storage-report-screen'), nav: false, title: 'Rapport - Excel', permission: 'BreweryPermission' },
      { route: 'brod-report',         name: 'brod-report',          moduleId: PLATFORM.moduleName('reports/brod-report-screen'), nav: false, title: 'Rapport - BROD', permission: 'BreweryPermission' },
      { route: 'yearly-customer-report',      name: 'yearly-customer-report', moduleId: PLATFORM.moduleName('reports/yearly-customer-report-screen'), nav: false, title: 'Rapport - Årlig til kunder', permission: 'BreweryPermission' },
      { route: 'yearly-liquor-license-report',name: 'yearly-liquor-license-report', moduleId: PLATFORM.moduleName('reports/yearly-liquor-license-report-screen'), nav: false, title: 'Rapport - Kommunal salgsbevilling', permission: 'BreweryPermission' },
      { route: 'sales-statistics-report',name: 'sales-statistics-report', moduleId: PLATFORM.moduleName('reports/sales-statistics-report-screen'), nav: false, title: 'Rapport - Salgsstatistikk', permission: 'BreweryPermission' },

      { route: 'fake-sftp-content',     name: 'fake-sftp-content',      moduleId: PLATFORM.moduleName('./fake-sftp-content-screen'), nav: true, title: 'FakeSftp', permission: 'AdminPermission' },
      { route: 'problem-list',          name: 'problem-list',           moduleId: PLATFORM.moduleName('./problem/problem-list-screen'), nav: this.featureCustomizationProvider.showProblemListRoute(), title: 'Problemliste', permission: 'BreweryPermission' },

      { route: 'login',               name: 'login',                moduleId: PLATFORM.moduleName('./login'),   nav: true, title: 'Innlogging',      nonAuthOnly: true },
      { route: 'logout',              name: 'logout',               moduleId: PLATFORM.moduleName('./logout'),  nav: true, title: 'Logg ut',         authOnly: true },
      { route: 'no-permission',       name: 'no-permission',        moduleId: PLATFORM.moduleName('./no-permission'), nav: false, title: 'no-permission', public: true },
    ]);

    config.fallbackRoute('home');
    this.router = router;
  }
}
