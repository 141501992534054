export class CustomerPermitTypeValueConverter {
  static x = {
    RETAIL_LICENCE: 'Salgsbevilling',
    LIQUOR_LICENSE: 'Skjenkebevilling',
  };

  toView(value) {
    return CustomerPermitTypeValueConverter.toView(value);
  }

  static toView(value) {
    return this.x[value];
  }
}
