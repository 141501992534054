import Backend from 'backend';
import {customElement, bindable, bindingMode, observable} from 'aurelia-framework';
import {inject} from 'aurelia-framework';

@customElement('order-confirmation-component')
@inject(Backend, Element)
export class OrderConfirmationComponent {
  backend;
  element;

  @bindable({changeHandler: 'orderConfirmationIdChanged'})
  orderConfirmationId;

  @bindable()
  hideSendButton;

  orderConfirmation;

  constructor(backend, element) {
    this.backend = backend;
    this.element = element;
  }

  orderConfirmationIdChanged() {
    this.refresh();
  }

  refresh() {
    this.orderConfirmation = null;
    return this.backend.OrderConfirmationDetailsQueryHandler_handle({orderConfirmationId: this.orderConfirmationId})
                      .then(result => {
                        this.orderConfirmation = result.orderConfirmation;
                      });
  }

  send() {
    return this.backend.SendOrderConfirmationEdiCommandHandler_handle({orderConfirmationId: this.orderConfirmationId})
                       .then(() => this.refresh())
                       .then(() => {
                         this.element.dispatchEvent(new CustomEvent('refreshed', {
                               detail: {orderConfirmationId: this.orderConfirmationId},
                               bubbles: true
                             }));
                       });
  }
}
