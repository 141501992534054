import Backend from 'backend';
import {inject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {ValidationController, ValidationRules} from "aurelia-validation";
import numeral from "numeral";
import {FeatureCustomizationProvider} from "../util/feature-customization-provider";

@inject(Backend, Router, ValidationController, FeatureCustomizationProvider)
export class ArticleEditScreen {
    backend;
    router;

    // For dropdowns
    products;
    packaging;
    vatCodes;

    fractionalVolumeSameAsPackaging;
    validationController;
    featureCustomizationProvider;

    constructor(backend, router, validationController, featureCustomizationProvider) {
        this.backend = backend;
        this.router = router;
        this.validationController = validationController;
        this.featureCustomizationProvider = featureCustomizationProvider;
    }

    activate(params) {
      let self = this;
        return Promise.all([
            this.backend.ArticleCrud_getForEdit(params.articleId)
                .then(result => {
                    this.editarticle = result.articleEditDto;
                    this.fractionalVolumeSameAsPackaging = this.editarticle.fractionalVolume === null;
                    this.canEditProductId = result.canEditProductId;
                    this.canEditFractionalVolume = result.canEditFractionalVolume;
                    this.canEditPackagingId = result.canEditPackagingId;
                }),
              this.backend.ProductsForDropdownQueryHandler_handle()
                .then(result => this.products = result.products),
              this.backend.PackagingForDropdownQueryHandler_handle()
                .then(result => this.packaging = result.packaging),
              this.backend.DropdownItemQueryHandler_handle('VatCode')
                .then(result => {
                  this.vatCodes = result.items;
                  this.vatCodes.unshift({id: null, name: 'Velg'})
                }),
          ])
          .catch(error => this.queryError = "Error: " + error.toString())
    }

    bind() {
        ValidationRules
          .ensure('volume')
          .displayName('Volume')
          .required()
          .then()
          .satisfies(value => numeral.validate(value, 'en'))
          .withMessage('Volum må være et tall')
          .on(this.editarticle);
    }

    submit() {
        // this.validationController.addError('sfgssdfg', this.editpackaging, 'volume');
        // this.validationController.addError('sfgssdfg', this.editpackaging, 'volume');
        this.validationController.validate()
          .then(result => {
              if (!result.valid) {
                  return;
              }
              this.saveInProgress = true;
              if (this.fractionalVolumeSameAsPackaging || this.editarticle.fractionalVolume === '') {
                  this.editarticle.fractionalVolume = null;
              }
              this.backend.ArticleCrud_edit(this.editarticle.articleId, this.editarticle)
                .then(() => this.router.navigateToRoute("article-details", {articleId: this.editarticle.articleId}))
                .catch(error => this.saveError = error)
                .then(() => this.saveInProgress = false);
          });
    }

    dismissSaveError() {
        this.saveError = null;
    }
}
