import Backend from 'backend';
import {inject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {CustomerPermitTypeValueConverter} from 'util/customer-permit-type-value-converter';
import {ValidationRules, ValidationController} from "aurelia-validation";
import {DateTime} from "luxon";

@inject(Backend, Router, ValidationController)
export class CustomerPermitCreateScreen {
  backend;
  router;
  validationController;

  // For dropdowns
  customerPermitTypes;

  // To be posted
  customerPermit = {validFromInclusive: DateTime.local().toFormat('yyyy-MM-dd')};

  constructor(backendFacade, router, validationController) {
    this.backend = backendFacade;
    this.router = router;
    this.validationController = validationController;
  }

  activate(params) {
    this.customerId = params.customerId;
    return this.refresh();
  }

  bind() {
    ValidationRules
      .ensure('validFromInclusive')
        .displayName('Gyldig f.o.m.')
        .required()
        .then()
        .satisfies(value => DateTime.fromFormat(value, 'yyyy-MM-dd').isValid)
        .withMessage('Må være dato på format 2020-01-31')
      .ensure('validToInclusive')
        .displayName('Gyldig t.o.m.')
        .required()
        .then()
        .satisfies(value => DateTime.fromFormat(value, 'yyyy-MM-dd').isValid)
        .withMessage('Må være dato på format 2020-01-31')
      .on(this.customerPermit);
  }

  refresh() {
    return Promise.all([
      this.backend.DropdownItemQueryHandler_handle('CustomerPermitType')
        .then(result => this.customerPermitTypes = result.items),
    ]);
  }

  createSubmit() {
    return this.validationController.validate()
      .then(result => {
        if (!result.valid) {
          return;
        }
        this.customerPermit.customerId = this.customerId;
        return this.backend.CustomerPermitCrud_create(this.customerPermit)
          .then(() => this.router.navigateToRoute('customer-details', {customerId: this.customerId}));
      });
  }

  translateCustomerPermitType(value) {
    return CustomerPermitTypeValueConverter.toView(value);
  }
}