export const configurationProfiles = {
  baseLineConfig: {
    baseLine: 1,
    isTestEnvironment: ''
  },
  productionConfig: {
    prod: 1,
  },
  environmentVariableConfig: {
    environmentVariableConfigEnabled: 'ENVIRONMENT_VARIABLE_CONFIG_ENABLED',
    apiRoot: "API_ROOT",
    authAuthorityUrl: 'AUTH_AUTHORITY_URL',
    authAppBaseUrl: 'AUTH_APP_BASE_URL',
    isTestEnvironment: 'IS_TEST_ENVIRONMENT',
  },
  developmentConfig: {
    prod: 0,
    dev: 1,
    apiRoot: "http://localhost:1934/api",
    authAuthorityUrl: 'http://localhost:1934/oidc/',
    authAppBaseUrl: 'http://localhost:8080',
  },
};