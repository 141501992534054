import numeral from 'numeral';
import Decimal from 'decimal.js';

export class RateAsPercentValueConverter {
  toView(raw) {
    // If it doesn't look like a number, don't try to do anything.
    if (!numeral.validate(raw, 'en')) {
        return raw;
    }

    return new Decimal(raw)
      .times(100)
      .toString()
      .replace('.', ',');
  }

  fromView(view) {
    if (view === '') {
      return null;
    }
    // If it doesn't look like a number, don't try to do anything.
    if (!numeral.validate(view, 'en')) {
        return view;
    }

    return new Decimal(view.replace(',', '.'))
      .div(100)
      .toString();
  }
}
