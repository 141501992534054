import Backend from 'backend';
import {inject, observable} from 'aurelia-framework';
import {FeatureCustomizationProvider} from "../util/feature-customization-provider";
import {Router} from "aurelia-router";

@inject(Backend, FeatureCustomizationProvider, Router)
export class CustomerIndexScreen {
  backend;
  router;
  customers = [];
  featureCustomizationProvider;

  @observable({changeHandler: 'changeShowInactiveCustomers'})
  showInactiveCustomers;

  constructor(backend, featureCustomizationProvider, router) {
    this.backend = backend;
    this.router = router;
    this.featureCustomizationProvider = featureCustomizationProvider;
  }

  activate(params) {
    this.showInactiveCustomers = params.showInactiveCustomers === true || params.showInactiveCustomers === 'true';
    return this.refresh();
  }

  refresh() {
    return this.backend.CustomerCrud_index(this.showInactiveCustomers)
      .then(result => {
        this.customers = result
      });
  }

  changeShowInactiveCustomers(newValue, oldValue) {
    // Don't do anything when value is initially bound to checkbox
    if (oldValue === undefined) {
      return;
    }

    // Navigate to same route, but with value in params, to remember state across page reload
    let newUrl = this.router.generate('customer-index', {showInactiveCustomers: newValue});
    window.location.href = newUrl;

    // Force reload because we can't change the data underlying the dt-datatables attribute (this.customer) without tripping up the binding engine.
    window.location.reload();
  }
}
