import Backend from 'backend';
import {customElement, bindable, bindingMode, observable} from 'aurelia-framework';
import {inject} from 'aurelia-framework';
import {FeatureCustomizationProvider} from "../util/feature-customization-provider";
import {Router} from "aurelia-router";

@customElement('order-table-component')
@inject(Backend, Element, FeatureCustomizationProvider, Router)
export class OrderTableComponent {
  backend;
  element;
  featureCustomizationProvider;
  router;

  @bindable()
  orders;
  @bindable()
  backUrl;

  constructor(backend, element, featureCustomizationProvider, router) {
    this.router = router;
    this.backend = backend;
    this.element = element;
    this.featureCustomizationProvider = featureCustomizationProvider;
  }

  attached() {

  }
}
