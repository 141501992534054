import Backend from 'backend';
import {inject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {FeatureCustomizationProvider} from "../util/feature-customization-provider";

@inject(Backend, Router, FeatureCustomizationProvider)
export class ProductDetailsScreen {
    backend;
    router;

    product;

    showDeleteConfirm = false;
    deleteError;
    featureCustomizationProvider;

    constructor(backend, router, featureCustomizationProvider) {
        this.backend = backend;
        this.router = router;
        this.featureCustomizationProvider = featureCustomizationProvider;
    }

    activate(params) {
        return this.backend.ProductCrud_details(params.productId)
                    .then(product => this.product = product);
    }
}
