export class BeverageTypeValueConverter {
  static x = {
    BEER: 'Øl',
    WINE: 'Vin',
    LIQUOR: 'Brennevin',
    CIDER: 'Cider',
    MEED: 'Mjød',
    OTHER_YIESTED_ALCOHOLIC_BEVERAGE: 'Annen gjæret alkoholholdig drikk',
    DENATURED_TECHNICAL_ETHANOL: 'Denaturert teknisk etanol',
    NON_ALCOHOLIC_BEVERAGE: 'Alkoholfri drikk',
    SYRUP: 'Sirup'
  };

  toView(value) {
    return BeverageTypeValueConverter.toView(value);
  }

  static toView(value) {
    return this.x[value];
  }
}
