import Backend from 'backend';
import {inject} from 'aurelia-framework';
import {activationStrategy} from 'aurelia-router';
import moment from 'moment';

@inject(Backend)
export class MovementDetailsScreen {
  backend;

  movementId;
  backUrl;

  movement;
  sameMovementGroup;
  sameOriginalMovement;

  queryError;

  constructor(backendFacade) {
    this.backend = backendFacade;
  }

  activate(params) {
    this.movementId = params.movementId;
    this.backUrl = params.backUrl;
    return this.refresh();
  }

  refresh() {
    return this.backend.MovementDetailsQueryHandler_handle(this.movementId)
      .then(result => {
        this.movement = result.movement;
        this.sameMovementGroup = result.sameMovementGroup;
        this.sameOriginalMovement = result.sameOriginalMovement;
      });
  }

  dismissSaveError() {
    this.queryError = null;
  }

  revertAll(comment) {
    return this.backend.RevertMovementGroupCommandHandler_handle({movementGroupId: this.movement.movementGroupId, comment: comment})
      // Full reload instead of this.refresh() because the dt-datatable we're using screws up the dom so it can't be changed after initial rendering.
      .then(() => window.location.reload())
      .then(() => this.revertAllModal.close());
  }

  // Define what to do when links points to this same page, but with different params.
  determineActivationStrategy() {
    return activationStrategy.replace; //replace the viewmodel with a new instance
    // or activationStrategy.invokeLifecycle to invoke router lifecycle methods on the existing VM
    // or activationStrategy.noChange to explicitly use the default behavior
  }

  getCurrentUrl() {
    return window.location.href;
  }
}
