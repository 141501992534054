import Backend from 'backend';
import {inject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {ValidationRules, ValidationController} from 'aurelia-validation';
import {BeverageTypeValueConverter} from 'util/beverage-type-value-converter';
import numeral from "numeral";
import {FeatureCustomizationProvider} from "../util/feature-customization-provider";

@inject(Backend, Router, ValidationController, FeatureCustomizationProvider)
export class ProductEditScreen {
    backend;
    router;
    validationController;

    // For dropdowns
    beverageTypes;

    // To be posted
    editproduct;

    postError;
    featureCustomizationProvider;

    constructor(backend, router, validationController, featureCustomizationProvider) {
        this.backend = backend;
        this.router = router;
        this.validationController = validationController;
        this.featureCustomizationProvider = featureCustomizationProvider;
    }

    activate(params) {
        return Promise.all([
            this.backend.ProductCrud_getForEdit(params.productId)
                .then(result => {
                    this.editproduct = result.productEditDto
                    this.canEditAbv = result.canEditAbv;
                    this.canEditBeverageType = result.canEditBeverageType;
                }),
            this.backend.BeverageTypesForDropdownQueryHandler_handle()
                .then(result => this.beverageTypes = result.beverageTypes),
            this.backend.DropdownItemQueryHandler_handle('ProductCategory')
                .then(result => {
                  this.productCategories = result.items;
                  this.productCategories.unshift({id: null, name: 'Ingen'});
                })
          ])
          .catch(error => this.queryError = "Error: " + error.toString());
    }

    bind() {
        ValidationRules
          .ensure('name')
            .displayName('Navn')
            .required()
          .ensure('abv')
            .displayName('ABV')
            .required()
            .when(object => object.beverageType !== 'SYRUP' && object.beverageType !== 'NON_ALCOHOLIC_BEVERAGE')
            .then()
            .satisfies(value => numeral.validate(value, 'en'))
            .when(object => object.beverageType !== 'SYRUP' && object.beverageType !== 'NON_ALCOHOLIC_BEVERAGE')
            .withMessage('ABV må være et tall')
          .on(this.editproduct);
    }

    submit() {
        this.validationController.validate()
          .then(result => {
              if (!result.valid) {
                  return;
              }
              this.saveInProgress = true;
              this.backend.ProductCrud_edit(this.editproduct.productId, this.editproduct)
                .then(() => this.router.navigateToRoute("product-details", {productId: this.editproduct.productId}))
                .catch(error => this.saveError = error)
                .then(() => this.saveInProgress = false);
          });
    }

    dismissSaveError() {
        this.saveError = null;
    }

    translateBeverageType(value) {
        return BeverageTypeValueConverter.toView(value);
    }
}
