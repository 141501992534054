import Backend from 'backend';
import {customElement, bindable, bindingMode, observable} from 'aurelia-framework';
import {inject} from 'aurelia-framework';

@customElement('permit-validity-warning-component')
@inject(Backend, Element)
export class PermitValidityWarningComponent {
  backend;
  element;

  @bindable({changeHandler: 'refresh'})
  date;

  @bindable({changeHandler: 'refresh'})
  customerId;


  constructor(backend, element) {
    this.backend = backend;
    this.element = element;
  }

  refresh() {
    if (this.date && this.customerId) {
      return this.backend.PermitValidityQueryHandler_handle(this.customerId, this.date)
        .then(result => this.showWarning = result.shouldHavePermit && !result.hasValidPermitOnDate)
    }
  }
}