import Backend from 'backend';
import {inject} from 'aurelia-framework';
import {Router} from 'aurelia-router';

@inject(Backend, Router)
export class CustomerPriceGroupDetailsScreen {
    backend;
    router;

    customerPriceGroup;

    constructor(backend, router) {
        this.backend = backend;
        this.router = router;
    }

    activate(params) {
        return this.backend.CustomerPriceGroupCrud_details(params.customerPriceGroupId)
                    .then(customerPriceGroup => this.customerPriceGroup = customerPriceGroup);
    }
}
