import Backend from 'backend';
import {customElement, bindable, bindingMode, observable} from 'aurelia-framework';
import {inject} from 'aurelia-framework';

@customElement('freetext-lines-component')
@inject(Backend, Element)
export class LinesComponent {
  backend;
  element;

  @bindable({ defaultBindingMode: bindingMode.twoWay })
  lines = [];

  constructor(backend, element) {
    this.backend = backend;
    this.element = element;
  }

  addFreetextLine() {
    this.lines.push({});
  }

  removeFreetextLine(line) {
    this.lines = this.lines.filter(l => l !== line);
  }
}