//import {computedFrom} from 'aurelia-framework';
import Backend from 'backend';
import {inject} from 'aurelia-framework';
import {IdentityProvider} from "devtag-aurelia-auth-plugin";

@inject(Backend, IdentityProvider)
export class Home {
  backend;
  identityProvider;

  constructor(backend, identityProvider) {
    this.backend = backend;
    this.identityProvider = identityProvider;
  }

  checkFtp() {
    return this.backend.CheckVmpSftpCommandHandler_handle({});
  }

  refreshVmpStoreCache() {
    return this.backend.RefreshVmpStoreCacheCommandHandler_handle({});
  }

  attemptAssignAllStoreNumbers() {
    return this.backend.AttemptAssignStoreNumberToAllExistingVmpCustomersCommandHandler_handle({});
  }
}
