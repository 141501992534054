import Backend from 'backend';
import {inject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {CustomerPermitTypeValueConverter} from 'util/customer-permit-type-value-converter';
import {ValidationRules, ValidationController} from "aurelia-validation";
import {DateTime} from "luxon";

@inject(Backend, Router, ValidationController)
export class CustomerPermitEditScreen {
  backend;
  router;
  validationController;

  // For dropdowns
  customerPermitTypes;

  // To be posted
  editcustomerPermit = {};

  customerId;
  customerPermitId;

  constructor(backend, router, validationController) {
    this.backend = backend;
    this.router = router;
    this.validationController = validationController;
  }

  activate(params) {
    this.customerId = params.customerId;
    this.customerPermitId = params.customerPermitId;
    return Promise.all([
      this.backend.DropdownItemQueryHandler_handle('CustomerPermitType')
        .then(result => this.customerPermitTypes = result.items),
        this.backend.CustomerPermitCrud_getForEdit(this.customerId, this.customerPermitId)
          .then(result => {
            this.editcustomerPermit = result.customerPermitEditDto;
            this.canEditCustomerPermitType = result.canEditCustomerPermitType;
          }),
      ]);
  }

  bind() {
    ValidationRules
      .ensure('validFromInclusive')
        .displayName('Gyldig f.o.m.')
        .required()
        .then()
        .satisfies(value => DateTime.fromISO(value).isValid)
        .withMessage('Må være dato på format 2020-01-31')
      .ensure('validToInclusive')
        .displayName('Gyldig t.o.m.')
        .required()
        .then()
        .satisfies(value => DateTime.fromISO(value).isValid)
        .withMessage('Må være dato på format 2020-01-31')
      .on(this.editcustomerPermit);
  }

  submit() {
    return this.validationController.validate()
      .then(result => {
        if (!result.valid) {
          return;
        }
        return this.backend.CustomerPermitCrud_edit(this.customerId, this.customerPermitId, this.editcustomerPermit)
          .then(() => this.router.navigateToRoute("customer-details", {customerId: this.customerId}));
      });
  }

  translateCustomerPermitType(value) {
    return CustomerPermitTypeValueConverter.toView(value);
  }
}
