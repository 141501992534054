import Backend from 'backend';
import {Router} from 'aurelia-router';
import {inject} from 'aurelia-framework';

@inject(Backend, Router)
export class InventoryOffsetDetailsScreen {
  backend;
  router;

  storageAreas;
  year;

  constructor(backend, router) {
    this.backend = backend;
    this.router = router;
  }

  activate(params) {
    this.year = params.year;
    return this.refresh();
  }

  refresh() {
    return Promise.all([
        this.backend.InventoryOffsetQueryHandler_handle({year: this.year})
          .then(result => this.storageAreas = result.storageAreas),
      ])
  }
}
