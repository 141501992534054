import Backend from 'backend';
import {inject} from 'aurelia-framework';
import {ValidationController, ValidationRules} from "aurelia-validation";
import numeral from "numeral";
import {CreateInvoiceComponent} from "../order/document-create/create-invoice-component";
import {FeatureCustomizationProvider} from "../util/feature-customization-provider";

@inject(Backend, ValidationController, FeatureCustomizationProvider)
export class ArticlePriceListScreen {
  backend;

  articleRows;
  ruleHeaders;
  validationController;
  featureCustomizationProvider;

  constructor(backend, validationController, featureCustomizationProvider) {
    this.backend = backend;
    this.validationController = validationController;
    this.featureCustomizationProvider = featureCustomizationProvider;
  }

  activate() {
    return this.refresh();
  }

  refresh() {
    return this.backend.ArticlePriceListQueryHandler_handle({})
      .then(result => {
        // Store result
        this.articleRows = result.articles;
        this.ruleHeaders = result.ruleHeaders
          // Only show cost price unless brewery has prices/invoicing enabled.
          .filter(header => this.featureCustomizationProvider.showPrices() || header.key.priceRuleType === 'COST_PRICE');

        // Transform result
        // Make sure ALL customerPriceGroups are present on ALL articles in the same order as in the header.
        for (const article of this.articleRows) {
          // Do it by looping through the headers and assign either existing or new cell.
          article.rules = this.ruleHeaders.map(ruleHeader => {
            // Find the matching cell
            let rules = article.rules.filter(rule => this.keyEqualsIgnoringArticleId(rule.key, ruleHeader.key));

            // If found, use it
            if (rules.length === 1) {
              return rules[0];
            }
            // If not found, create new one
            else {
              return {
                key: this.copyKeyAndSetArticleId(ruleHeader.key, article.articleId),
                price: null
              };
            }
          });
        }

        // Validation
        for (const article of this.articleRows) {
          for (const rule of article.rules) {
            ValidationRules
              .ensure('price')
              .displayName('Pris')
              .satisfies(value => value == null || numeral.validate(value + '', 'en'))
              .withMessage('Pris må være et tall')
              .then()
              .satisfies(value => CreateInvoiceComponent.validateTwoDecimalMax(value))
              .withMessage('Pris kan maks ha to desimaler')
              .on(rule);
          }
        }
      });
  }

  edit() {
    this.isEditMode = true;
  }

  cancelEdit() {
    this.isEditMode = false;
    this.refresh();
  }

  saveEdit() {
    return this.validationController.validate()
      .then(result => {
          if (!result.valid) {
              return;
          }
          let allRules = this.articleRows.flatMap(article => article.rules);

          return this.backend.SaveArticlePriceListCommandHandler_handle({rules: allRules})
            .then(() => this.refresh())
            .then(() => this.isEditMode = false);
      });
  }

  /**
   * Remove whitespace from price and convert , to .
   */
  trimPrice(price) {
    if (typeof price === 'string') {
      return price.trim()
        ? price.trim().replace(',', '.')
        : null;
    } else {
      return price
    }
  }

  keyEqualsIgnoringArticleId(key, key2) {
    return key.customerPriceGroupId === key2.customerPriceGroupId
        && key.customerId === key2.customerId
        && key.priceRuleType === key2.priceRuleType
  }

  copyKeyAndSetArticleId(key, articleId) {
    return {
      articleId: articleId,
      customerPriceGroupId: key.customerPriceGroupId,
      customerId: key.customerId,
      priceRuleType: key.priceRuleType,
    }
  }
}
