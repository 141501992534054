import Backend from 'backend';
import {inject, observable} from 'aurelia-framework';
import {activationStrategy, Router} from 'aurelia-router';
import moment from 'moment';
import {MovementTypeValueConverter} from "../util/movement-type-value-converter";

@inject(Backend, Router)
export class MovementScreen {
  backend;
  router;

  // For dropdowns
  customers;
  movementTypes;
  storageAreas;
  products;
  articles;
  batches;


  queryInProgress;
  queryError;

  fromDateInclusive;
  toDateInclusive;
  selectedStorageAreaId;
  selectedCustomerId;
  selectedMovementType;
  @observable({changeHandler: 'productIdSelected'})
  selectedProductId;
  selectedArticleId;
  selectedBatchId;

  query;
  movements;

  constructor(backend, router) {
    this.backend = backend;
    this.router = router;
  }

  activate(params) {
    this.selectedProductId = params.productId;
    this.fromDateInclusive = params.fromDateInclusive || moment().startOf('month').format('YYYY-MM-DD');
    this.toDateInclusive = params.toDateInclusive || moment().endOf('month').format('YYYY-MM-DD');
    this.selectedStorageAreaId = params.storageAreaId;
    this.selectedCustomerId = params.customerId;
    this.selectedMovementType = params.movementType;
    this.selectedProductId = params.productId;
    this.selectedArticleId = params.articleId;
    this.selectedBatchId = params.batchId;
    if (params.productId ||
      params.fromDateInclusive ||
      params.toDateInclusive ||
      params.storageAreaId ||
      params.customerId ||
      params.movementType ||
      params.productId ||
      params.articleId ||
      params.batchId) {
      return Promise.all([
        this._submitQuery(),
        this.refresh()]);
    } else {
      return this.refresh();
    }
  }

  refresh() {
    return Promise.all([
        this.backend.CustomersForDropdownQueryHandler_handle()
          .then(result => {
            this.customers = result.customers;
            this.customers.unshift({id: null, name: 'Alle'});
          }),
        this.backend.StorageAreasForDropdownQueryHandler_handle()
          .then(result => {
            this.storageAreas = result.storageAreas;
            this.storageAreas.unshift({id: null, name: 'Alle'});
          }),
        this.backend.ProductsForDropdownQueryHandler_handle()
          .then(result => {
            this.products = result.products;
            this.products.unshift({id: null, name: 'Alle'});
          }),
        this.backend.DropdownItemQueryHandler_handle('MovementType')
          .then(result => {
            this.movementTypes = result.items;
            this.movementTypes.unshift({id: null, name: 'Alle'});
          }),
      ])
      .catch(error => this.queryError = "Error: " + error.toString())
  }

  productIdSelected(selectedProductId) {

    return Promise.all([
        this.backend.ProductArticlesForDropdownQueryHandler_handle({productId: selectedProductId})
          .then(result => {
            this.articles = result.articles;
            this.articles.unshift({id: null, name: 'Alle'});
          }),
        this.backend.BatchesForDropdownQueryHandler_handle({productId: selectedProductId})
          .then(result => {
            this.batches = result.batches;
            this.batches.unshift({id: null, name: 'Alle'});
          }),

      ])
      .catch(error => this.queryError = "Error: " + error.toString());
  }

  submitQueryForm() {
    return this._submitQuery()
      .then(() => this.updateUrl());
  }

  _submitQuery() {
    this.queryInProgress = true;
    this.queryError = '';
    this.query = {
      storageAreaId: this.selectedStorageAreaId,
      fromDateInclusive: this.fromDateInclusive,
      toDateInclusive: this.toDateInclusive,
      productId: this.selectedProductId,
      customerId: this.selectedCustomerId,
      movementType: this.selectedMovementType,
      articleId: this.selectedArticleId,
      batchId: this.selectedBatchId,
    };
    return this.backend.MovementQueryHandler_handle(this.query)
      .then(result => this.movements = result.movements)
      .catch(error => this.queryError = error)
      .then(result => this.queryInProgress = false);
  }

  updateUrl() {
    this.router.navigateToRouteAndFixIssue("movement", {
      productId: this.selectedProductId,
      fromDateInclusive: this.fromDateInclusive,
      toDateInclusive: this.toDateInclusive,
      storageAreaId: this.selectedStorageAreaId,
      customerId: this.selectedCustomerId,
      movementType: this.selectedMovementType,
      articleId: this.selectedArticleId,
      batchId: this.selectedBatchId
    });
  }

  selectedMovementGroupId;

  trClick(movement, event) {
    // This also captures the click events of nested elements, which we don't want to interfere with.
    if (event.target.localName === 'a') {
      // Aurelia will automatically call preventDefault() on events handled with delegate or trigger binding. Most of the time this is the behavior
      // you want. To turn this off, return true from your event handler function.
      return true;
    }

    this.selectedMovementGroupId = movement.movementGroupId;
  }

  getCurrentUrl() {
    return this.router.getBackUrl();
  }

  dismissQueryError() {
    this.queryError = '';
  }

  translateMovementType(value) {
    return MovementTypeValueConverter.toView(value);
  }

  // Define what to do when links points to this same page, but with different params.
  determineActivationStrategy() {
      return activationStrategy.replace; //replace the viewmodel with a new instance
      // or activationStrategy.invokeLifecycle to invoke router lifecycle methods on the existing VM
      // or activationStrategy.noChange to explicitly use the default behavior
  }
}
