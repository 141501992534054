import Backend from 'backend';
import {bindable, customElement, inject, observable} from 'aurelia-framework';
import {ValidationController, ValidationRules} from "aurelia-validation";
import numeral from "numeral";
import {FeatureCustomizationProvider} from "../../util/feature-customization-provider";
import {BackendCache} from "../../util/backend-cache";

@customElement('create-invoice-component')
@inject(Backend, BackendCache, Element, ValidationController, FeatureCustomizationProvider)
export class CreateInvoiceComponent {
  backend;
  backendCache;
  element;
  validationController;
  featureCustomizationProvider;

  @bindable() orderId;

  @bindable() orderPolicy;

  createdInvoiceId;

  @bindable()
  isCreditNote ;

  commandTemplate = {lines: []};

  @observable({changeHandler: 'changeSelectedInvoiceType'})
  selectedInvoiceType

  constructor(backend, backendCache, element, validationController, featureCustomizationProvider) {
    this.backend = backend;
    this.backendCache = backendCache;
    this.element = element;
    this.validationController = validationController;
    this.featureCustomizationProvider = featureCustomizationProvider;
  }

  attached() {
    this.backendCache.DropdownItemQueryHandler_handle('VatCode')
                .then(result => {
                  this.vatCodes = result.items;
                  this.vatCodes.unshift({id: null, name: ''})
                });
  }

  bind() {
    // Set up initial validation. Don't wait to run this. Validation should be enabled before the command template
    // is loaded from api.
    this.refreshValidationRules();
  }

  changeSelectedInvoiceType(selectedInvoiceType) {
    this.refreshValidationRules();
  }

  changeSelectedPrefillSource(selectedPrefillSource) {
    this.backend.CreateInvoiceCommandHandler_getTemplate({orderDocumentType: selectedPrefillSource.orderDocumentType, id: selectedPrefillSource.id})
      .then(result => {
        // Store result
        this.commandTemplate = result.command;
        this.invoiceTypes = result.invoiceTypes.map(invoiceType => ({id: invoiceType, name: invoiceType}));

        // If there is a KID preview, then default to generating KID
        this.commandTemplate.isKidNumber = Boolean(this.commandTemplate.kidNumberPreview);

        this.refreshValidationRules();
      });
  }

  refreshValidationRules() {
    // Remove any old validation rules
    ValidationRules.off(this.commandTemplate)
    for (const line of this.commandTemplate.lines) {
      ValidationRules.off(line)
    }

    // Add validation to result
    ValidationRules
      .ensure('invoiceDate').displayName('Fakturadato').required()
      .ensure('deliveryDate').displayName('Leveringsdato').required()
      .ensure('dueDate').displayName('Forfallsdato').required()
      .ensure('bankAccountNumber').displayName('Bankkontonr.').required()
      .on(this.commandTemplate);

    if (this.isCreditNote === true || this.isCreditNote === 'true') {
      // ValidationRules
      // .ensure('originalInvoiceNumber')
      //   .displayName('Opprinnelig fakturanr.')
      //   .required()
      // .on(this.commandTemplate);
    }


    // Add validation to result's lines
    for (const line of this.commandTemplate.lines) {
      ValidationRules
        .ensure('quantity')
        .displayName('Antall')
        .required()
        .then()
        .satisfies(value => Number.isInteger(Number(value))) // Number() converts string to number. Otherwise "1" is not an integer.
        .withMessage('Antall må være et heltall')
        .satisfies(value => this.selectedInvoiceType !== 'VMP' || value > 0)
        .withMessage('Antall for VMP-faktura må være større enn 0')

        .ensure('vatCode')
        .displayName('MVA-sats')
        .required()
        .then()
        .satisfies(value => value.id != null)

        .ensure('priceExVat')
        .displayName('Pris')
        .required()
        .then()
        .satisfies(value => numeral.validate(value, 'en'))
        .withMessage('Pris må være et tall')
        .then()
        .satisfies(value => CreateInvoiceComponent.validateTwoDecimalMax(value))
        .withMessage('Pris kan maks ha to desimaler')
        .ensure('discountRate')
        .satisfies(value => !value || numeral.validate(value, 'en'))
        .withMessage('Rabatt må være et tall')
        .on(line);
    }
  }

  static validateTwoDecimalMax(value) {
    if (!value) {
      return true;
    }

    let split = value.toString().split('.');
    if (split.length > 2) {
      return false;
    } else if (split.length === 1) {
      return true;
    }
    return split[1].length <= 2;
  }

  saveButtonClicked() {
    return this._save()
          .then(invoiceId => {
            // Don't do this on saveAndSend because we don't want to show the send button while we're in progress (risk of double click)
            this.createdInvoiceId = invoiceId;
          })
          .catch(error => {
            // Mute validation exception, but no other errors.
            if (error.message !== this.validationError) {
              throw error;
            }
          });
  }

  sendButtonClicked() {
    return this._send(this.createdInvoiceId);
  }

  saveAndSendButtonClicked() {
    return this._save()
      .then(invoiceId => this._send(invoiceId))
      .catch(error => {
        // Mute validation exception, but no other errors.
        if (error.message !== this.validationError) {
          throw error;
        }
      });
  }

  validationError = 'validation-error';

  closeButtonClicked() {
    this._emitDoneEvent();
  }

  _save() {
    return this.validationController.validate()
      .then(result => {
        if (!result.valid) {
          throw new Error(this.validationError);
        }

        return this.backend.CreateInvoiceCommandHandler_handle({
          invoiceType: this.selectedInvoiceType,
          isCreditNote: this.isCreditNote === true || this.isCreditNote === 'true',
          originalInvoiceNumber: this.commandTemplate.originalInvoiceNumber,
          orderId: this.commandTemplate.orderId,
          invoiceDate: this.commandTemplate.invoiceDate,
          deliveryDate: this.commandTemplate.deliveryDate,
          dueDate: this.commandTemplate.dueDate,
          bankAccountNumber: this.commandTemplate.bankAccountNumber,
          isKidNumber: this.commandTemplate.isKidNumber,
          requestedBankReference: this.commandTemplate.requestedBankReference,
          customersReference: this.commandTemplate.customersReference,
          lines: this.commandTemplate.lines.map(line => ({
            articleId: line.articleId,
            orderLineId: line.orderLineId,
            description: line.description,
            quantity: line.quantity,
            thisLineIsPanteSats: line.thisLineIsPanteSats,
            priceExVat: line.priceExVat,
            discountRate: line.discountRate,
            vatCode: line.vatCode,
          }))
        })
      });
  }

  _send(invoiceId) {
    return this.backend.SendInvoiceCommandHandler_handle({invoiceId: invoiceId})
      .then(() => this._emitDoneEvent(invoiceId));
  }

  _emitDoneEvent(invoiceId) {
    this.element.dispatchEvent(new CustomEvent('done', {
      detail: {invoiceId: invoiceId},
      bubbles: true
    }));

    // Clear form for reuse
    this.createdInvoiceId = null;
  }

  removeLine(line) {
    this.commandTemplate.lines = this.commandTemplate.lines.filter(l => l !== line);
  }

  removeLinesByCustomerOrderLineReference(line) {
    this.commandTemplate.lines = this.commandTemplate.lines.filter(l => l.customerOrderLineReference !== line.customerOrderLineReference);
  }
}
