import Backend from 'backend';
import {customElement, bindable} from 'aurelia-framework';
import {inject, TaskQueue} from 'aurelia-framework';

@customElement('excise-report-movement-table-component')
@inject(Backend, Element, TaskQueue)
export class ExciseReportMovementTableComponent {
  backend;
  element;
  taskQueue;

  @bindable()
  movements;

  @bindable()
  dutyColumnLabel;

  constructor(backend, element, taskQueue) {
    this.backend = backend;
    this.element = element;
    this.taskQueue = taskQueue;
  }
}