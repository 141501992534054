import {inject} from 'aurelia-framework';
import {Router} from 'aurelia-router';

@inject(Router)
export class ArticleCreateScreen {
  router;

  constructor(router) {
    this.router = router;
  }

  articleSaved() {
    this.router.navigateToRoute('article-index');
  }
}