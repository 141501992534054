import numeral from 'numeral';

export class NumeralTwoDecimalsValueConverter {
  toView(value) {
    if (!value) {
      return value;
    }
    if (!numeral.validate(value, 'en')) {
        console.error("Number did not validate as 'en': " + value);
        return value;
    }
    let displayValue = numeral(value).format('0.00');
    return displayValue;
  }

  fromView(value) {
    if (!numeral.validate(value)) {
        return value;
    }
    let viewModelValue = numeral(value).value();
    return viewModelValue;
  }
}
