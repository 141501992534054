import Backend from 'backend';
import {inject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {ValidationRules, ValidationController} from 'aurelia-validation';
import {RangeOverrideValueConverter} from 'util/range-override-value-converter';

@inject(Backend, Router, ValidationController)
export class ProductCategoryEditScreen {
  backend;
  router;
  validationController;

  constructor(backend, router, validationController) {
    this.backend = backend;
    this.router = router;
    this.validationController = validationController;
  }

  activate(params) {
    return Promise.all([
        this.backend.ProductCategoryCrud_getForEdit(params.productCategoryId)
          .then(result => {
            this.editproductCategory = result.productCategoryEditDto;
          }),
      ])
      .catch(error => this.queryError = "Error: " + error.toString())
  }

  bind() {
    ValidationRules
      .ensure('name')
      .displayName('Navn')
      .required()
      .on(this.editproductCategory);
  }

  submit() {
    return this.validationController.validate()
      .then(result => {
        if (!result.valid) {
          return;
        }
        return this.backend.ProductCategoryCrud_edit(this.editproductCategory.productCategoryId, this.editproductCategory)
          .then(() => this.router.navigateToRoute("product-category-details", {productCategoryId: this.editproductCategory.productCategoryId}));
      });
  }
}
