import Backend from 'backend';
import {inject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {FeatureCustomizationProvider} from "../util/feature-customization-provider";

@inject(Backend, Router, FeatureCustomizationProvider)
export class CustomerDetailsScreen {
  backend;
  router;

  customerId;
  customer;
  permits;

  showDeleteConfirm = false;
  deleteError;
  featureCustomizationProvider;

  constructor(backend, router, featureCustomizationProvider) {
    this.backend = backend;
    this.router = router;
    this.featureCustomizationProvider = featureCustomizationProvider;
  }

  activate(params) {
    this.customerId = params.customerId;
    return Promise.all([
      this.backend.CustomerCrud_details(this.customerId)
        .then(customer => this.customer = customer),
      this.backend.CustomerPermitCrud_index(this.customerId)
        .then(result => this.permits = result),
    ]);
  }
}
