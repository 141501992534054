import Backend from 'backend';
import {inject} from 'aurelia-framework';
import {EventAggregator} from "aurelia-event-aggregator";

@inject(Backend, EventAggregator)
export class ProblemListScreen {
  backend;
  eventAggregator;

  constructor(backend, eventAggregator) {
    this.backend = backend;
    this.eventAggregator = eventAggregator;
  }
}
