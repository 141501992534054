export class RecyclingSchemeValueConverter {
  static x = {
    GRONT_PUNKT: 'Grønt punkt',
    INFINITUM: 'Infinitum',
    NO_RECYCLING: 'Ingen retur',
  };

  toView(value) {
    return RecyclingSchemeValueConverter.toView(value);
  }

  static toView(value) {
    return this.x[value];
  }
}
