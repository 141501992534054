export class SaleTypeValueConverter {
  static x = {
    REGULAR: 'Ordinært',
    PRODUCT_SAMPLE: 'Vareprøve',
    DUTY_FREE: 'Avgiftsfritt - 30 (overført til andre registrerte avgiftspliktige for de aktuelle drikkevarene og emballasjetypene)',
  };

  toView(value) {
    return SaleTypeValueConverter.toView(value);
  }

  static toView(value) {
    return this.x[value];
  }
}
