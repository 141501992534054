import Backend from 'backend';
import {customElement, bindable, bindingMode, observable} from 'aurelia-framework';
import {inject, TaskQueue} from 'aurelia-framework';
import Materialize from 'materialize-css'

@customElement('excise-report-content-component')
@inject(Backend, Element, TaskQueue)
export class ExciseReportContentComponent {
  backend;
  element;
  taskQueue;

  @bindable()
  report;

  constructor(backend, element, taskQueue) {
    this.backend = backend;
    this.element = element;
    this.taskQueue = taskQueue;
  }

  attached() {
    let element = document.getElementById('tabs-row-ul');
    this.tabsInstance = Materialize.Tabs.init(element, {});
    this.taskQueue.queueMicroTask({
      call: () => {
        this.tabsInstance.updateTabIndicator()
        let element2 = document.getElementById('tabs-row-ul2');
        this.tabsInstance2 = Materialize.Tabs.init(element2, {});
        this.taskQueue.queueMicroTask({
          call: () => this.tabsInstance2.updateTabIndicator()
        });
      }
    });
  }
}