import Backend from 'backend';
import {Router} from 'aurelia-router';
import {inject} from 'aurelia-framework';
import {ValidationRules, ValidationController} from 'aurelia-validation';

@inject(Backend, Router, ValidationController)
export class InventoryOffsetEditScreen {
  backend;
  router;
  validationController;

  groups;
  storageAreaId;
  year;

  constructor(backend, router, validationController) {
    this.backend = backend;
    this.router = router;
    this.validationController = validationController;
  }

  activate(params) {
    this.year = params.year;
    return this.refresh();
  }

  refresh() {
    return Promise.all([
        this.backend.InventoryOffsetQueryHandler_handle({storageAreaId: this.storageAreaId, year: this.year})
          .then(result => {
            // Store result
            this.storageAreas = result.storageAreas;

            // Convert result to view models
            this.storageAreas.forEach(storageArea => {
              storageArea.groups = storageArea.groups.map(groupDto => new Group(this, groupDto));
            });
          }),
      ])
  }

  submit() {
    return this.validationController.validate()
      .then(result => {
        if (!result.valid) {
          return;
        }
        // All groups from all storage areas
        let allGroups = this.storageAreas.map(storageArea => storageArea.groups).flat();

        // Shortages
        let shortageLines = allGroups
          .map(group => group.items)
          .flat()
          .filter(item => Math.abs(Number(item.shortageOffsetQuantityToBeConverted)) !== Math.abs(Number(item.shortageOffsetQuantityConverted)))
          .map(item => ({
            storageAreaId: item.storageAreaId,
            articleId: item.articleId,
            batchId: item.batchId,
            quantity: item.shortageOffsetQuantityToBeConverted,
            quantityOld: item.shortageOffsetQuantityConverted
          }));

        // Surpluses
        let surplusLines = allGroups
          .map(group => group.items)
          .flat()
          .filter(item => Math.abs(Number(item.surplusOffsetQuantityToBeConverted)) !== Math.abs(Number(item.surplusOffsetQuantityConverted)))
          .map(item => ({
            storageAreaId: item.storageAreaId,
            articleId: item.articleId,
            batchId: item.batchId,
            quantity: item.surplusOffsetQuantityToBeConverted,
            quantityOld: item.surplusOffsetQuantityConverted
          }));

        // Command
        return this.backend.RegisterShortageConversionCommandHandler_handle({
            year: this.year,
            shortageLines: shortageLines,
            surplusLines: surplusLines
          })
          .then(() => this.router.navigateToRoute('inventory-offset-details', {year: this.year}));
      });
    }
}

class Group {
  viewModel;
  dutyTypeCode;
  dutyGroupCode;
  items;

  sumShortageOffsetQuantity;
  sumSurplusOffsetQuantity;
  sumNetSurplusOffsetQuantity;
  sumShortageOffsetQuantityConverted;
  sumSurplusOffsetQuantityConverted;
  sumShortageOffsetQuantityOriginal;
  sumSurplusOffsetQuantityOriginal;
  sumNetSurplusOffsetQuantityOriginal;


  constructor(viewModel, groupDto) {
    this.viewModel = viewModel;
    Object.assign(this, groupDto);
    this.items = groupDto.items.map(itemDto => new Item(this, itemDto));
  }

  get isShortage() {
    return this.sumNetSurplusOffsetQuantityOriginal < 0;
  }
  get isSurplus() {
    return this.sumNetSurplusOffsetQuantityOriginal > 0;
  }

  get tooLittleShortage() {
    return this.isShortage && this.sumNetSurplusOffsetQuantityOriginalLive < 0;
  }

  get tooMuchShortage() {
    return this.isShortage && this.sumNetSurplusOffsetQuantityOriginalLive > 0;
  }

  get isBalanced() {
    return this.sumNetSurplusOffsetQuantityOriginalLive === 0;
  }

  get tooLittleSurplus() {
    return !this.isShortage && this.sumNetSurplusOffsetQuantityOriginalLive > 0;
  }

  get tooMuchSurplus() {
    return !this.isShortage && this.sumNetSurplusOffsetQuantityOriginalLive < 0;
  }

  get sumOffset() {
    return this.items.map(item => item.offset).reduce((a,b) => a+b);
  }

  get sumShortageOffsetQuantityConvertedLive() {
    return this.items.map(item => -Number(item.shortageOffsetQuantityToBeConverted)).reduce((a,b) => a+b);
  }

  get sumSurplusOffsetQuantityConvertedLive() {
    return this.items.map(item => Number(item.surplusOffsetQuantityToBeConverted)).reduce((a,b) => a+b);
  }

  get sumNetSurplusOffsetQuantityOriginalLive() {
    return 0
      // Add shortage and surplus because shortage is negative
      + this.sumShortageOffsetQuantityOriginal + this.sumSurplusOffsetQuantityOriginal
      //
      - this.sumSurplusOffsetQuantityConvertedLive
      - this.sumShortageOffsetQuantityConvertedLive
  }
}

class Item {
  // Ref to parent
  group;

  // From dto
  storageAreaId;
  articleId;
  articleNumber;
  articleName;
  batchId;
  batchNumber;
  dutyTypeCode;
  dutyGroupCode;
  shortageOffsetQuantity;
  surplusOffsetQuantity;
  netSurplusOffsetQuantity;
  shortageOffsetQuantityConverted;
  surplusOffsetQuantityConverted;
  shortageOffsetQuantityOriginal;
  surplusOffsetQuantityOriginal;
  netSurplusOffsetQuantityOriginal;

  //
  shortageOffsetQuantityToBeConverted;
  surplusOffsetQuantityToBeConverted;

  constructor(group, itemDto) {
    this.group = group;
    Object.assign(this, itemDto);



    if (this.isShortage){
      // Abs because stored shortage is negative, but inputs aren't because we don't want users to have to type in negative numbers.
      this.shortageOffsetQuantityToBeConverted = Math.abs(this.shortageOffsetQuantityConverted);
    } else {
      this.shortageOffsetQuantityToBeConverted = 0;
    }
    if (this.isSurplus) {
      // Abs because stored shortage is negative, but inputs aren't because we don't want users to have to type in negative numbers.
      this.surplusOffsetQuantityToBeConverted = Math.abs(this.surplusOffsetQuantityConverted);
    } else {
      this.surplusOffsetQuantityToBeConverted = 0;
    }

    ValidationRules
      .ensure('shortageOffsetQuantityToBeConverted')
        .displayName('Manko')
        // Integer
        // Number() converts string to number. Otherwise "1" is not an integer.
        .satisfies(value => Number.isInteger(Number(value))).withMessage('Manko må være et heltall')
        // Zero or more
        .then()
        .satisfies(value => value >= 0).withMessage('Manko må være et positivt tall')
        // Net or lower
        .then()
        .satisfies(value => value <= Math.abs(this.netSurplusOffsetQuantityOriginal)).withMessage('Manko må være mindre enn netto.')
      .ensure('surplusOffsetQuantityToBeConverted')
        .displayName('Overtallighet')
        // Integer
        // Number() converts string to number. Otherwise "1" is not an integer.
        .satisfies(value => Number.isInteger(Number(value))).withMessage('Overtallighet må være et heltall')
        // Zero or more
        .then()
        .satisfies(value => value >= 0).withMessage('Overtallighet må være et positivt tall')
        // Net or lower
        .then()
        .satisfies(value => value <= this.netSurplusOffsetQuantityOriginal).withMessage('Overtallighet må være mindre enn netto.')
      .on(this);
  }

  get offset() {
    return Number(this.netSurplusOffsetQuantityOriginal) - Number(this.surplusOffsetQuantityToBeConverted) + Number(this.shortageOffsetQuantityToBeConverted);
  }

  get isShortage() {
    return this.group.isShortage && this.netSurplusOffsetQuantityOriginal < 0
  }

  get isSurplus() {
    return this.group.isSurplus && this.netSurplusOffsetQuantityOriginal > 0;
  }
}
