import Backend from 'backend';
import {customElement, bindable, bindingMode, observable} from 'aurelia-framework';
import {inject} from 'aurelia-framework';
import {download} from "../../util/download-file";
import {IdentityProvider} from "devtag-aurelia-auth-plugin";

@customElement('invoice-component')
@inject(Backend, Element, IdentityProvider)
export class InvoiceComponent {
  backend;
  element;
  identityProvider;

  @bindable({changeHandler: 'invoiceIdChanged'})
  invoiceId;

  @bindable() hideSendButton;
  @bindable() orderPolicy;

  invoice;

  constructor(backend, element, identityProvider) {
    this.backend = backend;
    this.element = element;
    this.identityProvider = identityProvider;
  }

  invoiceIdChanged() {
    this.invoice = null;
    this.refresh();
  }

  refresh() {
    return this.backend.InvoiceDetailsQueryHandler_handle({invoiceId: this.invoiceId})
      .then(result => {
        this.invoice = result.invoice;
        this.documentRenderings = result.documentRenderings;
        this.anyLineHasAdditionalFields = this.invoice.lines.some(line => line.additionalFields.length > 0);
      });
  }

  send() {
    return this.backend.SendInvoiceCommandHandler_handle({invoiceId: this.invoiceId})
      .then(() => this.refresh())
      .then(() => {
        this.element.dispatchEvent(new CustomEvent('refreshed', {
              detail: {invoiceId: this.invoiceId},
              bubbles: true
            }));
      });
  }

  download(documentRendering) {
    return this.backend.DocumentRenderingFileQueryHandler_handle({documentRenderingId: documentRendering.documentRenderingId})
      .then(result => download(result.fileBase64, result.filename))
  }

  render() {
    return this.backend.RenderInvoiceCommandHandler_handle({invoiceId: this.invoiceId, invoiceType: 'PDF'})
      .then(result => this.refresh());
  }

  get hasPdfRendering() {
    return this.documentRenderings.filter(d => d.mimeType.toLowerCase() === 'application/pdf').length === 0;
  }
}
